import React from 'react';
import {
  PasswordRequirementsSD,
  PasswordRequirementsListSD,
  PasswordRequirementsItemSD,
  PasswordRequirementsIconSD,
  PasswordRequirementsTitleSD,
} from './styled';
import { Box } from '@material-ui/core';
import IconCheckPassword from '../../../icons/IconCheckPassword';

export interface PasswordRequirementsProps {
  password?: string;
}

function PasswordRequirements({ password = '' }: PasswordRequirementsProps) {
  return (
    <PasswordRequirementsSD>
      <PasswordRequirementsTitleSD variant="subtitle1" gutterBottom>
        <Box fontWeight={700} fontSize={12}>
          Regular text style 12px
        </Box>
      </PasswordRequirementsTitleSD>
      <PasswordRequirementsListSD>
        <PasswordRequirementsItemSD isValid={password.length >= 8}>
          <PasswordRequirementsIconSD>
            <IconCheckPassword />
          </PasswordRequirementsIconSD>
          Minimum 8 characters
        </PasswordRequirementsItemSD>
        <PasswordRequirementsItemSD
          isValid={!!password.match('(?=.*[a-z])(?=.*[A-Z])')}
        >
          <PasswordRequirementsIconSD>
            <IconCheckPassword />
          </PasswordRequirementsIconSD>
          At least one big cap and one small cap
        </PasswordRequirementsItemSD>
        <PasswordRequirementsItemSD isValid={!!password.match('(?=.*\\d)')}>
          <PasswordRequirementsIconSD>
            <IconCheckPassword />
          </PasswordRequirementsIconSD>
          At least one number included
        </PasswordRequirementsItemSD>
      </PasswordRequirementsListSD>
    </PasswordRequirementsSD>
  );
}

export default PasswordRequirements;
