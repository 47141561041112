import styled from 'styled-components';

export const BankInfoSD = styled.article`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;
`;

export const BankInfoFullNameSD = styled.div`
  > span {
    margin-right: 5px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const BankInfoPhoneSD = styled.div``;
