import React from 'react';
import { FormButtonSD, FormControlSD, FormSD, FormTitleSD } from './styled';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import {
  RadioKitControlLabelSD,
  RadioKitWrapLabelSD,
  RadioKitLabelSD,
} from '../../../../components/kit/Fields/Radio/styled';
import RadioKit from '../../../../components/kit/Fields/Radio/RadioKit';
import { FormikProps, withFormik } from 'formik';

interface FormValues {
  type: string;
}

interface FormProps {
  type?: string;
  bankName: string;
  handleSubmit: Function;
}

interface OtherProps {
  bankName: string;
}

function Form(props: OtherProps & FormikProps<FormValues>) {
  const { values, handleChange, handleSubmit, bankName } = props;
  return (
    <FormSD onSubmit={handleSubmit}>
      <FormTitleSD>How do you want to add the new portfolio?</FormTitleSD>
      <FormControlSD component="fieldset">
        <RadioGroup
          aria-label="type"
          onChange={handleChange}
          name="type"
          value={values.type}
        >
          <RadioKitControlLabelSD
            value="same"
            control={<RadioKit />}
            label={
              <RadioKitWrapLabelSD>
                <RadioKitLabelSD>Use the same Bank information</RadioKitLabelSD>
                <RadioKitLabelSD bold>{bankName}</RadioKitLabelSD>
              </RadioKitWrapLabelSD>
            }
            defaultChecked
          />
          <FormControlLabel
            value="new"
            control={<RadioKit />}
            label={
              <RadioKitLabelSD>Use different Bank information</RadioKitLabelSD>
            }
          />
        </RadioGroup>
      </FormControlSD>
      <FormButtonSD type="submit" color="primary" variant="contained">
        Add Another Portfolio
      </FormButtonSD>
    </FormSD>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ type = 'same' }) => ({
    type,
  }),
  handleSubmit: (values: FormValues, { props, setSubmitting }) => {
    setSubmitting(true);
    props.handleSubmit(values.type);
  },
})(Form);
