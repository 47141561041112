import React from 'react';
import { FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import RadioKit from '../../../kit/Fields/Radio/RadioKit';
import { RadioKitLabelSD } from '../../../kit/Fields/Radio/styled';

export interface ISearchTypeProps {
  value: String;
  onChange: Function;
}

function SearchType({ value, onChange }: ISearchTypeProps) {
  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  return (
    <FormControl component="fieldset" data-test="component-search-type">
      <RadioGroup
        aria-label="type"
        name="type"
        value={value}
        onChange={handleChange}
        row
      >
        <FormControlLabel
          value="name"
          control={<RadioKit color="primary" />}
          data-test="label"
          label={<RadioKitLabelSD small>Client names and IDs</RadioKitLabelSD>}
        />
        <FormControlLabel
          value="custodian"
          control={<RadioKit color="primary" />}
          data-test="label"
          label={<RadioKitLabelSD small>Custodian</RadioKitLabelSD>}
        />
        <FormControlLabel
          value="number"
          data-test="label"
          control={<RadioKit color="primary" />}
          label={<RadioKitLabelSD small>Portfolio and account number</RadioKitLabelSD>}
        />
      </RadioGroup>
    </FormControl>
  );
}

export default SearchType;
