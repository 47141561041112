import _ from 'lodash';
/**
 * Get property value by path or defaultValue
 *
 * @param obj {object} - Object for finding
 * @param path {string} - path to a property
 * @param defaultValue {* = null} - default value. It will return if path will wrong. It has null by default value.
 * @return {*} Return value of property by specified path or passed default value or null if it do not pass
 */
export const propValueOr = (
  obj: object | null,
  path: string,
  defaultValue: any | undefined = null
): any => {
  if (!path) return defaultValue;

  return (
    path.split('.').reduce((acc: any, item) => {
      if (!acc) return acc;

      return acc[item];
    }, obj) || defaultValue
  );
};

export const addUnit = (value: any) => {
  if (!value && value !== 0) return '0';

  if (typeof value === 'number') {
    return value === 0 ? '0' : `${value}px`;
  }

  const lastSymbol = value[value.length - 1];

  if (!isNaN(lastSymbol)) {
    return value === '0' ? '0' : `${value}px`;
  }

  return value;
};

export const groupPortfoliosByBank = (portfolios: any = []) => {
  return portfolios.reduce((r: any, a: any) => {
    r[a.custodian.name] = r[a.custodian.name] || [];
    r[a.custodian.name].push(a);
    return r;
  }, Object.create(null));
};

export const getErrorsByTemplate: any = (data: any, template: any) => {
  const obj: { [key: string]: any } = {};

  for (let key in data) {
    const templateKey = template[key] || key;
    if (_.isString(data[key])) {
      obj[templateKey] = data[key];
    } else if (Array.isArray(data[key]) && typeof data[key][0] === 'string') {
      obj[templateKey] = data[key].join(' ');
    } else if (Array.isArray(data[key]) && _.isObject(data[key][0])) {
      obj[templateKey] = [
        ...data[key].map((i: any) => getErrorsByTemplate(i, template)),
      ];
    }
  }

  return obj;
};

export const formatMoney = (number: number | null): string => {
  return Number(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
