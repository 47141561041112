export const DATE_PERIODS = {
  range: 'range',
  today: 'today',
  week: 'week',
  month: 'month',
  all: 'all',
};

export const DATE_FIELD_MENU_LIST = [
  {
    id: DATE_PERIODS.range,
    label: 'Choose range',
  },
  {
    id: DATE_PERIODS.today,
    label: 'Today',
  },
  {
    id: DATE_PERIODS.week,
    label: 'This week',
  },
  {
    id: DATE_PERIODS.month,
    label: 'This month',
  },
  {
    id: DATE_PERIODS.all,
    label: 'All time',
  },
];
