import { useEffect, useState } from 'react';
import Api from '../../../../api/api';
import { propValueOr } from '../../../../helpers/common';
import  _ from "lodash";

export interface ICustodian {
  name?: string;
  address?: string;
}

function objectKeysToCamelCase(snake_case_object: object) {
  let camelCaseObject = new Array<ICustodian>();
  _.forEach(
    snake_case_object,
    function(value: object, key: string) {
      if (_.isPlainObject(value) || _.isArray(value)) {     // checks that a value is a plain object or an array - for recursive key conversion
        value = objectKeysToCamelCase(value);               // recursively update keys of any values that are also objects
      }
      camelCaseObject[_.camelCase(key)] = value;
    }
  )
  return camelCaseObject;
};



function useCustodian() {
  const [custodians, setCustodians] = useState<Array<ICustodian>>([]);

  useEffect(() => {
    Api.getCustodians()
      .then(response => {
        let data = propValueOr(response, 'data', []);
        setCustodians(data);
      })
      .catch(() => {});
  }, []);

  return objectKeysToCamelCase(custodians);
}

export { useCustodian };
