import {
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel, MenuItem, Select,
  TextField,
} from '@material-ui/core';
import {
  TooltipKitButtonSD,
  TooltipKitSD,
  TooltipKitTitleSD,
} from '../../../components/kit/TooltipKit/styled';
import IconInfo from '../../../components/icons/IconInfo';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import {IAdvisor, useAdvisors} from "../hooks/useAdvisor";
import {ClientFormAdvisorSelectSD} from "./styled";
import {ROLES} from "../../../constants/common";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/rootReducer";
import {UserInfo, UserState} from "../../../store/commonStates/user/types";
import {IClient} from "../../../store/commonStates/clients/types";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formActions: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '30px',
  },
}));

export interface FormValues {
  goioID: string;
  customerNumber: string;
  firstName: string;
  lastName: string;
  nickname: string;
  wealthManager: number;
}

interface FormProps {
  goioID?: string;
  customerNumber?: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  wealthManager?: any;
  onSubmit: Function;
  type?: string;
  onCancel?: Function;
}

interface OtherProps {
  type?: string;
  onCancel?: Function;
}

function ClientForm(props: OtherProps & FormikProps<FormValues>) {
  const classes = useStyles();
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    type = 'create',
    onCancel = () => {},
  } = props;
  const wealthManagers = useAdvisors();

  const {
   userInfo
  } = useSelector<RootState, UserState>((state: RootState) => ({
    userInfo: state.user.userInfo
  }));

  const submitForm = (event: React.FormEvent<any>, type: boolean) => {
    event.preventDefault();
    setFieldValue('addPortfolio', type, false);
    handleSubmit();
  };

  const handleCancel = (event: React.FormEvent<any>) => {
    onCancel();
  };

  return (
    <form onSubmit={event => submitForm(event, false)}>
      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl
            variant="filled"
            fullWidth
            error={Boolean(touched.customerNumber && errors.customerNumber)}
          >
            <InputLabel htmlFor="customer-number">Client ID</InputLabel>
            <FilledInput
              id="customer-number"
              value={values.customerNumber}
              error={Boolean(touched.customerNumber && errors.customerNumber)}
              onChange={handleChange('customerNumber')}
              onBlur={handleBlur('customerNumber')}
              name="customerNumber"
              endAdornment={
                <InputAdornment position="end">
                  <TooltipKitSD
                    title={
                      <TooltipKitTitleSD>
                        The number assigned to your client when they open an
                        account in your office, also often called <br />
                        <i>Kundennummer</i>.
                      </TooltipKitTitleSD>
                    }
                  >
                    <TooltipKitButtonSD>
                      <IconInfo />
                    </TooltipKitButtonSD>
                  </TooltipKitSD>
                </InputAdornment>
              }
            />
            <FormHelperText id="customer-number-helper-text">
              {Boolean(touched.customerNumber && errors.customerNumber)
                ? errors.customerNumber
                : '*Required'}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="First Name"
            variant="filled"
            fullWidth
            value={values.firstName}
            error={Boolean(touched.firstName && errors.firstName)}
            onChange={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
            name="firstName"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last Name"
            variant="filled"
            value={values.lastName}
            error={Boolean(touched.lastName && errors.lastName)}
            onChange={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
            fullWidth
            name="lastName"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Nickname"
            variant="filled"
            value={values.nickname}
            error={Boolean(touched.nickname && errors.nickname)}
            onChange={handleChange('nickname')}
            onBlur={handleBlur('nickname')}
            fullWidth
            name="nickname"
          />
        </Grid>
        {userInfo.role === ROLES.WMOadvisor &&
           <Grid item xs={12} md={6}>
               <ClientFormAdvisorSelectSD variant="filled">
              <InputLabel htmlFor="wealthManager">Wealth Manager</InputLabel>
              <Select
                  IconComponent={props => (
                    <KeyboardArrowDown {...props} />
                  )}
                  name='wealthManager'
                  value={values.wealthManager}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  displayEmpty
                  fullWidth
                >
                  {wealthManagers.length > 0 &&
                    wealthManagers.map((wealth_manager: IAdvisor) => (
                      <MenuItem key={wealth_manager.id} value={wealth_manager.id}>
                        {wealth_manager.first_name} {wealth_manager.last_name} ({wealth_manager.email})
                      </MenuItem>
                    ))}
                </Select>
                 </ClientFormAdvisorSelectSD>
           </Grid>}
      </Grid>
      <br />
      {type === 'edit' ? (
        <div className={classes.formActions} data-test="form-actions-edit">
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                data-test="button-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                data-test="button-save"
                onClick={e => submitForm(e, false)}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.formActions} data-test="form-actions-create">
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                type="submit"
                data-test="button-save"
                onClick={e => submitForm(e, false)}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                data-test="button-save-and-add"
                onClick={e => submitForm(e, true)}
              >
                Save & Add a Portfolio
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </form>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({
    goioID = '',
    customerNumber = '',
    firstName = '',
    lastName = '',
    nickname = '',
    wealthManager = undefined,
  }) => ({
    goioID,
    customerNumber,
    firstName,
    lastName,
    nickname,
    wealthManager,
  }),
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    goioID: Yup.string().required('GOIO ID is required'),
    customerNumber: Yup.string().required('Client ID is required'),
  }),
  handleSubmit: (values: FormValues, { props, setErrors, setSubmitting }) => {
    setSubmitting(true);
    props.onSubmit(values, setErrors, setSubmitting);
  },
})(ClientForm);
