import React from 'react';
import LayoutQualityEngineer from '../../../layouts/quality-engineer/LayoutQualityEngineer';
import { Switch, Route, Redirect } from 'react-router-dom';
import { layoutAuthRoutes, layoutQualityEngineerRoutes } from '../routes';
import RouteNotFound from '../../../routes/not-found/RouteNotFound';

const RedirectToHomePage = () => <Redirect to={'/'} />;

function QualityEngineerRouting() {
  return (
    <LayoutQualityEngineer>
      <Switch>
        {/*All routes from LayoutAuth will redirect to home-page*/}
        {layoutAuthRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            component={RedirectToHomePage}
          />
        ))}

        {layoutQualityEngineerRoutes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        })}
        <Route component={RouteNotFound} />
      </Switch>
    </LayoutQualityEngineer>
  );
}

export default QualityEngineerRouting;
