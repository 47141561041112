import { AppDispatch } from '../../../../store/configureStore';
import Api from '../../../../api/api';
import { RECONCILIATION_DETAILS_ACTIONS } from './types';
import _ from 'lodash';

export const getReconciliationDetails = (id: number): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: RECONCILIATION_DETAILS_ACTIONS.request,
  });

  return Api.reconsiliations
    .getOne(id)
    .then(response => {
      dispatch({
        type: RECONCILIATION_DETAILS_ACTIONS.success,
        payload: {
          baseInfo: {
            id: _.get(response, 'data.id'),
            portfolio: {
              id: _.get(response, 'data.portfolio.id'),
              custodian: _.get(response, 'data.portfolio.custodian'),
              portfolioNumber: _.get(
                response,
                'data.portfolio.portfolio_number'
              ),
            },
            reconciliationDate: _.get(response, 'data.reconciliation_date'),
            reconciliationStatus: _.get(response, 'data.reconciliation_status'),
            identifiers: _.get(response, 'data.identifiers'),
          },
          startStatement: {
            positionId: _.get(response, 'data.start_statement.position_id'),
            positionDate: _.get(response, 'data.start_statement.position_date'),
            amount: _.get(response, 'data.start_statement.amount'),
            statementsFile: _.get(response, 'data.start_statement.statements_file'),
          },
          transactions: _.get(response, 'data.transactions', []).map(
            transformTransaction
          ),
          endStatement: {
            positionId: _.get(response, 'data.end_statement.position_id'),
            positionDate: _.get(response, 'data.end_statement.position_date'),
            amount: _.get(response, 'data.end_statement.amount'),
            statementsFile: _.get(response, 'data.end_statement.statements_file'),
            goioComputation: _.get(
              response,
              'data.end_statement.goio_computation'
            ),
            reconciliationCorrect: _.get(
              response,
              'data.end_statement.reconciliation_correct'
            ),
          },
        },
      });
      return response;
    })
    .catch(err => {
      dispatch({
        type: RECONCILIATION_DETAILS_ACTIONS.error,
      });
      return err;
    });
};

export const getPdfDocument = (id: number, pdf_id: string): any => (
  dispatch: AppDispatch
) => {

  dispatch({
    type: RECONCILIATION_DETAILS_ACTIONS.requestPdf,
  });

  return Api.reconsiliations
      .getPdfDocument({id, pdf_id})
      .then(response => {
        let data = response.data;
        dispatch({
          type: RECONCILIATION_DETAILS_ACTIONS.requestPdfSuccess,
          payload: {
            pdfDocument: data,
          },
        });
        return response;
      })
      .catch(err => {
        dispatch({
          type: RECONCILIATION_DETAILS_ACTIONS.requestPdfError,
        });
        return err;
      });
};

export const resetReconciliationDetails = () => ({
  type: RECONCILIATION_DETAILS_ACTIONS.reset,
});

export const transformTransaction = (data: any) => ({
  transactionId: data.transaction_id,
  transactionDate: data.transaction_date,
  amount: data.amount,
  goioComputation: data.goio_computation,
  operation: data.operation,
  reconciliationCorrect: data.reconciliation_correct,
  statementsFile: data.statements_file,
});
