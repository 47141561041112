import styled from 'styled-components';

export const LayoutAuthSD = styled.main`
  padding-top: 145px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
`;

export const LayoutAuthContainerSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LayoutAuthWrapLogoSD = styled.figure`
  margin: 0 0 50px;
  width: 190px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LayoutAuthLogoSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

export const LayoutAuthContentSD = styled.div`
  padding: 0 15px;
`;
