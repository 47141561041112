import React from 'react';
import { Grid, Box } from '@material-ui/core';
import IconArrowDropUp from '../../../icons/IconArrowDropUp';
import IconArrowDropDown from '../../../icons/IconArrowDropDown';
import IconZoomIn from '../../../icons/IconZoomIn';
import IconZoomOut from '../../../icons/IconZoomOut';
import {
  PdfViewerToolbarButtonSD,
  PdfViewerToolbarSD,
  PdfViewerToolbarTotalSD,
} from './styled';
import PageInputForm from './PageInputForm/PageInputForm';

export interface PdfViewerToolbarProps {
  numPages: number | null;
  pageNumber: number;
  onChangePage: (pageNumber: number) => void;
  scale: number;
  onChangeScale: (scale: number) => void;
}

function PdfViewerToolbar(props: PdfViewerToolbarProps) {
  const { numPages, pageNumber, onChangePage, scale, onChangeScale } = props;

  return (
    <PdfViewerToolbarSD data-test="component-pdf-viewer-toolbar">
      <Grid container spacing={1} justify="center" alignItems="center">
        <Grid item>
          <PdfViewerToolbarButtonSD
            data-test="button-previous"
            disabled={pageNumber <= 1}
            onClick={() => onChangePage(pageNumber - 1)}
            size="small"
          >
            <IconArrowDropDown fontSize="small" />
          </PdfViewerToolbarButtonSD>
        </Grid>
        <Grid item>
          <PdfViewerToolbarButtonSD
            data-test="button-next"
            disabled={pageNumber >= Number(numPages)}
            onClick={() => onChangePage(pageNumber + 1)}
            size="small"
          >
            <IconArrowDropUp fontSize="small" />
          </PdfViewerToolbarButtonSD>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <PageInputForm
                pageNumber={pageNumber}
                numPages={Number(numPages)}
                onChange={p => onChangePage(p)}
              />
            </Grid>
            <Grid item>
              <PdfViewerToolbarTotalSD variant="subtitle1">
                <Box data-test="total" fontSize={14}>
                  {' '}
                  / {numPages}
                </Box>
              </PdfViewerToolbarTotalSD>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <PdfViewerToolbarButtonSD
            data-test="zoom-out"
            disabled={scale <= 1}
            onClick={() => onChangeScale(scale - 0.5)}
            size="small"
          >
            <IconZoomOut fontSize="small" />
          </PdfViewerToolbarButtonSD>
        </Grid>
        <Grid item>
          {' '}
          <PdfViewerToolbarButtonSD
            data-test="zoom-in"
            disabled={scale >= 3}
            onClick={() => onChangeScale(scale + 0.5)}
            size="small"
          >
            <IconZoomIn fontSize="small" />
          </PdfViewerToolbarButtonSD>
        </Grid>
      </Grid>
    </PdfViewerToolbarSD>
  );
}

export default PdfViewerToolbar;
