import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { layoutAuthorizedRoutes, layoutAuthRoutes } from './routes';
import LayoutAdvisor from '../../layouts/advisor/LayoutAdvisor';
import RouteNotFound from '../../routes/not-found/RouteNotFound';
import { RootState } from '../../store/rootReducer';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ROLES } from '../../constants/common';
import QualityEngineerRouting from './quality-engineer/QualityEngineerRouting';

const RedirectToOnboardingPage = () => <Redirect to={'/my-clients'} />;

interface AuthorizedLayoutRoutingProps {
  role: number;
}

function AuthorizedLayoutRouting() {
  const { role } = useSelector<RootState, AuthorizedLayoutRoutingProps>(
    (state: RootState) => {
      return {
        role: _.get(state, 'user.userInfo.role'),
      };
    }
  );

  switch (role) {
    case ROLES.qualityEngineer:
      return <QualityEngineerRouting />;
    case ROLES.WMOadvisor:
    case ROLES.advisor:
      return (
        <Switch>
          {/*All routes from LayoutRegistration will redirect to home-page*/}
          {layoutAuthRoutes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              component={RedirectToOnboardingPage}
            />
          ))}

          <Route
            key={'/'}
            exact={true}
            path={'/'}
            component={RedirectToOnboardingPage}
          />

          <Route
            render={props => (
              <LayoutAdvisor {...props}>
                <Switch>
                  {layoutAuthorizedRoutes.map(route => {
                    const Component = route.component;

                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        // @ts-ignore
                        render={props => <Component {...props} />}
                      />
                    );
                  })}
                  <Route component={RouteNotFound} />
                </Switch>
              </LayoutAdvisor>
            )}
          />
        </Switch>
      );
    default:
      return null;
  }
}

export default AuthorizedLayoutRouting;
