import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconSend(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M59.482,64.075a1.687,1.687,0,0,0-.254,2.061l2.349,3.915a2.19,2.19,0,0,0,3.956-.434l5.5-16.5A1.516,1.516,0,0,0,69.117,51.2l-16.5,5.5a2.19,2.19,0,0,0-.434,3.956L56.094,63a1.687,1.687,0,0,0,2.061-.254l4.531-4.531a.939.939,0,1,1,1.328,1.328Z"
        transform="translate(-51.115 -51.115)"
      />
    </SvgIcon>
  );
}

export default IconSend;
