import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconInfo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M-127-43a10,10,0,0,0-10,10,10,10,0,0,0,10,10,10,10,0,0,0,10-10A10,10,0,0,0-127-43Zm0,4.286a1.786,1.786,0,0,1,1.786,1.786A1.786,1.786,0,0,1-127-35.143a1.786,1.786,0,0,1-1.786-1.786A1.786,1.786,0,0,1-127-38.714Zm2.857,10.893a.536.536,0,0,1-.536.536h-4.643a.536.536,0,0,1-.536-.536v-.357a.536.536,0,0,1,.536-.536.536.536,0,0,0,.536-.536v-3.214a.536.536,0,0,0-.536-.536.536.536,0,0,1-.536-.536v-.357a.536.536,0,0,1,.536-.536h3.571a.536.536,0,0,1,.536.536v4.643a.536.536,0,0,0,.536.536.536.536,0,0,1,.536.536Z"
        transform="translate(137 43)"
      />
    </SvgIcon>
  );
}

export default IconInfo;
