import { Reducer } from 'redux';
import { CLIENTS_ACTIONS, ClientsActionTypes, IClientsState } from './types';

export const initialClientsState: IClientsState = {
  list: [],
  isClientsFetching: false,
  currentClient: null,
  isCurrentClientFetching: false,
  total: 1,
  currentPortfolio: null,
  isCurrentPortfolioFetching: false,
  isSearch: false,
};

const reducer: Reducer<IClientsState> = (
  state = initialClientsState,
  action: ClientsActionTypes
): IClientsState => {
  switch (action.type) {
    case CLIENTS_ACTIONS.client.request:
      return {
        ...state,
        isCurrentClientFetching: true,
      };
    case CLIENTS_ACTIONS.client.success:
      return {
        ...state,
        isCurrentClientFetching: false,
        currentClient: action.payload,
      };
    case CLIENTS_ACTIONS.client.error:
      return {
        ...state,
        isCurrentClientFetching: false,
        currentClient: null,
      };
    case CLIENTS_ACTIONS.portfolio.request:
      return {
        ...state,
        isCurrentPortfolioFetching: true,
      };
    case CLIENTS_ACTIONS.portfolio.success:
      return {
        ...state,
        currentPortfolio: action.payload.portfolio,
        isCurrentPortfolioFetching: false,
      };
    case CLIENTS_ACTIONS.portfolio.error:
      return {
        ...state,
        isCurrentPortfolioFetching: false,
      };
    case CLIENTS_ACTIONS.clients.request:
      return {
        ...state,
        isClientsFetching: true,
      };
    case CLIENTS_ACTIONS.clients.success:
      return {
        ...state,
        isClientsFetching: false,
        list: action.payload.clients,
        total: action.payload.total,
        isSearch: action.payload.isSearch
      };
    case CLIENTS_ACTIONS.clients.error:
      return {
        ...state,
        isClientsFetching: false,
        list: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default reducer;
