import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import imageSorry from './../../static/images/404.png';
import {
  RouteNotFoundSD,
  RouteNotFoundImageSD,
  RouteNotFoundCardSD,
} from './styled';

const RouteNotFound = () => {
  return (
    <RouteNotFoundSD data-test="component-404">
      <RouteNotFoundCardSD>
        <CardContent>
          <RouteNotFoundImageSD image={imageSorry} />
          <Typography gutterBottom variant="h5" component="h2">
            Page not found :(
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            data-test="error-message"
          >
            Maybe the page you are looking for has been removed, or you typed in
            the wrong URL
          </Typography>
        </CardContent>
      </RouteNotFoundCardSD>
    </RouteNotFoundSD>
  );
};

export default RouteNotFound;
