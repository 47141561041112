import React from 'react';
import {
    LayoutAuthSD,
    LayoutAuthContainerSD,
    LayoutAuthWrapLogoSD,
    LayoutAuthLogoSD,
    LayoutAuthContentSD
} from "./styled";

import iconLogo from '../../static/icons/logo.svg';

type LayoutAuthProps = {
    children: React.ReactNode,
}

function LayoutAuth ({children}: LayoutAuthProps) {
    return <LayoutAuthSD>
        <LayoutAuthContainerSD>
            <LayoutAuthWrapLogoSD>
                <LayoutAuthLogoSD src={iconLogo} alt={'GOIO DATA'} />
            </LayoutAuthWrapLogoSD>
            <LayoutAuthContentSD>
                {children}
            </LayoutAuthContentSD>
        </LayoutAuthContainerSD>
    </LayoutAuthSD>
}

export default LayoutAuth;