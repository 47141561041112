import React from "react";
import {
    AddPortfolioPoliciesTitleSD,
    SwitchPoliciesGroupSD
} from "./styled";
import {
    createStyles,
    FormControl,
    FormGroup, FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Theme
} from "@material-ui/core";
import {AppDispatch} from "../../../store/configureStore";
import {useDispatch, useSelector} from "react-redux";
import {POLICIES_ACTIONS} from "../../../store/commonStates/policies/types";
import SwitchKit from "../../../components/kit/Fields/Switch/SwitchKit";
import {makeStyles} from "@material-ui/core/styles";
import {DesktopDatePicker, LocalizationProvider} from "@material-ui/pickers";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Api from "../../../api/api";
import {RootState} from "../../../store/rootReducer";


interface IPolicy {
    id: number,
    name: string,
    own: boolean,
    value: string,
    policy_type: string,
    choices: string[],
}


export interface PoliciesGroupProps {
  policies: [IPolicy];
  portfolioId: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(4),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

interface PoliciesSelectorProps {
  statePolicies: any;
}

function PortfolioPolicyGroup(props: PoliciesGroupProps) {
    const {policies, portfolioId} = props;
    const classes = useStyles();
    const dispatch: AppDispatch = useDispatch();
      const { statePolicies } = useSelector<
        RootState,
        PoliciesSelectorProps
      >((state: RootState) => {
        return {
          statePolicies: state.policies.policies,
        };
      })

    const savePolicies = () => {
        Api.portfolios
            .createPolicies(portfolioId, statePolicies)
            .then((response) => {
                dispatch({
                    type: POLICIES_ACTIONS.setPolicies,
                    payload: {
                        policies: response.data,
                    }
                });
            }).catch(err => {
                console.log('err', err)
        })
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, policyType: any) => {
        const policyID = event.target.id
        dispatch({
          type: POLICIES_ACTIONS.togglePolicy,
          payload: { policyID },
        })
        if (policyType === 'active' || !event.target.checked) {
            savePolicies();
        }
    };

    const handleDateChange = (targetValue:any, policyID: any) => {
        const value = targetValue.valueOf()
        dispatch({
          type: POLICIES_ACTIONS.setPolicyValue,
          payload: { value, policyID },
        })
        savePolicies();
    };

    const handleTextChange = (event:any, policyID:any) => {
        const value = event.target.value
        dispatch({
          type: POLICIES_ACTIONS.setPolicyValue,
          payload: { value , policyID},
        })
        savePolicies();
    };

    const handleMultiChoiceChange = (event:any, policyID:any) => {
        const value = event.target.value.filter(Boolean).join(";")
        dispatch({
          type: POLICIES_ACTIONS.setPolicyValue,
          payload: { value , policyID},
        })
        savePolicies();
    };

    return (
        <SwitchPoliciesGroupSD
          component="fieldset"
          data-test="component-checkbox-custodians-group"
        >
          <AddPortfolioPoliciesTitleSD variant="h3" color="primary">
            Active Policies
          </AddPortfolioPoliciesTitleSD>
          <FormGroup className={classes.root}>
            {policies.map((policy, index) =>
              { if (policy.own || policy.value)
                  return (
                      <Grid container key={`active_${index}`} alignItems="center" direction="row" >
                          <Grid item xs={3} className={classes.paper}>
                            <SwitchKit
                              data-test="checkbox"
                              id={policy.id.toString()}
                              label={policy.name}
                              checked={policy.own}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              onChange={(e) => handleChange(e, policy.policy_type)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            {(() => {
                              switch(policy.policy_type) {
                                case 'text':
                                  return(
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        value={policy.value}
                                        error={!policy.value}
                                        helperText={!policy.value ? 'Please fill this field': 'Use semicolon to create more then one policy'}
                                        onChange={(value) => handleTextChange(value, policy.id)}
                                    />
                                  )
                                case 'date':
                                  return (
                                      <FormControl variant="filled" fullWidth>
                                          <LocalizationProvider dateAdapter={MomentUtils}>
                                            <DesktopDatePicker
                                              autoOk
                                              value={policy.value}
                                              onChange={(value) => handleDateChange(value, policy.id)}
                                              inputFormat={'DD/MM/YYYY'}
                                              renderInput={props => <TextField
                                                  {...props}
                                                  fullWidth
                                                  variant="filled"
                                                  error={!policy.value}
                                                  helperText={!policy.value ? 'Please fill this field': ''}
                                              />}
                                            />
                                          </LocalizationProvider>
                                      </FormControl>
                                  )
                                case 'choice':
                                  return (
                                      <FormControl variant="filled" fullWidth>
                                          <Select
                                          IconComponent={props => (
                                            <KeyboardArrowDown {...props} />
                                          )}
                                          value={policy.value}
                                          onChange={(value) => handleTextChange(value, policy.id)}
                                          error={!policy.value}
                                          displayEmpty
                                          fullWidth
                                        >
                                          {policy.choices.map((value, index) =>
                                              <MenuItem key={`choice_${index}`} value={value}>{value}</MenuItem>
                                          )}
                                        </Select>
                                        {!policy.value && <FormHelperText error={!policy.value}>Please fill this field</FormHelperText>}
                                      </FormControl>
                                  )
                                case 'multi_choice':
                                  return (
                                      <FormControl variant="filled" fullWidth>
                                          <Select
                                          IconComponent={props => (
                                            <KeyboardArrowDown {...props} />
                                          )}
                                          value={policy.value.split(";")}
                                          onChange={(value) => handleMultiChoiceChange(value, policy.id)}
                                          displayEmpty
                                          multiple
                                          error={!policy.value}
                                          fullWidth
                                        >
                                          {policy.choices.map((value, index) =>
                                              <MenuItem key={`multi_choice_${index}`} value={value}>{value}</MenuItem>
                                          )}
                                        </Select>
                                        {!policy.value && <FormHelperText error={!policy.value}>Please fill this field</FormHelperText>}
                                      </FormControl>
                                  )
                                default:
                                  return
                              }
                            })()}

                          </Grid>
                      </Grid>
                  )})}
          </FormGroup><br/><br/>
          <AddPortfolioPoliciesTitleSD variant="h3" color="primary">
            Available Policies
          </AddPortfolioPoliciesTitleSD>

            <FormGroup className={classes.root}>
               <Grid container  alignItems="flex-start" direction="column">
                  {policies.map((policy, index) =>
                    { if (!policy.own && !policy.value)
                      return (
                        <Grid item xs={3} key={`available_${index}`} className={classes.paper}>
                          <SwitchKit
                            data-test="checkbox"
                            id={policy.id.toString()}
                            label={policy.name}
                            checked={policy.own}
                            color="primary"
                            name={policy.name}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            onChange={(e) => handleChange(e, policy.policy_type)}
                          />
                        </Grid>
                    )}
                  )}
              </Grid>
            </FormGroup>
        </SwitchPoliciesGroupSD>
    )


}
export default PortfolioPolicyGroup;