import React from 'react';
import { FilterFooterSD, FilterSD } from './styled';
import SearchBar from '../../../components/presentation/SearchBar/SearchBar';
import FilterButtonsGroup from '../FilterButtonsGroup/FilterButtonsGroup';

function Filter() {
  return (
    <FilterSD data-test="component-filter">
      <FilterButtonsGroup />
      <FilterFooterSD>
        <SearchBar />
      </FilterFooterSD>
    </FilterSD>
  );
}

export default Filter;
