import styled from 'styled-components';

export const StatisticSD = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.colors.lineGrey};

  list-style: none;
  background-color: #fff;
`;

export const StatisticItemSD = styled.li`
  position: relative;
  width: 50%;
  padding: 15px;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 6px;
      bottom: 6px;
      width: 1px;
      background-color: ${({ theme }) => theme.colors.lineGrey};
    }
  }
`;
