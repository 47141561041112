import React from 'react';
import moment from 'moment';
import { StatisticItemSD, StatisticItemTitle } from './styled';

export interface IStatisticItemProps {
  title: string;
  date: string;
  subtitle: string;
}

function StatisticItem(props: IStatisticItemProps) {
  const { title, date, subtitle } = props;

  return (
    <StatisticItemSD data-test="component-statistic-item">
      <StatisticItemTitle data-test="title">{title}</StatisticItemTitle>
      <data data-test="date">{moment(date).format('DD.MM.YYYY')}</data>
      <div data-test="subtitle">{subtitle}</div>
    </StatisticItemSD>
  );
}

export default StatisticItem;
