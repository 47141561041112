import React from 'react';
import {FormControlLabel, SwitchProps} from "@material-ui/core";
import {SwitchKitContainerSD, SwitchKitSD} from "./styled";

interface SwitchKitProps {
  label?: string;
}


function SwitchKit({
    label,
  ...otherProps
}: SwitchKitProps & SwitchProps) {
    return (
        <SwitchKitContainerSD data-test={'component-switch-kit'}>
            <FormControlLabel
              control={
                  <SwitchKitSD
                      {...otherProps}
                  />
              }
              label={label}
            />

        </SwitchKitContainerSD>
    );

}

export default SwitchKit;