import React from 'react';
import styled, { css } from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  TransactionCardAmountSD,
  TransactionCardCalculationSD,
} from './TransactionCard/styled';

export const ReconciliationTableSD = styled(Table)`
  table-layout: fixed;
`;

export const ReconciliationTableHeadSD = styled(TableHead)`
  th {
    vertical-align: bottom;

    &:last-child {
      width: 155px;
    }
  }
`;

interface ReconciliationTableRowProps {
  isActive?: boolean;
  isError?: boolean;
}

export const ReconciliationTableRowSD = styled(
  ({ isActive, isError, ...props }) => <TableRow {...props} />
)<ReconciliationTableRowProps>`
  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    `}

  ${({ isError }) =>
    isError &&
    css`
      border-color: #f26d7d;

      ${TransactionCardAmountSD}, ${TransactionCardCalculationSD} {
        color: #f26d7d;
      }
    `}
`;

export const ReconciliationsTableCellSD = styled(TableCell)<TableCellProps>`
  padding: 5px 15px;
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px Open Sans, Arial,
    sans-serif;

  &:last-child {
    width: 155px;
  }
`;

export const ReconciliationTableBodySD = styled(TableBody)`
  ${ReconciliationTableRowSD} {
    &:first-child,
    &:last-child {
      background-color: #fbfbfd;

      ${ReconciliationsTableCellSD} {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }
  }
`;
