export const CUSTODIANS_FILTER_ACTIONS = {
  setSortBy: 'CUSTODIANS_FILTER_ACTIONS / SET_SORT_BY',
  setStatus: 'CUSTODIANS_FILTER_ACTIONS / SET_STATUS',
  setSearch: 'CUSTODIANS_FILTER_ACTIONS / SET_SEARCH',
  setCustodians: 'CUSTODIANS_FILTER_ACTIONS / SET_CUSTODIAN',
  setSelectedCustodians: 'CUSTODIANS_FILTER_ACTIONS / SET_SELECTED_CUSTODIANS',
  toggleCustodian: 'CUSTODIANS_FILTER_ACTIONS / TOGGLE_CUSTODIAN',
  clearCustodians: 'CUSTODIANS_FILTER_ACTIONS / CLEAR_CUSTODIANS',
};

export interface ICustodianFilter {
  custodian: string;
  count: number;
}

export interface CustodiansFilterState {
  sortBy: string;
  status: string | number;
  search: string;
  selectedCustodians: string[];
  custodians: ICustodianFilter[];
}

interface SetCustodiansAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.setCustodians;
  payload: {
    custodians: ICustodianFilter[];
  };
}

interface SetSortByAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.setSortBy;
  payload: { sortBy: string };
}

interface SetStatusAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.setStatus;
  payload: { status: number | string };
}

interface SetSearchAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.setSearch;
  payload: { search: string };
}

interface SetSelectedCustodiansAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.setSelectedCustodians;
  payload: { selectedCustodians: string[] };
}

interface toggleCustodianAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.toggleCustodian;
  payload: { custodian: string };
}

interface clearCustodiansAction {
  type: typeof CUSTODIANS_FILTER_ACTIONS.clearCustodians;
}

export type CustodiansFilterTypes =
  | SetSelectedCustodiansAction
  | clearCustodiansAction
  | SetSortByAction
  | SetStatusAction
  | toggleCustodianAction
  | SetSearchAction
  | SetCustodiansAction;
