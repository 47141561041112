import styled from 'styled-components';

export const StatisticItemSD = styled.article`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;
`;

export const StatisticItemTitle = styled.span`
  margin-bottom: 3px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
