import React from 'react';
import StatisticItem, {
  IStatisticItemProps,
} from './StatisticItem/StatisticItem';
import { StatisticDateSD, StatisticDateItemSD } from './styled';

export interface IStatisticDateProps {
  first: IStatisticItemProps;
  second: IStatisticItemProps;
}

function StatisticDate({ first, second }: IStatisticDateProps) {
  return (
    <StatisticDateSD data-test="component-statistic-date">
      <StatisticDateItemSD>
        <StatisticItem data-test="first" {...first} />
      </StatisticDateItemSD>
      <StatisticDateItemSD>
        <StatisticItem data-test="second" {...second} />
      </StatisticDateItemSD>
    </StatisticDateSD>
  );
}

export default StatisticDate;
