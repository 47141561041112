import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconSort(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 10 14">
      <path
        d="M8.858,8.322l1.41-1.484A.5.5,0,0,0,9.9,6H.826C.274,6,0,5.552,0,5v.008C0,4.455.274,4,.826,4H9.89a.507.507,0,0,0,.36-.857l-1.4-1.46A1,1,0,0,1,8.888.271l0,0a.987.987,0,0,1,1.394.043l3.171,3.342a2.007,2.007,0,0,1-.007,2.757L10.305,9.693a.986.986,0,0,1-1.388.042l-.009-.01a.992.992,0,0,1-.05-1.4"
        transform="translate(0 14) rotate(-90)"
      />
    </SvgIcon>
  );
}

export default IconSort;
