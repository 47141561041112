import React, { Fragment } from 'react';
import { RouteQEDataQualitySD, RouteQEDataQualityBlankSD } from './styled';
import PageBlank from '../../../components/presentation/PageBlank/PageBlank';
import imageDecor from '../../../static/images/decore-reconciliation-breaks.png';

function RouteQEDataQuality() {
  return (
    <RouteQEDataQualitySD>
      <RouteQEDataQualityBlankSD>
        <PageBlank
          title={'Data Quality'}
          subtitle={'Focus on quality: Ready, set, GOIO!'}
          renderText={() => (
            <Fragment>
              In this page you would be able to{' '}
              <strong>
                keep track of the quality of data received by GOIO from
                custodians and notify them about errors or quality problems.
              </strong>{' '}
              For now, it seems like you no data errors to review!
            </Fragment>
          )}
          image={imageDecor}
        />
      </RouteQEDataQualityBlankSD>
    </RouteQEDataQualitySD>
  );
}

export default RouteQEDataQuality;
