import { combineReducers } from 'redux';
import filterReducer from './Filter/store/reducer';
import qualityEngineerReducer from './store/reducer';
import reconciliationDetailsReducer from './reconciliation-details/store/reducer';

const reducers = {
  root: qualityEngineerReducer, // maybe need change root => reconciliation
  reconciliationDetails: reconciliationDetailsReducer,
  filter: filterReducer, // it's filter with custodians, search & date-range-picker
};

export default combineReducers({
  ...reducers,
});
