import React from 'react';
import * as Yup from 'yup';
import { Button, Grid } from '@material-ui/core';
import { FormikProps, withFormik } from 'formik';
import IconSend from '../../../../components/icons/IconSend';
import {
  FeedbackFormTitleSD,
  FeedbackFormFieldsetSD,
  FeedbackFormLegendSD,
  FeedbackFormTextFieldSD,
  FeedbackFormTextareaSD,
} from './styled';

interface FormValues {
  custodian: string;
  accountNumber: string;
  fullName: string;
  email: string;
  phone: string;
  message: string;
}

function FeedbackForm(props: FormikProps<FormValues>) {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors,
  } = props;

  return (
    <form data-test="component-feedback-form" onSubmit={handleSubmit}>
      <FeedbackFormTitleSD variant="h1" color="primary">
        Send Feedback to a Custodian
      </FeedbackFormTitleSD>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={5}>
          <FeedbackFormFieldsetSD gutterBottom>
            <FeedbackFormLegendSD
              variant="h3"
              component="legend"
              color="primary"
            >
              Account/Portfolio Details
            </FeedbackFormLegendSD>
            <FeedbackFormTextFieldSD
              name="custodian"
              label="Custodian"
              variant="filled"
              fullWidth
              error={Boolean(touched.custodian && errors.custodian)}
              value={values.custodian}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText="*Required"
              gutterBottom
            />
            <FeedbackFormTextFieldSD
              name="accountNumber"
              label="Portfolio Account Number"
              variant="filled"
              fullWidth
              error={Boolean(touched.accountNumber && errors.accountNumber)}
              value={values.accountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText="*Required"
              gutterBottom={false}
            />
          </FeedbackFormFieldsetSD>
        </Grid>
        <Grid item xs={6}>
          <FeedbackFormFieldsetSD gutterBottom>
            <FeedbackFormLegendSD
              variant="h3"
              component="legend"
              color="primary"
            >
              Custodian IT Contact
            </FeedbackFormLegendSD>
            <Grid container>
              <Grid item xs={10}>
                <FeedbackFormTextFieldSD
                  name="fullName"
                  label="Full Name"
                  variant="filled"
                  fullWidth
                  error={Boolean(touched.fullName && errors.fullName)}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText="*Required"
                  gutterBottom
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FeedbackFormTextFieldSD
                  name="email"
                  label="Email"
                  variant="filled"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText="*Required"
                  gutterBottom={false}
                />
              </Grid>
              <Grid item xs={6}>
                <FeedbackFormTextFieldSD
                  name="phone"
                  label="Phone number"
                  variant="filled"
                  fullWidth
                  error={Boolean(touched.phone && errors.phone)}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText="*Required. Example +496912345678"
                  gutterBottom={false}
                />
              </Grid>
            </Grid>
          </FeedbackFormFieldsetSD>
        </Grid>
        <Grid item container xs={12} alignItems="flex-end" spacing={2}>
          <Grid item xs={9}>
            <FeedbackFormFieldsetSD>
              <FeedbackFormLegendSD
                variant="h3"
                component="legend"
                color="primary"
              >
                Message
              </FeedbackFormLegendSD>
              <FeedbackFormTextareaSD
                name="message"
                variant="filled"
                error={Boolean(touched.message && errors.message)}
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                multiline
                placeholder="Write your message here"
                isValid={values.message.length > 0}
                rows={8}
              />
            </FeedbackFormFieldsetSD>
          </Grid>
          <Grid item xs={3}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<IconSend />}
            >
              Send to Custodian
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export interface FeedbackFormProps {
  custodian?: string;
  accountNumber?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  message?: string;
  onSubmit: (
    values: FormValues,
    setErrors: Function,
    setSubmitting: Function
  ) => void;
}

export default withFormik<FeedbackFormProps, FormValues>({
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    custodian: Yup.string().required('Custodian is required'),
    accountNumber: Yup.string().required(
      'Portfolio Account Number is required'
    ),
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    phone: Yup.string()
      .required('Phone is required')
      .matches(
        new RegExp(
          '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$',
          'im'
        ),
        'Phone number is invalid'
      ),
    message: Yup.string().required('Message is required'),
  }),
  mapPropsToValues: ({
    custodian = '',
    accountNumber = '',
    fullName = '',
    email = '',
    phone = '',
    message = '',
  }) => ({
    custodian,
    accountNumber,
    fullName,
    email,
    phone,
    message,
  }),
  handleSubmit: (values: FormValues, { props, setErrors, setSubmitting }) => {
    props.onSubmit(values, setErrors, setSubmitting);
  },
})(FeedbackForm);
