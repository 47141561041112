import React, { useEffect } from 'react';
import _ from 'lodash';
import AddPortfolioForm from './AddPortfolioForm/AddPortfolioForm';
import { RouteAddPortfolioSD, RouteAddPortfolioLinkBackSD } from './styled';
import { Link, useHistory, useParams } from 'react-router-dom';
import IconChevronLeft from '../../components/icons/IconChevronLeft';
import ClientInformation from './ClientInformation/ClientInformation';
import { Grid } from '@material-ui/core';
import DialogSaveSuccess from './DialogSaveSuccess/DialogSaveSuccess';
import Api from '../../api/api';
import reducer from './reducer/reducer';
import { initialState } from './reducer/reducer';
import { ROUTE_ADD_PORTFOLIO_ACTIONS } from './reducer/types';
import { useAddPortfolioReducer } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/configureStore';
import { getClient } from '../../store/commonStates/clients/actions';
import { RootState } from '../../store/rootReducer';
import { IClient } from '../../store/commonStates/clients/types';
import { getErrorsByTemplate, propValueOr } from '../../helpers/common';

interface IUseSelectorProps {
  client: Partial<IClient>;
}

function RouteAddPortfolio() {
  const history = useHistory();
  const { client } = useSelector<RootState, IUseSelectorProps>(
    (state: RootState) => ({ client: state.clients.currentClient || {} })
  );
  const dispatch: AppDispatch = useDispatch();
  const [store, dispatchLocal] = useAddPortfolioReducer(reducer, initialState);
  const { clientId = '' } = useParams();

  useEffect(() => {
    dispatch(getClient(clientId));
  }, [clientId]);

  const createPortfolio = (
    values: any,
    setErrors: Function,
    setSubmitting: Function,
    setStatus: Function
  ) => {
    dispatchLocal({
      type: ROUTE_ADD_PORTFOLIO_ACTIONS.form.updateFields,
      payload: values,
    });

    Api.portfolios
      .create({ ...values, accountHolder: clientId })
      .then(() => {
        dispatchLocal({
          type: ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.open,
        });
      })
      .catch(err => {
        const template = {
          portfolio_number: 'portfolioNumber',
          cash_accounts: 'cashAccounts',
          custodian: 'custodian',
          account_holder: 'accountHolder',
          account_holders: 'accountHolders',
          security_accounts: 'securityAccounts',
          security_number: 'securityNumber',
          phone_number: 'phoneNumber',
          bank_contacts: 'bankContacts',
        };

        const transformedErrors = getErrorsByTemplate(
          _.get(err, 'response.data', {}),
          template
        );

        if (_.get(transformedErrors, 'securityAccounts', undefined)) {
          let caseMapping: { [key: string]: string } = {
            security_number: 'securityNumber',
            security_accounts: 'securityAccounts',
          };
          let securityAccounts = Object();
          transformedErrors.securityAccounts.forEach((v: any, k: number) => {
            if (v instanceof String) {
              if (!securityAccounts.hasOwnProperty('securityAccounts')) {
                securityAccounts['securityAccounts'] = []
              }
              securityAccounts['securityAccounts'].push(v)
            } else if (v instanceof Object && 'path' in v && v['path']) { // Object
              let path = v['path'].split(' ').map((vv: any, kk: number) => {
                if (!isNaN(vv)) {
                  return '[' + vv + ']'
                }
                let key = caseMapping[String(vv)] || String(vv);
                return kk === 0 ? key : '.' + key
              }).join('');

              if (!securityAccounts.hasOwnProperty(path)) {
                securityAccounts[path] = []
              }
              securityAccounts[path].push(v['message'])
            }
          });

          for (let prop in securityAccounts) {
            transformedErrors[prop] = securityAccounts[prop];
          }
        }

        if (_.get(transformedErrors, 'cashAccounts', undefined)){
          let caseMapping: { [key: string]: string } = {
            iban: 'iban',
            cash_accounts: 'cashAccounts',
          };
          let cashAccounts = Object();
          transformedErrors.cashAccounts.forEach((v: any, k: number) => {
            if (v instanceof String) {
              if (!cashAccounts.hasOwnProperty('cashAccounts')) {
                cashAccounts['cashAccounts'] = []
              }
              cashAccounts['cashAccounts'].push(v)
            } else if (v instanceof Object && 'path' in v && v['path']) { // Object
              let path = v['path'].split(' ').map((vv: any, kk: number) => {
                if (!isNaN(vv)) {
                  return '[' + vv + ']'
                }
                let key = caseMapping[String(vv)] || String(vv);
                return kk === 0 ? key : '.' + key
              }).join('');
              if (!cashAccounts.hasOwnProperty(path)) {
                cashAccounts[path] = []
              }
              cashAccounts[path].push(v['message'])
            }

          });
          for (let prop in cashAccounts) {
            transformedErrors[prop] = cashAccounts[prop];
          }

        }

        transformedErrors.bankAdvisorContact = _.get(
          transformedErrors,
          'bankContacts[0]',
          {}
        );
        transformedErrors.bankITContact = _.get(
          transformedErrors,
          'bankContacts[1]',
          {}
        );

        setErrors(transformedErrors);
        setStatus({ error: true });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const closeDialogSaveSuccess = (value?: string) => {
    if (!propValueOr(store, 'form.addAnother', false)) {
      history.push('/my-clients');
    } else {
      dispatch(getClient(clientId));
      if (value === 'same') {
        dispatchLocal({
          type: ROUTE_ADD_PORTFOLIO_ACTIONS.form.updateFields,
          payload: {
            portfolioNumber: '',
          },
        });
        dispatchLocal({
          type: ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.close,
        });
      } else {
        dispatchLocal({
          type: ROUTE_ADD_PORTFOLIO_ACTIONS.form.reset,
        });
        dispatchLocal({
          type: ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.close,
        });
      }
    }
  };

  return (
    <RouteAddPortfolioSD>
      <RouteAddPortfolioLinkBackSD
        component={Link}
        to="/my-clients"
        color="primary"
        size="small"
        variant="text"
        startIcon={<IconChevronLeft />}
      >
        Go back to My Clients page
      </RouteAddPortfolioLinkBackSD>

      <Grid container spacing={3} justify="space-between">
        <Grid item lg={7} sm={6}>
          <AddPortfolioForm
            handleSubmit={createPortfolio}
            {...propValueOr(store, 'form', {})}
          />
        </Grid>
        <Grid item lg={4} sm={6}>
          <ClientInformation />
        </Grid>
      </Grid>
      <DialogSaveSuccess
        onClose={closeDialogSaveSuccess}
        addAnother={propValueOr(store, 'form.addAnother', false)}
        open={store.dialogSaveSuccessOpen}
        fullName={`${client.firstName} ${client.lastName}`}
        customerNumber={client.customerNumber}
        bankName={propValueOr(store, 'form.custodian.name', '')}
      />
    </RouteAddPortfolioSD>
  );
}

export default RouteAddPortfolio;
