import React from 'react';
import _ from 'lodash';
import * as Yup from 'yup';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { FieldArray, FormikProps, getIn, withFormik } from 'formik';
import IconAddCircle from '../../../components/icons/IconAddCircle';
import {
  AddPortfolioFormButtonCARemoveSD,
  AddPortfolioFormButtonCASD,
  AddPortfolioFormButtonSARemoveSD,
  AddPortfolioFormButtonSASD,
  AddPortfolioFormFieldSD,
  AddPortfolioFormSD,
  AddPortfolioFormTitleSD,
  AddPortfolioFormCATextFieldSD,
  AddPortfolioFormCASelectSD,
  AddPortfolioFormFieldListSD,
  AddPortfolioFormFieldItemSD,
  AddPortfolioFormCAFieldItemSD,
  AddPortfolioFormButtonsSD,
  AddPortfolioFormButtonSD,
  PoliciesButtonSD,
} from './styled';
import {
  TooltipKitButtonSD,
  TooltipKitSD,
  TooltipKitTitleSD,
} from '../../../components/kit/TooltipKit/styled';
import IconInfo from '../../../components/icons/IconInfo';
import { ICurrency, useCurrency } from './hooks/useCurrency';
import {
  ICashAccount,
  ISecurityAccount,
} from '../../../store/commonStates/clients/types';
import {useAccountType} from "./hooks/useAccountType";
import {ICustodian, useCustodian} from "./hooks/useCustodian";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Link, useParams} from "react-router-dom";
import IconEdit from "../../../components/icons/IconEdit";
import Api from "../../../api/api";


interface BankAdvisorContact {
  id?: number;
  contactType?: string;
  fullName?: string;
  email?: string;
  phoneNumber?: string;
}

interface BankITContact {
  id?: number;
  contactType?: string;
  fullName?: string;
  email?: string;
  phoneNumber?: string;
}

export interface AddPortfolioFormValues {
  id: number,
  custodian: ICustodian;
  portfolioNumber: string;
  accountHolders: string;
  securityAccounts: Array<ISecurityAccount>;
  cashAccounts: Array<ICashAccount>;
  bankAdvisorContact: BankAdvisorContact;
  bankITContact: BankITContact;
  status: string;
  noticeSubmitted: string;
  dateOfCancellation: any;
  statusChangedOn: any;
  policiesNum: number;
}

export interface AddPortfolioFormProps {
  id?: number,
  custodian?: ICustodian;
  portfolioNumber?: string;
  accountHolders?: string;
  securityAccounts?: Array<ISecurityAccount>;
  cashAccounts?: Array<ICashAccount>;
  bankAdvisorContact?: BankAdvisorContact;
  status?: string;
  noticeSubmitted?: string;
  dateOfCancellation?: any;
  statusChangedOn?: any;
  bankITContact?: BankITContact;
  handleSubmit: Function;
  type?: string;
  onCancel?: Function;
  policiesNum?: number;
}

interface OtherProps {
  type?: string;
  onCancel?: Function;
}

const useStyles = makeStyles(() => ({
  divider: {
    height: '3px',
    backgroundColor: "#7d7d7d",
  },
  tooltipKit: {
    marginBottom: "18px",
  }
}));

function AddPortfolioForm(
  props: OtherProps & FormikProps<AddPortfolioFormValues>
) {
  const currencies = useCurrency();
  const accountTypes = useAccountType();
  const custodians = useCustodian();
  const {
    values,
    status,
    handleChange,
    errors,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
    type = 'create',
    onCancel = () => ({}),
  } = props;
  const classes = useStyles();

  const handleCancel = () => {
    onCancel();
  };
  const { clientId = '', portfolioId = '' } = useParams();
  const isInvalidAccountNumber =
    Boolean(touched.portfolioNumber && errors.portfolioNumber) ||
    (errors.portfolioNumber && _.get(status, 'error', false));

  const isInvalidBankITContactPhone = Boolean(
    (getIn(errors, 'bankITContact.phoneNumber') &&
      getIn(touched, 'bankITContact.phoneNumber')) ||
      (getIn(errors, 'bankITContact.phoneNumber') && _.get(status, 'error'))
  );

  const isInvalidBankAdvisorContactPhone =
    getIn(errors, 'bankAdvisorContact.phoneNumber') &&
    getIn(
      touched,
      'bankAdvisorContact.phoneNumber' ||
        (getIn(errors, 'bankAdvisorContact.phoneNumber') &&
          _.get(status, 'error'))
    );
  const topCurrencies = currencies.filter(v => v.mostUsed);
  const commonCurrencies = currencies.filter(v => !v.mostUsed);

  const onChangeHandlerCustodian = (e:any, value:any) => {
    setFieldValue('custodian.name', value)
    let result  = custodians.filter(function(o){
      return o.name === value;
    });
    if (result[0]){
      setFieldValue('custodian.address', result[0].address)
    }
  };

  const cancelOrRevokePolicy = (type: string) => {
      Api.portfolios
      .cancelPolicy(portfolioId, type)
      .then((response) => {
        setFieldValue('status', response.data.status, false);
        setFieldValue('statusChangedOn', response.data.status_changed_on, false);
        setFieldValue('noticeSubmitted', response.data.notice_requested, false);
        setFieldValue('dateOfCancellation', '', false);
      }).catch(err => {
        console.log('err', err)
      })
    }

  return (
    <AddPortfolioFormSD
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        setFieldValue('addAnother', false, false);
        handleSubmit();
      }}
    >
      <AddPortfolioFormTitleSD variant="h1" data-test="title">
        {type === 'edit' ? 'Edit' : 'Add New'} Portfolio
      </AddPortfolioFormTitleSD>
      <Grid container>
        <Grid item md={9} xs={12}>
          <FormControl
          variant="filled"
          fullWidth
          error={Boolean(getIn(touched, 'custodian.name') && getIn(errors, 'custodian.name'))}>
            <AddPortfolioFormFieldSD>
              <Autocomplete
                onChange={onChangeHandlerCustodian}
                freeSolo
                value={values.custodian.name}
                onBlur={(e:any) => setFieldValue('custodian.name', e.target.value)}
                options={custodians.map((option) => option.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Custodian"
                    name="custodian.name"
                    helperText="*Required"
                    fullWidth
                    variant="filled"
                    error={Boolean(getIn(errors, 'custodian.name') &&
                    getIn(touched, 'custodian.name'))}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {
                            <TooltipKitSD
                             title={
                               <TooltipKitTitleSD>
                                 E.g. bank, custodian bank, financial institution,
                                 financial service provider, <i>Depotbank</i>,
                               </TooltipKitTitleSD>
                             }
                             >
                              <TooltipKitButtonSD className={classes.tooltipKit}>
                                <IconInfo />
                              </TooltipKitButtonSD>
                            </TooltipKitSD>
                          }
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </AddPortfolioFormFieldSD>
          </FormControl>
          <AddPortfolioFormFieldSD>
            <TextField
              label="Custodian Address"
              variant="filled"
              helperText="*Required"
              fullWidth
              name="custodian.address"
              value={values.custodian.address}
              onChange={handleChange}
              error={Boolean(
                 getIn(errors, 'custodian.address')
              )}
              onBlur={handleBlur}
            />
          </AddPortfolioFormFieldSD>
          <AddPortfolioFormFieldSD>
            <TextField
              label="Portfolio Number"
              variant="filled"
              helperText={
                isInvalidAccountNumber
                  ? errors.portfolioNumber
                  : '*Required'
              }
              fullWidth
              name="portfolioNumber"
              value={values.portfolioNumber}
              onChange={handleChange}
              error={Boolean(isInvalidAccountNumber)}
              onBlur={handleBlur}
            />
          </AddPortfolioFormFieldSD>
          <AddPortfolioFormFieldSD mb={50}>
            <TextField
              label="Account Holder(s)"
              variant="filled"
              helperText="*Required"
              fullWidth
              name="accountHolders"
              value={values.accountHolders}
              onChange={handleChange}
              error={Boolean(touched.accountHolders && errors.accountHolders)}
              onBlur={handleBlur}
            />
          </AddPortfolioFormFieldSD>
          {type === 'edit' &&
          <Grid container>
            <Grid container>
              <PoliciesButtonSD
                component={Link}
                to={`/edit-policies/${clientId}/${portfolioId}`}
                color="primary"
                size="small"
                variant="text"
                startIcon={<IconEdit />}
                data-test="button-edit-portfolio"
              >
                Add portfolio policies
              </PoliciesButtonSD>
              <div style={{paddingTop: '7px'}}>
                Active portfolio policies = {values.policiesNum}
              </div>
            </Grid>
            <Grid container>
            <AddPortfolioFormTitleSD variant="subtitle1" color="primary">
              Client status
            </AddPortfolioFormTitleSD>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={10} md={values.dateOfCancellation ? 4 : 6}>
                  <AddPortfolioFormTitleSD variant="subtitle2" color="primary">
                    Status
                  </AddPortfolioFormTitleSD>
                  <AddPortfolioFormTitleSD variant="body2">
                    {values.status}
                  </AddPortfolioFormTitleSD>
              </Grid>
              <Grid item xs={10} md={values.dateOfCancellation ? 4 : 6}>
                  <AddPortfolioFormTitleSD variant="subtitle2" color="primary">
                    Status changed on
                  </AddPortfolioFormTitleSD>
                  <AddPortfolioFormTitleSD variant="body2">
                    {values.statusChangedOn}
                  </AddPortfolioFormTitleSD>
              </Grid>
              {values.dateOfCancellation &&
              <Grid item xs={10} md={4}>
                <AddPortfolioFormTitleSD variant="subtitle2" color="primary">
                  Portfolio cancelled as of
                </AddPortfolioFormTitleSD>
                <AddPortfolioFormTitleSD variant="body2">
                  {values.dateOfCancellation}
                </AddPortfolioFormTitleSD>
              </Grid>}
            </Grid>
            <Grid item>
              <AddPortfolioFormButtonsSD>
                <AddPortfolioFormButtonSD
                  color="secondary"
                  variant="contained"
                  disabled={!!values.noticeSubmitted}
                  onClick={e => {
                    cancelOrRevokePolicy("cancel");
                  }}
                >
                  Cancel portfolio
                </AddPortfolioFormButtonSD>
                {values.noticeSubmitted &&
                <AddPortfolioFormButtonSD
                  color="secondary"
                  variant="contained"
                  onClick={e => {
                    cancelOrRevokePolicy("revoke");
                  }}
                >
                  Revoke notice
                </AddPortfolioFormButtonSD>}
                {values.noticeSubmitted &&
                <AddPortfolioFormTitleSD variant="body2">
                  Notice submitted on {values.noticeSubmitted}
                </AddPortfolioFormTitleSD>
                }
              </AddPortfolioFormButtonsSD>
            </Grid>
          </Grid>}
          <AddPortfolioFormTitleSD variant="h3" color="primary">
            Security Account
          </AddPortfolioFormTitleSD>
          <FieldArray
            name="securityAccounts"
            render={({ push, remove }) => (
              <AddPortfolioFormFieldListSD>
                {values.securityAccounts.map((securityAccount, index) => {
                  const securityNumber = `securityAccounts[${index}].securityNumber`;
                  const errorSecurityNumber = _.get(errors, securityNumber, undefined);
                  return (
                    <AddPortfolioFormFieldItemSD key={securityNumber}>
                      <TextField
                        label="Account Number"
                        variant="filled"
                        helperText={
                          errorSecurityNumber
                          ? Array.isArray(errorSecurityNumber) ? errorSecurityNumber.join('\n') : errorSecurityNumber
                          : "*Required"
                        }
                        fullWidth
                        name={securityNumber}
                        value={securityAccount.securityNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errorSecurityNumber)}
                      />
                      {index > 0 && (
                        <AddPortfolioFormButtonSARemoveSD
                          onClick={() => remove(index)}
                          data-test="button-remove-security-account"
                        >
                          <IconAddCircle />
                        </AddPortfolioFormButtonSARemoveSD>
                      )}
                    </AddPortfolioFormFieldItemSD>
                  );
                })}
                <AddPortfolioFormButtonSASD
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => push({ securityNumber: '' })}
                  startIcon={<IconAddCircle />}
                  data-test="button-add-security-account"
                >
                  Add security account
                </AddPortfolioFormButtonSASD>
              </AddPortfolioFormFieldListSD>
            )}
          />
          <AddPortfolioFormTitleSD variant="h3" color="primary">
            Cash and other accounts
          </AddPortfolioFormTitleSD>
        </Grid>
        <Grid item md={12}>
          <FieldArray name="cashAccounts">
            {({ push, remove }) => (
              <AddPortfolioFormFieldListSD>
                {values.cashAccounts.map((account, index) => {
                  const iban = `cashAccounts[${index}].iban`;
                  const errorIban = _.get(errors, iban, undefined);

                  const accountNo = `cashAccounts[${index}].accountNo`;
                  const errorAccountNo = _.get(errors, accountNo, undefined);

                  const currency = `cashAccounts[${index}].currency`;
                  const errorCurrency = _.get(errors, currency, undefined);

                  const accountType = `cashAccounts[${index}].accountType`;
                  const errorAccountType = _.get(errors, accountType, undefined);

                  return (
                    <AddPortfolioFormCAFieldItemSD key={`cashAccounts-${index}`}>
                      <AddPortfolioFormCATextFieldSD
                        label="Account number"
                        variant="filled"
                        helperText={
                          errorAccountNo
                          ? Array.isArray(errorAccountNo) ? errorAccountNo.join('\n') : errorAccountNo
                          : "*Iban or Account Number are required"
                        }
                        name={accountNo}
                        value={account.accountNo ? account.accountNo: ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errorAccountNo)}
                      />
                      <AddPortfolioFormCATextFieldSD
                        label="Iban"
                        variant="filled"
                        helperText={
                          errorIban
                          ? Array.isArray(errorIban) ? errorIban.join('\n') : errorIban
                          : "*Iban or Account Number are required"
                        }
                        name={iban}
                        value={account.iban ? account.iban: ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errorIban)}
                      />
                      <AddPortfolioFormCASelectSD variant="filled" error={Boolean(errorCurrency)}>
                        <InputLabel htmlFor={currency}>Currency</InputLabel>
                        <Select
                          IconComponent={props => (
                            <KeyboardArrowDown {...props} />
                          )}
                          name={currency}
                          value={account.currency}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id={currency}
                          displayEmpty
                          MenuProps={{
                            MenuListProps: {
                              style: { maxHeight: '263px', overflowY: 'auto' },
                            },
                          }}
                        >
                          {topCurrencies.length > 0 &&
                            topCurrencies.map((currency: ICurrency) => (
                              <MenuItem key={currency.id} value={currency.id}>
                                {currency.code}
                              </MenuItem>
                            ))}
                          {topCurrencies.length > 0 && <Divider className={classes.divider} />}
                          {commonCurrencies.map((currency: ICurrency) => (
                            <MenuItem key={currency.id} value={currency.id}>
                              {currency.code}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{errorCurrency
                          ? Array.isArray(errorCurrency) ? errorCurrency.join('\n') : errorCurrency: null
                        }</FormHelperText>
                      </AddPortfolioFormCASelectSD>
                      <AddPortfolioFormCASelectSD variant="filled" error={Boolean(errorAccountType)}>
                        <InputLabel htmlFor={accountType}>Type</InputLabel>
                        <Select
                          IconComponent={props => (
                            <KeyboardArrowDown {...props} />
                          )}
                          name={accountType}
                          value={account.accountType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id={accountType}
                          MenuProps={{
                            MenuListProps: {
                              style: { maxHeight: '263px', overflowY: 'auto' },
                            },
                          }}
                        >
                          {accountTypes.map((v: any) => (
                            <MenuItem key={v.value} value={v.value}>
                              {v.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{errorAccountType
                          ? Array.isArray(errorAccountType) ? errorAccountType.join('\n') : errorAccountType
                          : "*Required"}</FormHelperText>
                      </AddPortfolioFormCASelectSD>
                      {index > 0 && (
                        <AddPortfolioFormButtonCARemoveSD
                          onClick={() => remove(index)}
                          data-test="button-remove-cash-account"
                        >
                          <IconAddCircle />
                        </AddPortfolioFormButtonCARemoveSD>
                      )}
                    </AddPortfolioFormCAFieldItemSD>
                  );
                })}
                <AddPortfolioFormButtonCASD
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={() => push({ iban: '', currency: '' })}
                  startIcon={<IconAddCircle />}
                  data-test="button-add-cash-account"
                >
                  Add account
                </AddPortfolioFormButtonCASD>
              </AddPortfolioFormFieldListSD>
            )}
          </FieldArray>
        </Grid>
        <Grid item>
          <AddPortfolioFormTitleSD variant="h3" color="primary">
            Contact Information
          </AddPortfolioFormTitleSD>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={10} md={6}>
            <AddPortfolioFormTitleSD variant="subtitle1" color="primary">
              Bank Advisor Contact
            </AddPortfolioFormTitleSD>
            <AddPortfolioFormFieldSD>
              <TextField
                label="Full Name"
                variant="filled"
                // helperText="*Required"
                fullWidth
                name="bankAdvisorContact.fullName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankAdvisorContact.fullName}
                error={Boolean(
                  getIn(errors, 'bankAdvisorContact.fullName') &&
                    getIn(touched, 'bankAdvisorContact.fullName')
                )}
              />
            </AddPortfolioFormFieldSD>
            <AddPortfolioFormFieldSD>
              <TextField
                label="Email"
                variant="filled"
                helperText={
                  Boolean(
                  getIn(errors, 'bankAdvisorContact.email'))
                  ? _.get(errors, 'bankAdvisorContact.email', undefined) : ''
                }
                fullWidth
                name="bankAdvisorContact.email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankAdvisorContact.email}
                error={Boolean(
                  getIn(errors, 'bankAdvisorContact.email') &&
                    getIn(touched, 'bankAdvisorContact.email')
                )}
              />
            </AddPortfolioFormFieldSD>
            <AddPortfolioFormFieldSD>
              <TextField
                label="Phone Number"
                variant="filled"
                helperText={
                  isInvalidBankAdvisorContactPhone
                    ? getIn(errors, 'bankAdvisorContact.phoneNumber')
                    : 'Example +496912345678'
                }
                fullWidth
                name="bankAdvisorContact.phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankAdvisorContact.phoneNumber}
                error={Boolean(isInvalidBankAdvisorContactPhone)}
              />
            </AddPortfolioFormFieldSD>
          </Grid>
          <Grid item xs={10} md={6}>
            <AddPortfolioFormTitleSD variant="subtitle1" color="primary">
              Bank IT Contact
            </AddPortfolioFormTitleSD>
            <AddPortfolioFormFieldSD>
              <TextField
                label="Full Name"
                variant="filled"
                // helperText="*Required"
                fullWidth
                name="bankITContact.fullName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankITContact.fullName}
                error={Boolean(
                  getIn(errors, 'bankITContact.fullName') &&
                    getIn(touched, 'bankITContact.fullName')
                )}
              />
            </AddPortfolioFormFieldSD>
            <AddPortfolioFormFieldSD>
              <TextField
                label="Email"
                variant="filled"
                helperText={
                  Boolean(
                  getIn(errors, 'bankITContact.email'))
                  ? _.get(errors, 'bankITContact.email', undefined) : ''
                }
                fullWidth
                name="bankITContact.email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankITContact.email}
                error={Boolean(
                  getIn(errors, 'bankITContact.email') &&
                    getIn(touched, 'bankITContact.email')
                )}
              />
            </AddPortfolioFormFieldSD>
            <AddPortfolioFormFieldSD>
              <TextField
                label="Phone Number"
                variant="filled"
                helperText={
                  isInvalidBankITContactPhone
                    ? getIn(errors, 'bankITContact.phoneNumber')
                    : 'Example +496912345678'
                }
                fullWidth
                name="bankITContact.phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankITContact.phoneNumber}
                error={Boolean(isInvalidBankITContactPhone)}
              />
            </AddPortfolioFormFieldSD>
          </Grid>
        </Grid>
      </Grid>
      {type === 'edit' ? (
        <AddPortfolioFormButtonsSD data-test="form-actions-edit">
          <AddPortfolioFormButtonSD
            color="primary"
            variant="outlined"
            data-test="button-cancel"
            onClick={handleCancel}
          >
            Cancel
          </AddPortfolioFormButtonSD>
          <AddPortfolioFormButtonSD
            type="submit"
            color="primary"
            variant="contained"
            data-test="button-save"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setFieldValue('addAnother', false, false);
              handleSubmit();
            }}
          >
            Save Changes
          </AddPortfolioFormButtonSD>
        </AddPortfolioFormButtonsSD>
      ) : (
        <AddPortfolioFormButtonsSD data-test="form-actions-create">
          <AddPortfolioFormButtonSD
            type="submit"
            color="primary"
            variant="outlined"
            data-test="button-save"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setFieldValue('addAnother', false, false);
              handleSubmit();
            }}
          >
            Save
          </AddPortfolioFormButtonSD>
          <AddPortfolioFormButtonSD
            type="button"
            color="primary"
            variant="contained"
            data-test="button-save-and-add"
            onClick={() => {
              setFieldValue('addAnother', true, false);
              handleSubmit();
            }}
          >
            Save & Add Another Portfolio
          </AddPortfolioFormButtonSD>
        </AddPortfolioFormButtonsSD>
      )}
    </AddPortfolioFormSD>
  );
}

export default withFormik<AddPortfolioFormProps, AddPortfolioFormValues>({
  mapPropsToValues: ({
    id = 0,
    custodian = {name: '', address: ''},
    accountHolders = '',
    portfolioNumber = '',
    securityAccounts = [{ securityNumber: '' }],
    cashAccounts = [{ iban: '', accountNo: '', currency: '', accountType: '' }],
    bankAdvisorContact = { fullName: '', email: '', phoneNumber: '' },
    bankITContact = { fullName: '', email: '', phoneNumber: '' },
    status = '',
    noticeSubmitted = '',
    dateOfCancellation = null,
    statusChangedOn = '',
    policiesNum= 0,
  }) => ({
    id,
    custodian,
    accountHolders,
    portfolioNumber,
    securityAccounts,
    cashAccounts,
    bankAdvisorContact,
    bankITContact,
    status,
    noticeSubmitted,
    dateOfCancellation,
    statusChangedOn,
    policiesNum,
  }),
  enableReinitialize: true,
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    custodian: Yup.object().shape({
      name: Yup.string().required('Custodian is required'),
      address: Yup.string().required('Custodian address is required'),
    }),
    portfolioNumber: Yup.string().required('Account number is required'),
    accountHolders: Yup.string().required('Account holder(s) is required'),
    securityAccounts: Yup.array().when('cashAccounts',
      (cashAccounts: any)=>{
         if(cashAccounts && !(cashAccounts.some((val: object) => {
           return Boolean(val['iban']) || Boolean(val['accountNo'])
         }))){
           return Yup.array().of(Yup.object().shape({securityNumber: Yup.string().required('Please enter at least one account.')}));
         } else if (cashAccounts && !(cashAccounts.some((val: object) => {return Boolean(val['iban'])}))){

         }
    }),
    cashAccounts: Yup.array().when('securityAccounts',
      (securityAccounts: any)=>{
         if(securityAccounts && !(securityAccounts.some((val: object) => {
           return Boolean(val['securityNumber'])
         }))){
           return Yup.array().of(Yup.object().shape({
            iban: Yup.string().when('accountNo',
                {is: accountNo => accountNo && accountNo.length !== 0, then: Yup.string().nullable(), otherwise: Yup.string().required('Please enter at least one account.')}
            ),
            accountNo: Yup.string().when('iban',
                {is: iban => iban && iban.length !== 0, then: Yup.string().nullable(), otherwise: Yup.string().required('Please enter at least one account.')}
            ),
            accountType: Yup.number().when('iban', {
              is: iban => iban && iban.length !== 0 ,
              then: Yup.number().required('Account type is required'),
              otherwise: Yup.number()
            })}, [['iban', 'accountNo']]));
         }
    }),

  }, [['securityAccounts', 'cashAccounts']]),
  handleSubmit: (
    values: AddPortfolioFormValues,
    { props, setErrors, setSubmitting, setStatus }
  ) => {
    props.handleSubmit(values, setErrors, setSubmitting, setStatus);
  },
})(AddPortfolioForm);
