import styled from 'styled-components';
import { Paper, TextField, Typography } from '@material-ui/core';

export const DialogEmailSD = styled.div``;

export const DialogEmailPaperSD = styled(Paper)`
  padding-top: 25px;
  padding-bottom: 27px;
  background: #fbfbfd;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
`;

export const DialogEmailHeaderSD = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 200px;
  height: 100%;
`;

export const DialogEmailTitleSD = styled(Typography)``;

export const DialogEmailContainerSD = styled.div`
  max-width: 1190px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
`;

export const DialogEmailSelectSD = styled(TextField)`
  width: 200px;

  .MuiSelect-root {
    background-color: #fff;
  }
`;

export const DialogEmailTextareaSD = styled(TextField)`
  .MuiFilledInput-multiline {
    background-color: #fff;
  }

  .MuiFilledInput-underline:before {
    border-color: ${({ theme }) => theme.palette.primary[40]};
  }
`;
