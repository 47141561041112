export const QUALITY_ENGINEER_ACTIONS = {
  reconsiliations: {
    request: 'QUALITY_ENGINEER / RECONCILIATIONS_REQUEST',
    success: 'QUALITY_ENGINEER / RECONCILIATIONS_SUCCESS',
    error: 'QUALITY_ENGINEER / RECONCILIATIONS_ERROR',
    reset: 'QUALITY_ENGINEER / RECONCILIATIONS_RESET',
  },
  statistics: {
    request: 'QUALITY_ENGINEER / STATISTICS_REQUEST',
    success: 'QUALITY_ENGINEER / STATISTICS_SUCCESS',
    error: 'QUALITY_ENGINEER / STATISTICS_ERROR',
    reset: 'QUALITY_ENGINEER / STATISTICS_RESET',
  },
};

export interface IReconciliation {
  id: number;
  status: number;
  updatedAt: string;
  createdAt: string;
  portfolio: {
    id: number;
    custodian: string;
    portfolioNumber: string;
  };
}

export interface QualityEngineerState {
  isReconsiliationsFetching: boolean;
  reconsiliations: IReconciliation[];
  total: number | null;
  isStatisticsFetching: boolean;
  statistics: {
    brokenNotProcessed: number;
    brokenProcessed: number;
    success: number;
    total: number;
  };
}

interface ReconciliationsRequestAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.reconsiliations.request;
}

interface ReconciliationsSuccessAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.reconsiliations.success;
  payload: {
    reconsiliations: IReconciliation[];
    total: number;
  };
}

interface ReconciliationsErrorAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.reconsiliations.error;
}

interface ReconciliationsResetAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.reconsiliations.reset;
}

interface StatisticsRequestAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.statistics.request;
}

interface StatisticsSuccessAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.statistics.success;
  payload: {
    brokenNotProcessed: number;
    brokenProcessed: number;
    success: number;
    total: number;
  };
}

interface StatisticsErrorAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.statistics.error;
}

interface StatisticsResetAction {
  type: typeof QUALITY_ENGINEER_ACTIONS.statistics.reset;
}

export type QualityEngineerActionTypes =
  | ReconciliationsRequestAction
  | ReconciliationsSuccessAction
  | ReconciliationsErrorAction
  | ReconciliationsResetAction
  | StatisticsRequestAction
  | StatisticsSuccessAction
  | StatisticsErrorAction
  | StatisticsResetAction;
