import { ROUTE_ADD_PORTFOLIO_ACTIONS, RouteAddPortfolioTypes } from './types';

export const initialState = {
  dialogSaveSuccessOpen: false,
  dialogSaveAndCreateSuccessOpen: false,
  portfolioInfo: { fullName: '', customerNumber: '' },
  form: null,
};

export default (state: any = initialState, action: RouteAddPortfolioTypes) => {
  switch (action.type) {
    case ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.open:
      return {
        ...state,
        dialogSaveSuccessOpen: true,
      };
    case ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.close:
      return {
        ...state,
        dialogSaveSuccessOpen: false,
      };
    case ROUTE_ADD_PORTFOLIO_ACTIONS.portfolioInfo.update:
      return {
        ...state,
        portfolioInfo: action.payload,
      };
    case ROUTE_ADD_PORTFOLIO_ACTIONS.form.updateFields:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      };
    case ROUTE_ADD_PORTFOLIO_ACTIONS.form.reset:
      return {
        ...state,
        form: null,
      };
    default:
      return state;
  }
};
