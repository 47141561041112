import React from 'react';
import { LayoutQualityEngineerSD } from './styled';
import Header from '../../components/smart/Header/Header';
import { QE_NAV_ITEMS } from './constants/common';

interface LayoutQualityEngineerProps {
  children: React.ReactNode;
}

function LayoutQualityEngineer({ children }: LayoutQualityEngineerProps) {
  return (
    <LayoutQualityEngineerSD>
      <Header navItems={QE_NAV_ITEMS} />
      {children}
    </LayoutQualityEngineerSD>
  );
}

export default LayoutQualityEngineer;
