import React, { Fragment, useEffect } from 'react';
import {
  RouteQEReconciliationBreaksContainerSD,
  RouteQEReconciliationBreaksContentSD,
  RouteQEReconciliationBreaksFilterSD,
  RouteQEReconciliationBreaksHeaderSD,
  RouteQEReconciliationBreaksSD,
  RouteQEReconciliationBreaksTableSD,
  RouteQEReconciliationBreaksTitleSD,
} from './styled';
import imageDecor from '../../../static/images/decore-reconciliation-breaks.png';
import PageBlank from '../../../components/presentation/PageBlank/PageBlank';
import { Grid } from '@material-ui/core';
import ReconciliationsTable from './ReconciliationsTable/ReconciliationsTable';
import Filter from '../Filter/Filter';
import PaginationKit from '../../../components/kit/PaginationKit/PaginationKit';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { IReconciliation } from '../store/types';
import {
  getReconsiliations,
  getReconsiliationsStatistics,
} from '../store/actions';
import StatusesFilter from './StatusesFilter/StatusesFilter';
import { useQuery } from '../../../hooks/useQuery';
import { ReconciliationTableHeadCells } from './ReconciliationsTable/constants/common';
import { RECONCILIATION_PAGE_SIZE } from './constants/common';
import { resetFilter } from '../Filter/store/actions';

interface SelectorProps {
  totalCount: number | null;
  total: number;
  reconsiliations: IReconciliation[];
  selectedCustodians: string[];
  search: string | null;
  dateRange: string[];
}

function RouteQEReconciliationBreaks() {
  const dispatch = useDispatch();
  const [
    { order = 'asc', order_by = ReconciliationTableHeadCells[0].id, page = 1 },
  ] = useQuery();

  const {
    totalCount,
    reconsiliations,
    total,
    search,
    selectedCustodians,
    dateRange,
  } = useSelector<RootState, SelectorProps>((state: RootState) => ({
    totalCount: state.qualityEngineer.root.statistics.total,
    reconsiliations: state.qualityEngineer.root.reconsiliations,
    total: state.qualityEngineer.root.total || 0,
    selectedCustodians: state.qualityEngineer.filter.selectedCustodians,
    search: state.qualityEngineer.filter.search,
    dateRange: state.qualityEngineer.filter.dateRange,
  }));

  const [activeStatus, setActiveStatus] = React.useState<string | number>(
    'total'
  );

  useEffect(() => {
    dispatch(
      // @ts-ignore
      getReconsiliations({
        order,
        order_by,
        page,
        page_size: RECONCILIATION_PAGE_SIZE,
        custodians: selectedCustodians,
        search,
        dateRange,
        ...(activeStatus !== 'total' ? { status: activeStatus } : {}),
      })
    );
    dispatch(getReconsiliationsStatistics());
  }, [
    activeStatus,
    order,
    order_by,
    page,
    selectedCustodians,
    search,
    dateRange,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, []);

  return (
    <RouteQEReconciliationBreaksSD>
      <RouteQEReconciliationBreaksContainerSD>
        {Boolean(totalCount) ? (
          <RouteQEReconciliationBreaksContentSD>
            <RouteQEReconciliationBreaksHeaderSD container spacing={2}>
              <Grid item xs={8}>
                <RouteQEReconciliationBreaksTitleSD
                  variant="h1"
                  color="primary"
                >
                  Reconciliation
                </RouteQEReconciliationBreaksTitleSD>
                <StatusesFilter
                  activeStatus={activeStatus}
                  onChangeStatus={(status: string | number) =>
                    setActiveStatus(status)
                  }
                />
              </Grid>
              <RouteQEReconciliationBreaksFilterSD item xs={4}>
                <Filter />
              </RouteQEReconciliationBreaksFilterSD>
            </RouteQEReconciliationBreaksHeaderSD>
            <RouteQEReconciliationBreaksTableSD>
              <ReconciliationsTable data={reconsiliations} />
            </RouteQEReconciliationBreaksTableSD>
            <PaginationKit total={total} limit={RECONCILIATION_PAGE_SIZE} />
          </RouteQEReconciliationBreaksContentSD>
        ) : (
          <PageBlank
            title={'Reconciliation Breaks'}
            subtitle={'It seems like everything’s under control.'}
            renderText={() => (
              <Fragment>
                In this page you would be able to{' '}
                <strong>
                  {' '}
                  keep track of all GOIO’s reconciliation break statuses,
                  review, and process each of them.
                </strong>
                For now, it seems like you have no reconciliation breaks to take
                care of!
              </Fragment>
            )}
            image={imageDecor}
          />
        )}
      </RouteQEReconciliationBreaksContainerSD>
    </RouteQEReconciliationBreaksSD>
  );
}

export default RouteQEReconciliationBreaks;
