import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const LoginFormSD = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

export const LoginFormFieldSD = styled.div`
  margin-bottom: 26px;
`;

export const LoginFormButtonSD = styled(Button)`
  align-self: center;
`;
