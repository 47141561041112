import React from 'react';
import {
  BankInfoCountSD,
  BankInfoSD,
  BankInfoTitleSD,
  BankInfoHeaderSD,
  BankInfoTableSD,
  BankInfoArrowDownSD,
} from './styled';
import PortfolioTable from '../PortfolioTable/PortfolioTable';
import { Collapse } from '@material-ui/core';
import {
  PortfolioTableAccountSD,
  PortfolioTableAccountTitleSD,
  PortfolioTableAccountValueSD,
} from '../PortfolioTable/styled';

export interface BankInfoProps {
  name: string;
  portfolios?: Array<any>;
}

const columns = [
  {
    title: 'Portfolios',
    propName: 'portfolioNumber',
    innerWidth: '150px',
  },
  {
    title: 'Accounts',
    propName: 'accounts',
    innerWidth: 'grow',
    renderItem(rowData: any) {
      return (
        <React.Fragment>
          {rowData.securityAccounts.map((account: any) => (
            <PortfolioTableAccountSD key={account.id}>
              <PortfolioTableAccountTitleSD>
                Security
              </PortfolioTableAccountTitleSD>
              <PortfolioTableAccountValueSD>
                {account.securityNumber}
              </PortfolioTableAccountValueSD>
            </PortfolioTableAccountSD>
          ))}
          {rowData.cashAccounts.map((account: any) => (
            <PortfolioTableAccountSD key={account.iban}>
              <PortfolioTableAccountTitleSD>
                Cash({account.currency.code})
              </PortfolioTableAccountTitleSD>
              <PortfolioTableAccountValueSD>
                {account.iban ? account.iban: account.accountNo}
              </PortfolioTableAccountValueSD>
            </PortfolioTableAccountSD>
          ))}
        </React.Fragment>
      );
    },
  },
];

function BankInfo({ name, portfolios = [] }: BankInfoProps) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <BankInfoSD>
      <BankInfoHeaderSD
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <BankInfoTitleSD color="primary">{name}</BankInfoTitleSD>
        <BankInfoCountSD color="primary">
          {portfolios.length} Portfolio <BankInfoArrowDownSD />
        </BankInfoCountSD>
      </BankInfoHeaderSD>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <BankInfoTableSD>
          <PortfolioTable columns={columns} data={portfolios} />
        </BankInfoTableSD>
      </Collapse>
    </BankInfoSD>
  );
}

export default BankInfo;
