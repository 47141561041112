import { MOCK_CLIENT, MOCK_PORTFOLIO } from '../../../mocks/common';
import { ONBOARDING_PORTFOLIO_STATUSES } from '../constants/common';

export const MOCK_PORTFOLIOS = [
  {
    ...MOCK_PORTFOLIO,
    id: 1,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.requested,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 2,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.submitted,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 3,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.submitted,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 4,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.activated,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 5,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.requested,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 6,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.submitted,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 7,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.submitted,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 8,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.activated,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 9,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.submitted,
  },
  {
    ...MOCK_PORTFOLIO,
    id: 10,
    accountHolder: MOCK_CLIENT,
    updated: '2020-03-31T11:02:25.810968Z',
    status: ONBOARDING_PORTFOLIO_STATUSES.activated,
  },
];

export const MOCK_STATISTICS_DATE = {
  first: {
    title: 'Oldest data received',
    date: '2020-03-31T11:02:25.810968Z',
    subtitle: 'Sparkasse Neuwied',
  },
  second: {
    title: 'Newest data received',
    date: '2020-03-23T11:02:25.810968Z',
    subtitle: 'Landesbank Baden-Württemberg',
  },
};

export const MOCK_STATISTICS = {
  first: {
    number: 89,
    title: 'Transactions received',
    abbr: '(YTD)',
  },
  second: {
    number: 86,
    title: 'Success reconcilliations',
    abbr: '(YTD)',
  },
};
