import React, { useState, useEffect } from 'react';
import { PageInputFormFieldSD } from './styled';

export interface PageInputFormProps {
  pageNumber: number;
  numPages: number;
  onChange: (page: number) => void;
}

function PageInputForm(props: PageInputFormProps) {
  const { numPages, pageNumber } = props;
  const [page, setPage] = useState<number | string>(pageNumber);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(event.target.value);
  };

  const handleSubmit = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    if (page >= 1 && page <= numPages) {
      props.onChange(+page);
    }
  };

  useEffect(() => {
    setPage(pageNumber);
  }, [pageNumber]);

  return (
    <form data-test="component-page-input-form" onSubmit={handleSubmit}>
      <PageInputFormFieldSD
        variant="filled"
        type="number"
        pattern="\d*"
        min="1"
        max={numPages}
        inputmode="numeric"
        value={page}
        onChange={handleChange}
      />
    </form>
  );
}

export default PageInputForm;
