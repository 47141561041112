import React from 'react';
import * as Yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import { TextField } from '@material-ui/core';
import {
  ForgotPasswordFormSD,
  ForgotPasswordFormFieldSD,
  ForgotPasswordFormButtonSD,
} from './styled';

export interface ForgotPasswordFormValues {
  email: string;
}

interface FormProps {
  handleSubmit: Function;
  initialEmail?: string;
}

function ForgotPasswordForm(props: FormikProps<ForgotPasswordFormValues>) {
  const {
    values,
    errors,
    handleChange,
    touched,
    handleBlur,
    handleSubmit,
  } = props;
  return (
    <ForgotPasswordFormSD onSubmit={handleSubmit}>
      <ForgotPasswordFormFieldSD>
        <TextField
          name="email"
          label="E-Mail-Address"
          variant="filled"
          fullWidth
          value={values.email}
          onChange={handleChange('email')}
          error={Boolean(errors.email && touched.email)}
          helperText={errors.email && touched.email ? errors.email : ''}
          onBlur={handleBlur('email')}
        />
      </ForgotPasswordFormFieldSD>
      <ForgotPasswordFormButtonSD
        type="submit"
        color="primary"
        variant="contained"
      >
        Forgot My Password
      </ForgotPasswordFormButtonSD>
    </ForgotPasswordFormSD>
  );
}

export default withFormik<FormProps, ForgotPasswordFormValues>({
  mapPropsToValues: ({ initialEmail = '' }) => ({ email: initialEmail }),
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is not valid'),
  }),
  handleSubmit: (
    values: ForgotPasswordFormValues,
    { props, setSubmitting }
  ) => {
    setSubmitting(true);
    props.handleSubmit(values.email, setSubmitting);
  },
})(ForgotPasswordForm);
