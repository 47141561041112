import { FILTER_ACTIONS, FilterActionTypes, FilterState } from './types';

export const initialFilterState: FilterState = {
  search: null,
  isCustodiansFilterOpen: false,
  selectedCustodians: [],
  dateRange: [],
};

const reducer = (
  state = initialFilterState,
  action: FilterActionTypes
): FilterState => {
  switch (action.type) {
    case FILTER_ACTIONS.openCustodiansFilter:
      return {
        ...state,
        isCustodiansFilterOpen: true,
      };
    case FILTER_ACTIONS.closeCustodiansFilter:
      return {
        ...state,
        isCustodiansFilterOpen: false,
      };
    case FILTER_ACTIONS.clearSelectedCustodians:
      return {
        ...state,
        selectedCustodians: [],
      };
    case FILTER_ACTIONS.setSearch:
      return {
        ...state,
        // @ts-ignore
        search: action.payload.search,
      };
    case FILTER_ACTIONS.setSelectedCustodians:
      return {
        ...state,
        // @ts-ignore
        selectedCustodians: action.payload.selectedCustodians,
      };
    case FILTER_ACTIONS.setDateRange:
      return {
        ...state,
        // @ts-ignore
        dateRange: action.payload.dateRange,
      };
    case FILTER_ACTIONS.reset:
      return initialFilterState;
    default:
      return state;
  }
};

export default reducer;
