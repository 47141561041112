import {
  ReconciliationDetailsState,
  ReconciliationDetailsActionTypes,
  RECONCILIATION_DETAILS_ACTIONS,
} from './types';

export const initialReconciliationDetailsState: ReconciliationDetailsState = {
  baseInfo: {
    id: null,
    portfolio: null,
    reconciliationDate: undefined,
    reconciliationStatus: null,
    identifiers: [],
    isin: null,
  },
  isFetching: false,
  isPdfFetching: false,
  pdfDocument: null,
  startStatement: {
    positionId: null,
    positionDate: undefined,
    amount: null,
    statementsFile: null,
  },
  transactions: [],
  endStatement: {
    positionId: null,
    positionDate: undefined,
    amount: null,
    goioComputation: null,
    reconciliationCorrect: null,
    statementsFile: null,
  },
};

const reducer = (
  state = initialReconciliationDetailsState,
  action: ReconciliationDetailsActionTypes
): ReconciliationDetailsState => {
  switch (action.type) {
    case RECONCILIATION_DETAILS_ACTIONS.request:
      return {
        ...state,
        isFetching: true,
      };
    case RECONCILIATION_DETAILS_ACTIONS.success:
      return {
        ...state,
        isFetching: false,
        baseInfo: action.payload.baseInfo,
        startStatement: action.payload.startStatement,
        endStatement: action.payload.endStatement,
        transactions: action.payload.transactions,
      };
    case RECONCILIATION_DETAILS_ACTIONS.error:
      return {
        ...state,
        isFetching: false,
      };
    case RECONCILIATION_DETAILS_ACTIONS.reset:
      return initialReconciliationDetailsState;
    case RECONCILIATION_DETAILS_ACTIONS.requestPdf:
      return {
        ...state,
        isPdfFetching: true,
      };
    case RECONCILIATION_DETAILS_ACTIONS.requestPdfSuccess:
      return {
        ...state,
        pdfDocument: action.payload.pdfDocument,
      };
    case RECONCILIATION_DETAILS_ACTIONS.requestPdfError:
      return {
        ...state,
        isPdfFetching: false,
      };
    default:
      return state;
  }
};

export default reducer;
