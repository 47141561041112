import React from 'react';
import { MenuListItemSD, MenuListSD } from './styled';

interface MenuItem {
  label: string;
  id: string;
}

export interface IMenuListProps {
  list: MenuItem[];
  onClickItem: (id: string) => void;
}

function MenuList(props: IMenuListProps) {
  const { list, onClickItem } = props;

  return (
    <MenuListSD data-test="component-menu-list">
      {list.map(item => (
        <MenuListItemSD
          key={item.id}
          data-test="menu-item"
          onClick={() => onClickItem(item.id)}
        >
          {item.label}
        </MenuListItemSD>
      ))}
    </MenuListSD>
  );
}

export default MenuList;
