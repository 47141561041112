import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const SearchFieldSD = styled(TextField)`
  .MuiInput-root {
    border: 1px solid ${({ theme }) => theme.palette.primary[70]};
    background-color: #fff;

    &:after,
    &:before {
      content: none;
    }
  }

  .MuiInput-input {
    padding: 13px 10px 10px;
    font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
      sans-serif;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textLightGrey};
    }
  }

  .MuiInputAdornment-root {
    margin: 0 5px 0;
    color: ${({ theme }) => theme.palette.primary[40]};
  }
`;
