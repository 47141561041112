export const MOCK_EMAIL_TEMPLATES = [
  {
    id: 23,
    label: 'Reconciliation break',
    template: `Dear Custodian Landesbank Baden-Württemberg:
We are having problems for this portfolio’s document- Please do In egestas felis efficitur tempus ullamcorper. Integer non neque nibh. Nunc sit amet enim fringilla, eleifend quam`,
  },
  {
    id: 24,
    label: 'Reconciliation break2',
    template: `Dear Custodian Landesbank Baden-Württemberg2:
We are having problems for this portfolio’s document- Please do In egestas felis efficitur tempus ullamcorper. Integer non neque nibh. Nunc sit amet enim fringilla, eleifend quam`,
  },
  {
    id: 25,
    label: 'Reconciliation break3',
    template: `Dear Custodian Landesbank Baden-Württemberg3:
We are having problems for this portfolio’s document- Please do In egestas felis efficitur tempus ullamcorper. Integer non neque nibh. Nunc sit amet enim fringilla, eleifend quam`,
  },
];
