import { AppDispatch } from '../../configureStore';
import { CLIENTS_ACTIONS, IPortfolio, IClient } from './types';
import Api from '../../../api/api';
import { propValueOr } from '../../../helpers/common';
import _ from 'lodash';

export const getClient = (clientId: string): any => (dispatch: AppDispatch) => {
  dispatch({
    type: CLIENTS_ACTIONS.client.request,
  });

  return Api.clients
    .getOne(clientId)
    .then(response => {
      const data = response.data;

      let currentClient = null;

      if (data) {
        currentClient = transformClient(data);
      }

      dispatch({
        type: CLIENTS_ACTIONS.client.success,
        payload: currentClient,
      });

      return response;
    })
    .catch(err => {
      dispatch({
        type: CLIENTS_ACTIONS.client.error,
      });

      return err;
    });
};

export const getPortfolio = (portfolioId: string): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: CLIENTS_ACTIONS.portfolio.request,
  });

  return Api.portfolios
    .getOne(portfolioId)
    .then(response => {
      dispatch({
        type: CLIENTS_ACTIONS.portfolio.success,
        payload: {
          portfolio: transformPortfolio(response.data),
        },
      });

      return response;
    })
    .catch(err => {
      dispatch({
        type: CLIENTS_ACTIONS.portfolio.error,
      });

      return err;
    });
};

export const getEmptyPortfolio = (): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: CLIENTS_ACTIONS.portfolio.success,
    payload: {
      portfolio: {},
    },
  });
};

export const getClients = (params?: { [key: string]: any }, isSearch : boolean = false): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: CLIENTS_ACTIONS.clients.request,
  });

  return Api.clients
    .getAll(params)
    .then(response => {
      const clients = propValueOr(response, 'data.results', []);

      dispatch({
        type: CLIENTS_ACTIONS.clients.success,
        payload: {
          clients: clients.map((c: any) => transformClient(c)),
          total: _.get(response, 'data.count', 0),
          isSearch: isSearch,
        },
      });

      return response;
    })
    .catch(err => {
      dispatch({
        type: CLIENTS_ACTIONS.clients.error,
      });

      return err;
    });
};

export const transformPortfolio = (p: any): IPortfolio => {
  return {
    id: p.id,
    custodian: {name: p.custodian.name, address: p.custodian.address},
    portfolioNumber: p.portfolio_number,
    accountHolder: p.account_holder,
    accountHolders: p.account_holders,
    mandate: p.mandate,
    securityAccounts: p.security_accounts.map((a: any = {}) => ({
      id: a.id,
      securityNumber: a.security_number,
    })),
    status: p.status,
    noticeSubmitted: p.notice_submitted,
    dateOfCancellation: p.date_of_cancellation,
    statusChangedOn: p.status_changed_on,
    cashAccounts: p.cash_accounts.map((v: any = {}) => ({
      id: v.id,
      iban: v.iban,
      accountNo: v.account_number,
      currency: {
        id: v.currency?.id,
        code: v.currency?.code,
      },
      accountType: v.account_type,
      accountTypeName: v.account_type_name,
    })),
    bankContacts: p.bank_contacts.map((b: any = {}) => ({
      id: b.id,
      contactType: b.contact_type,
      fullName: b.fullname,
      email: b.email,
      phoneNumber: b.phone_number,
    })),
    policiesNum: p.policies_num,
  };
};

function transformClient(c: any): IClient {
  return {
    id: c.id,
    avatar: c.avatar,
    goioID: c.goio_id,
    wealthManager: c.wealth_manager,
    wealthManagerName: c.wealth_manager_name,
    customerNumber: c.customer_number,
    firstName: c.first_name,
    lastName: c.last_name,
    nickname: c.nickname,
    created: c.created,
    portfolios: c.portfolios.map(transformPortfolio),
  };
}
