import { FILTER_ACTIONS, FilterActionTypes } from './types';

export function setSearch(search: string): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.setSearch,
    payload: { search },
  };
}

export function setDateRange(dateRange: string[]): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.setDateRange,
    payload: { dateRange },
  };
}

export function setSelectedCustodians(
  selectedCustodians: string[]
): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.setSelectedCustodians,
    payload: { selectedCustodians },
  };
}

export function openCustodiansFilter(): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.openCustodiansFilter,
  };
}

export function closeCustodiansFilter(): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.closeCustodiansFilter,
  };
}

export function clearSelectedCustodians(): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.clearSelectedCustodians,
  };
}

export function resetFilter(): FilterActionTypes {
  return {
    type: FILTER_ACTIONS.reset,
  };
}
