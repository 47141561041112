import { Action } from '../../../../app/types';
import { CLIENTS_ACTIONS } from '../../../../store/commonStates/clients/types';

export const RECONCILIATION_DETAILS_ACTIONS = {
  request: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_REQUEST',
  success: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_SUCCESS',
  error: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_ERROR',
  reset: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_RESET',
  requestPdf: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_REQUEST_PDF',
  requestPdfSuccess: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_SUCCESS_PDF',
  requestPdfError: 'QUALITY_ENGINEER / RECONCILIATION_DETAILS_ERROR_PDF',
};

interface IIdentifier {
  type: string;
  value: string;
}

export interface ReconciliationDetailsState {
  isFetching: boolean;
  isPdfFetching: boolean;
  pdfDocument: any | null;
  baseInfo: {
    id: number | null;
    portfolio: {
      id: number;
      custodian: string;
      portfolioNumber: string;
    } | null;
    reconciliationDate: Date | undefined;
    reconciliationStatus: {
      id: number;
      label: string;
    } | null;
    identifiers: IIdentifier[];
    isin: string | null;
  };
  startStatement: {
    positionId: number | null;
    positionDate: string | undefined;
    amount: number | null;
    statementsFile: string | null,
  };
  transactions: ITransaction[];
  endStatement: {
    positionId: number | null;
    positionDate: string | undefined;
    amount: number | null;
    goioComputation: number | null;
    reconciliationCorrect: boolean | null;
    statementsFile: string | null,
  };
}

export type IOperation = 'SELL' | 'BUY';

export interface ITransaction {
  transactionId: number;
  transactionDate: string;
  amount: number;
  goioComputation: number;
  operation: IOperation;
  reconciliationCorrect: boolean;
  statementsFile: string | null;
}

type ReconciliationDetailsRequestAction = Action<
  typeof RECONCILIATION_DETAILS_ACTIONS.request,
  any
>;

type ReconciliationDetailsSuccessAction = Action<
  typeof RECONCILIATION_DETAILS_ACTIONS.success,
  {
    startStatement: {
      positionId: number;
      positionDate: string;
      amount: number;
    };
    transactions: ITransaction[];
    endStatement: {
      positionId: number;
      positionDate: string;
      amount: number;
      goioComputation: number;
      reconciliationCorrect: boolean;
    };
  }
>;

type ReconciliationDetailsErrorAction = Action<
  typeof RECONCILIATION_DETAILS_ACTIONS.error,
  any
>;

type ReconciliationDetailsResetAction = Action<
  typeof RECONCILIATION_DETAILS_ACTIONS.reset,
  any
>;


type ReconciliationDetailsPdfSuccessAction = Action<
  typeof RECONCILIATION_DETAILS_ACTIONS.requestPdfSuccess,
  {
    pdfDocument: any,
  }
>;

type ReconciliationDetailsPdfErrorAction = Action<
  typeof RECONCILIATION_DETAILS_ACTIONS.requestPdfError,
  any
>;


export type ReconciliationDetailsActionTypes =
  | ReconciliationDetailsRequestAction
  | ReconciliationDetailsSuccessAction
  | ReconciliationDetailsErrorAction
  | ReconciliationDetailsResetAction
  | ReconciliationDetailsPdfSuccessAction
  | ReconciliationDetailsPdfErrorAction;
