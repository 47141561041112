import React from 'react';
import PasswordField from '../../kit/Fields/PasswordField/PasswordField';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';

import {
  SetUpPasswordFormButtonSD,
  SetUpPasswordFormFieldSD,
  SetUpPasswordFormSD,
} from './styled';
import PasswordRequirements from './PasswordRequirements/PasswordRequirements';

export interface FormValues {
  password: string;
  confirmPassword: string;
}

interface FormProps {
  password?: string;
  confirmPassword?: string;
  onSubmit: Function;
}

function SetUpPasswordForm(props: FormikProps<FormValues>) {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
  } = props;

  return (
    <SetUpPasswordFormSD onSubmit={handleSubmit}>
      <SetUpPasswordFormFieldSD>
        <PasswordField
          error={Boolean(touched.password && errors.password)}
          onChange={handleChange('password')}
          onBlur={handleBlur('password')}
          value={values.password}
        />
      </SetUpPasswordFormFieldSD>
      <SetUpPasswordFormFieldSD>
        <PasswordField
          label={'Confirm password'}
          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          onChange={handleChange('confirmPassword')}
          onBlur={handleBlur('confirmPassword')}
          value={values.confirmPassword}
          name="confirmPassword"
        />
      </SetUpPasswordFormFieldSD>
      <SetUpPasswordFormButtonSD
        type="submit"
        color="primary"
        variant="contained"
      >
        Save My Password
      </SetUpPasswordFormButtonSD>

      <PasswordRequirements password={values.password} />
    </SetUpPasswordFormSD>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ password = '', confirmPassword = '' }) => ({
    password,
    confirmPassword,
  }),
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'min')
      .matches(/.*[A-Z].*/, 'bigMin')
      .matches(/.*[a-z].*/, 'bigCap')
      .matches(/.*\d.*/, 'number'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null])
      .required('Password confirm is required'),
  }),
  handleSubmit: (values: FormValues, { props, setErrors, setSubmitting }) => {
    setSubmitting(true);
    props.onSubmit(values.password, setErrors, setSubmitting);
  },
})(SetUpPasswordForm);
