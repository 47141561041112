import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const SetUpPasswordFormSD = styled.form`
  display: flex;
  flex-direction: column;
`;

export const SetUpPasswordFormFieldSD = styled.div`
  margin-bottom: 26px;
`;

export const SetUpPasswordFormButtonSD = styled(Button)`
  align-self: center;
  margin-bottom: 50px;
`;
