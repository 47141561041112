import RouteAccountActivation from '../../routes/account-activation/RouteAccountActivation';
import RouteLogin from '../../routes/login/RouteLogin';
import RouteRegistration from '../../routes/registration/RouteRegistration';
import RouteForgotPassword from '../../routes/forgot-password/RouteForgotPassword';
import RouteResetPassword from '../../routes/reset-password/RouteResetPassword';
import OnboardingStatus from '../../routes/onboarding-status/OnboardingStatus';
import MyClients from '../../routes/my-clients/MyClients';
import RouteAddPortfolio from '../../routes/add-portfolio/RouteAddPortfolio';
import RouteQEReconciliationBreaks from '../../routes/quality-engineer/reconsiliation-breaks/RouteQEReconciliationBreaks';
import RouteQEDataQuality from '../../routes/quality-engineer/data-quality/RouteQEDataQuality';
import RouteQEOnboardingPackage from '../../routes/quality-engineer/onboarding-package/RouteQEOnboardingPackage';
import RouteEditPortfolio from '../../routes/advisor/edit-portfolio/RouteEditPortfolio';
import RouteQEReconciliationDetails from '../../routes/quality-engineer/reconciliation-details/RouteQEReconciliationDetails';
import RouteQEOnboardingDetails from '../../routes/quality-engineer/onboarding-details/RouteQEOnboardingDetails';
import RouteQEFeedback from '../../routes/quality-engineer/feedback/RouteQEFeedback';
import RouteAddPortfolioPolicies from "../../routes/add-portfolio/RouteAddPortfolioPolicy";

export const layoutAuthRoutes = [
  {
    path: '/activate/:id',
    exact: false,
    component: RouteAccountActivation,
  },
  {
    path: '/login',
    component: RouteLogin,
  },
  {
    path: '/registration',
    component: RouteRegistration,
  },
  {
    path: '/forgot-password',
    component: RouteForgotPassword,
  },
  {
    path: '/reset-password',
    component: RouteResetPassword,
  },
];

export const layoutAuthorizedRoutes = [
  {
    path: '/',
    exact: true,
    component: OnboardingStatus,
  },
  {
    path: '/my-clients',
    component: MyClients,
  },
  {
    path: '/add-portfolio/:clientId',
    component: RouteAddPortfolio,
  },
  {
    path: '/edit-portfolio/:clientId/:portfolioId',
    component: RouteEditPortfolio,
  },
  {
    path: '/edit-policies/:clientId/:portfolioId',
    component: RouteAddPortfolioPolicies,
  },
];

export const layoutQualityEngineerRoutes = [
  {
    path: '/',
    exact: true,
    component: RouteQEReconciliationBreaks,
  },
  {
    path: '/data-quality',
    exact: true,
    component: RouteQEDataQuality,
  },
  {
    path: '/onboarding-package',
    exact: true,
    component: RouteQEOnboardingPackage,
  },
  {
    path: '/onboarding-package/:onboardingId',
    exact: true,
    component: RouteQEOnboardingDetails,
  },
  {
    path: '/reconciliations/:reconciliationId',
    exact: false,
    component: RouteQEReconciliationDetails,
  },
  {
    path: '/feedback',
    exact: true,
    component: RouteQEFeedback,
  },
];
