import styled, { css } from 'styled-components';
import { TRANSACTION_TYPES } from '../../constants/common';

interface TransactionCardAmountProps {
  type?: string;
}

export const TransactionCardAmountSD = styled.div<TransactionCardAmountProps>`
  ${styledAmountByType}
`;

function styledAmountByType(props: any) {
  switch (props.type) {
    case TRANSACTION_TYPES.buy:
      return css`
        &:before {
          content: '+';
          margin-right: 5px;
          color: #84cc59;
          font-weight: 700;
          font-size: 18px;
        }
      `;
    case TRANSACTION_TYPES.sell:
      return css`
        &:before {
          content: '−';
          margin-right: 5px;
          color: #f26d7d;
          font-weight: 700;
          font-size: 18px;
        }
      `;
  }
}

export const TransactionCardCalculationSD = styled.div``;
