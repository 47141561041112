import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconAddCircle(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M343,333a10,10,0,1,0,10,10A10,10,0,0,0,343,333Zm4.286,11.429h-2.857v2.857a1.429,1.429,0,1,1-2.857,0v-2.857h-2.857a1.429,1.429,0,1,1,0-2.857h2.857v-2.857a1.429,1.429,0,0,1,2.857,0v2.857h2.857a1.429,1.429,0,0,1,0,2.857Z"
        transform="translate(-333 -333)"
      />
    </SvgIcon>
  );
}

export default IconAddCircle;
