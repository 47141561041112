import React, { useEffect } from 'react';
import {
  RouteQEReconciliationDetailsLinkBackSD,
  RouteQEReconciliationDetailsTitleSD,
  RouteQEReconciliationDetailsSD,
  RouteQEReconciliationDetailsInfoSD,
} from './styled';
import IconChevronLeft from '../../../components/icons/IconChevronLeft';
import { Link, useParams } from 'react-router-dom';
import ReconciliationInfo from './ReconciliationInfo/ReconciliationInfo';
import { Grid } from '@material-ui/core';
import ReconciliationTable from './ReconciliationTable/ReconciliationTable';
import { TransactionList } from './mocks/common';
import DialogEmail from '../../../components/smart/DialogEmail/DialogEmail';
import { MOCK_EMAIL_TEMPLATES } from '../../../components/smart/DialogEmail/mocks/common';
import PdfViewer from '../../../components/smart/PdfViewer/PdfViewer';
import _ from 'lodash';
import { ITransaction } from './store/types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import {
  getPdfDocument,
  getReconciliationDetails,
  resetReconciliationDetails,
} from './store/actions';
import { AppDispatch } from '../../../store/configureStore';

interface RouteQEReconciliationDetails {
  startStatement: {
    positionId: number | null;
    positionDate: string | undefined;
    amount: number | null;
    statementsFile: string | null;
  };
  endStatement: {
    positionId: number | null;
    positionDate: string | undefined;
    amount: number | null;
    goioComputation: number | null;
    reconciliationCorrect: boolean | null;
    statementsFile: string | null;
  };
  transactions: ITransaction[];
  pdfDocument: any;
}

function RouteQEReconciliationDetails() {
  const dispatch: AppDispatch = useDispatch();
  const { reconciliationId } = useParams();
  const { startStatement, endStatement, transactions, pdfDocument } = useSelector<
    RootState,
    RouteQEReconciliationDetails
  >(store => ({
    startStatement: store.qualityEngineer.reconciliationDetails.startStatement,
    endStatement: store.qualityEngineer.reconciliationDetails.endStatement,
    transactions: store.qualityEngineer.reconciliationDetails.transactions,
    pdfDocument: store.qualityEngineer.reconciliationDetails.pdfDocument
  }));

  const [activeTransaction, setActiveTransaction] = React.useState<
    number | null
  >(null);

  const [activeDocument, setActiveDocument] = React.useState<string | null>(null);

  useEffect(() => {
    if (activeDocument) {
      dispatch(getPdfDocument(Number(reconciliationId), activeDocument));
    }
  }, [activeDocument]);

  useEffect(() => {
    dispatch(getReconciliationDetails(Number(reconciliationId)));
    return () => {
      dispatch(resetReconciliationDetails());
    };
  }, [reconciliationId]);

  return (
    <RouteQEReconciliationDetailsSD data-test="component-route-qe-reconciliation-details">
      <RouteQEReconciliationDetailsLinkBackSD
        data-test="link-reconsiliation-breaks"
        component={Link}
        to="/"
        color="primary"
        variant="text"
        startIcon={<IconChevronLeft />}
      >
        Go back to Reconciliation Breaks
      </RouteQEReconciliationDetailsLinkBackSD>
      <RouteQEReconciliationDetailsTitleSD data-test="title" variant="h1">
        Reconciliation Details
      </RouteQEReconciliationDetailsTitleSD>
      <RouteQEReconciliationDetailsInfoSD>
        <ReconciliationInfo />
      </RouteQEReconciliationDetailsInfoSD>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ReconciliationTable
            startStatement={startStatement}
            endStatement={endStatement}
            transactions={transactions}
            onClickRow={(id: number) => setActiveTransaction(id)}
            onClickDocument={(file_id => setActiveDocument(file_id))}
            activeTransaction={activeTransaction}
          />
        </Grid>
        <Grid item xs={6}>
          {pdfDocument ?
              <PdfViewer
                  file={pdfDocument}
              /> : null
          }
        </Grid>
      </Grid>
      <DialogEmail
        onSubmit={(value: any) => console.log(value)}
        title="Notify Custodian"
        templates={MOCK_EMAIL_TEMPLATES}
      />
    </RouteQEReconciliationDetailsSD>
  );
}

export default RouteQEReconciliationDetails;
