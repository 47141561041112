import React from 'react';
import FormReadOnly from './FormReadOnly/FormReadOnly';
import { MOCK_CLIENT, MOCK_DATE, MOCK_PORTFOLIO } from '../../../mocks/common';
import {
  RouteQEOnboardingDetailsLinkSD,
  RouteQEOnboardingDetailsPropertyKeySD,
  RouteQEOnboardingDetailsPropertySD,
  RouteQEOnboardingDetailsPropertyValueSD,
  RouteQEOnboardingDetailsTitleSD,
  RouteQEOnboardingDetailsSD,
  RouteQEOnboardingDetailsContainerSD,
  RouteQeOnboardingDetailsHeaderSD,
} from './styled';
import { Link } from 'react-router-dom';
import IconChevronLeft from '../../../components/icons/IconChevronLeft';
import { Grid } from '@material-ui/core';
import moment from 'moment';

function RouteQEOnboardingDetails() {
  return (
    <RouteQEOnboardingDetailsSD data-test="component-route-qe-onboarding-details">
      <RouteQEOnboardingDetailsContainerSD>
        <RouteQEOnboardingDetailsLinkSD
          component={Link}
          to="/onboarding-package"
          color="primary"
          size="small"
          variant="text"
          startIcon={<IconChevronLeft />}
        >
          Go back to Onboarding Package page
        </RouteQEOnboardingDetailsLinkSD>
        <RouteQEOnboardingDetailsTitleSD variant="h1" color="primary">
          Not Verified Onboarding Package
        </RouteQEOnboardingDetailsTitleSD>
        <RouteQeOnboardingDetailsHeaderSD container spacing={2}>
          <Grid item xs={4}>
            <RouteQEOnboardingDetailsPropertySD>
              <RouteQEOnboardingDetailsPropertyKeySD>
                Onboarding Request Date:
              </RouteQEOnboardingDetailsPropertyKeySD>
              <RouteQEOnboardingDetailsPropertyValueSD
                as="time"
                data-test="request-date"
              >
                {moment(MOCK_DATE).format('DD.MM.YYYY')}
              </RouteQEOnboardingDetailsPropertyValueSD>
            </RouteQEOnboardingDetailsPropertySD>
          </Grid>
          <Grid item xs={4}>
            <RouteQEOnboardingDetailsPropertySD>
              <RouteQEOnboardingDetailsPropertyKeySD>
                Advisor Name:
              </RouteQEOnboardingDetailsPropertyKeySD>
              <RouteQEOnboardingDetailsPropertyValueSD data-test="advisor-name">
                Shalika Hanum
              </RouteQEOnboardingDetailsPropertyValueSD>
            </RouteQEOnboardingDetailsPropertySD>
            <RouteQEOnboardingDetailsPropertySD>
              <RouteQEOnboardingDetailsPropertyKeySD>
                Advisor ID:
              </RouteQEOnboardingDetailsPropertyKeySD>
              <RouteQEOnboardingDetailsPropertyValueSD data-test="advisor-id">
                SH234523
              </RouteQEOnboardingDetailsPropertyValueSD>
            </RouteQEOnboardingDetailsPropertySD>
          </Grid>
        </RouteQeOnboardingDetailsHeaderSD>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormReadOnly portfolio={MOCK_PORTFOLIO} client={MOCK_CLIENT} />
          </Grid>
        </Grid>
      </RouteQEOnboardingDetailsContainerSD>
    </RouteQEOnboardingDetailsSD>
  );
}

export default RouteQEOnboardingDetails;
