import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconChevronLeft(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 11 18">
      <path
        className="a"
        d="M11.115,11.76,18,18.559l6.885-6.8L27,13.853,18,22.76,9,13.853Z"
        transform="translate(22.76 -9) rotate(90)"
      />
    </SvgIcon>
  );
}

export default IconChevronLeft;
