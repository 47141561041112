import { useEffect, useState } from 'react';
import Api from '../../../../api/api';
import { propValueOr } from '../../../../helpers/common';

export interface ICurrency {
  id: number;
  code: string;
  mostUsed?: boolean;
}

function useCurrency() {
  const [currencies, setCurrencies] = useState<Array<ICurrency>>([]);

  useEffect(() => {
    Api.getCurrencies()
      .then(response => {
        let data = propValueOr(response, 'data', []);

        data = data.map((v: any) => {
          v.mostUsed = Boolean(v.order);
          return v;
        });
        setCurrencies(data);
      })
      .catch(() => {});
  }, []);

  return currencies;
}

export { useCurrency };
