import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableHeadCell, TableRowSD, TableSD } from './styled';
import moment from 'moment';
import { Link } from 'react-router-dom';
import IconCheckSnackbar from '../../../components/icons/IconCheckSnackbar';
import { TableSortLabel } from '@material-ui/core';
import IconSort from '../../../components/icons/IconSort';
import { useQuery } from '../../../hooks/useQuery';
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableCell: {
    padding: '10px 25px',
    verticalAlign: 'middle',
  },
  tableCellNowrap: {
    padding: '10px 25px',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  tableCellLink: {
    textDecoration: 'none',
    color: '#84CC59',
    fontWeight: 700,
  },
  icons: {
    fontSize: 16,
    verticalAlign: 'middle',
  },
});

interface HeadCellProps {
  value: string;
  sortable: boolean;
  name: string;
  nowrap: boolean;
}

function HeadCell({ value, sortable, nowrap, name }: HeadCellProps) {
  const [{ order = 'asc', order_by }, setQuery] = useQuery();
  const active = name === order_by;

  const handleRequestSort = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    const isAsc = order_by === property && order === 'asc';
    setQuery({
      order: isAsc ? 'desc' : 'asc',
      order_by: property,
    });
  };

  return (
    <TableHeadCell
      sortable={sortable}
      sortableActive={active}
      nowrap={nowrap}
      data-tst="component-head-cell"
    >
      {sortable ? (
        <TableSortLabel
          active={active}
          direction={order}
          IconComponent={IconSort}
          onClick={handleRequestSort(name)}
        >
          {value}
        </TableSortLabel>
      ) : (
        value
      )}
    </TableHeadCell>
  );
}

interface BodyCellProps {
  children: ReactNode;
  nowrap: boolean;
}

function BodyCell(props: BodyCellProps) {
  const classes = useStyles();

  return (
    <TableCell
      className={props.nowrap ? classes.tableCellNowrap : classes.tableCell}
    >
      {props.children}
    </TableCell>
  );
}

function OnboardingPackageTable({ portfolios }: { portfolios: Array<object> }) {
  const classes = useStyles();

  return (
    <TableSD
      className={classes.table}
      aria-label="spanning table"
      stickyHeader
      data-test="component-onboarding-package-table"
    >
      <TableHead>
        <TableRow>
          <HeadCell
            value={'Client Name'}
            sortable={true}
            name={'full_name'}
            nowrap={false}
          />
          <HeadCell
            value={'Custodian'}
            sortable={false}
            name={''}
            nowrap={false}
          />
          <HeadCell
            value={'Portfolio number'}
            sortable={false}
            name={''}
            nowrap={false}
          />
          <HeadCell
            value={'Request Date'}
            sortable={true}
            name={'created'}
            nowrap={true}
          />
          <HeadCell value={'Status'} sortable={false} name={''} nowrap={true} />
          <HeadCell value={''} sortable={false} name={''} nowrap={true} />
        </TableRow>
        <tr style={{ height: '10px' }} />
      </TableHead>
      <TableBody>
        {portfolios.map((portfolio: any) => {
          let icon,
            iconText = '';

          if (portfolio.status === 'ACT') {
            icon = (
              <IconCheckSnackbar
                htmlColor={'#B3C9DA'}
                className={classes.icons}
              />
            );
            iconText = 'Verified';
          } else {
            icon = (
              <NewReleasesIcon
                htmlColor={'#84cc59'}
                className={classes.icons}
              />
            );
            iconText = 'New';
          }

          return (
            <TableRowSD>
              <BodyCell nowrap={false}>
                <div data-test="full-name">
                  {portfolio.account_holder.first_name}{' '}
                  {portfolio.account_holder.last_name}
                </div>
              </BodyCell>
              <BodyCell nowrap={false}>
                <div data-test="custodian">{portfolio.custodian.name}</div>
              </BodyCell>
              <BodyCell nowrap={false}>
                <div data-test="account-num">
                  {portfolio.portfolio_number}
                </div>
              </BodyCell>
              <BodyCell nowrap={false}>
                <div data-test="request-date">
                  {moment(portfolio.updated).format('DD.MM.YYYY')}
                </div>
              </BodyCell>
              <BodyCell nowrap={true}>
                <div data-test="status">
                  {icon} {iconText}
                </div>
              </BodyCell>
              <BodyCell nowrap={true}>
                <Link
                  to={`/onboarding-package/${portfolio.id}`}
                  className={classes.tableCellLink}
                  data-test="link-onboarding-package"
                >
                  Check Onboarding Package
                </Link>
              </BodyCell>
            </TableRowSD>
          );
        })}
      </TableBody>
    </TableSD>
  );
}

export default OnboardingPackageTable;
