import styled from 'styled-components';
import { LinkProps } from 'react-router-dom';
import { Button } from '@material-ui/core';

export const RouteQEFeedbackSD = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const RouteQEFeedbackLinkSD = styled(Button)<LinkProps>`
  margin-bottom: 30px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
