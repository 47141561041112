import { AxiosInstance } from 'axios';
import {ICashAccount, ISecurityAccount} from '../../store/commonStates/clients/types';

interface FilteringParams {
  type?: string;
  order?: string;
  order_by?: string;
  search?: string;
}

class PortfoliosApi {
  client: AxiosInstance;
  basePath: string | undefined;
  constructor(client: AxiosInstance) {
    this.client = client;
    this.basePath = `portfolios/`;
  }

  getOne(portfolioId: string) {
    return this.client.get(`${this.basePath}${portfolioId}/`);
  }

  create(portfolio: any) {
    const body = {
      custodian: {name: portfolio.custodian.name, address: portfolio.custodian.address},
      portfolio_number: portfolio.portfolioNumber,
      account_holder: portfolio.accountHolder,
      account_holders: portfolio.accountHolders,
      security_accounts: portfolio.securityAccounts.map(
        (a: ISecurityAccount) => ({
          security_number: a.securityNumber,
        })
      ),
      cash_accounts: portfolio.cashAccounts.map(
          (v: ICashAccount) => ({
            ...v,
            account_number: v.accountNo,
            account_type: v.accountType,
          })
      ),
      bank_contacts: [
        {
          contact_type: 'AD',
          fullname: portfolio.bankAdvisorContact.fullName,
          email: portfolio.bankAdvisorContact.email,
          phone_number: portfolio.bankAdvisorContact.phoneNumber,
        },
        {
          contact_type: 'IT',
          fullname: portfolio.bankITContact.fullName,
          email: portfolio.bankITContact.email,
          phone_number: portfolio.bankITContact.phoneNumber,
        },
      ],
    };
    return this.client.post(`${this.basePath}`, body);
  }

  update(portfolioId: string | number, portfolio: any) {
    const body = {
      custodian: {name: portfolio.custodian.name, address: portfolio.custodian.address},
      portfolio_number: portfolio.portfolioNumber,
      account_holder: portfolio.accountHolder,
      account_holders: portfolio.accountHolders,
      security_accounts: portfolio.securityAccounts.map(
        (a: ISecurityAccount) => ({
          ...a,
          security_number: a.securityNumber,
        })
      ),
      cash_accounts: portfolio.cashAccounts.map(
          (v: ICashAccount) => ({
            ...v,
            account_number: v.accountNo,
            account_type: v.accountType,
          })
      ),
      bank_contacts: [
        {
          ...portfolio.bankAdvisorContact,
          contact_type: 'AD',
          fullname: portfolio.bankAdvisorContact.fullName,
          email: portfolio.bankAdvisorContact.email,
          phone_number: portfolio.bankAdvisorContact.phoneNumber,
        },
        {
          ...portfolio.bankITContact,
          contact_type: 'IT',
          fullname: portfolio.bankITContact.fullName,
          email: portfolio.bankITContact.email,
          phone_number: portfolio.bankITContact.phoneNumber,
        },
      ],
    };
    return this.client.put(`${this.basePath}${portfolioId}/`, body);
  }

  list(page = 1, limit = 10, params: FilteringParams) {
    return this.client.get(`${this.basePath}`, {
      params: {
        page: page,
        page_size: limit,
        status: params.type,
        order: params.order,
        order_by: params.order_by,
      },
    });
  }

  statistic() {
    return this.client.get(`${this.basePath}statistic/`);
  }

  getPolicies(portfolioId: string, params: FilteringParams) {
    const searchParams = params.search ? {params: {search: params.search}} : {}
    return this.client.get(`${this.basePath}${portfolioId}/policies/`, searchParams);
  }
  createPolicies(portfolioId: string, policies: object[]) {
    return this.client.post(`${this.basePath}${portfolioId}/policies/`, policies);
  }

  cancelPolicy(portfolioId: string, type: string) {
    return this.client.post(`${this.basePath}${portfolioId}/${type}/`);
  }
}

export default PortfoliosApi;
