import React from 'react';
import {
  PageBlankContentSD,
  PageBlankImageSD,
  PageBlankSD,
  PageBlankSubtitleSD,
  PageBlankTextSD,
  PageBlankTitleSD,
  PageBlankWrapImageSD,
} from './styled';

export interface IPageBlank {
  title: string;
  subtitle: string;
  renderText: Function;
  image: string;
}

function PageBlank(props: IPageBlank) {
  const { title, subtitle, renderText, image } = props;

  return (
    <PageBlankSD>
      <PageBlankTitleSD variant="h1">{title}</PageBlankTitleSD>
      <PageBlankContentSD>
        <PageBlankSubtitleSD variant="h1" component="h2">
          {subtitle}
        </PageBlankSubtitleSD>
        <PageBlankTextSD>
          {typeof renderText === 'function' && renderText()}
        </PageBlankTextSD>
      </PageBlankContentSD>
      <PageBlankWrapImageSD>
        <PageBlankImageSD src={image} alt={'decor image'} />
      </PageBlankWrapImageSD>
    </PageBlankSD>
  );
}

export default PageBlank;
