export const RECONCILIATION_STATUSES = {
  success: 2,
  processed: 1,
  notProcessed: 0,
};

export const STATUSES_FILTER_LABELS = {
  [RECONCILIATION_STATUSES.notProcessed]:
    'Broken reconcilliations, not processed',
  [RECONCILIATION_STATUSES.processed]: 'Broken reconciliations, processed',
  [RECONCILIATION_STATUSES.success]: 'Successful reconciliations',
  total: 'All reconciliations',
};
