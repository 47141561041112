import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import { Collapse, Grid } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { groupPortfoliosByBank } from '../../../helpers/common';
import {
  ClientsTableSD,
  ClientTableButtonMoreSD,
  ClientTableCellSD,
  ClientTableIconClosed,
  ClientTableIconOpened,
  ClientTableRowSD,
} from './styled';
import {
  ClientCardBanksCountSD,
  ClientCardCustomerNumberSD,
  ClientCardDateSD,
  ClientCardFullNameSD,
  ClientCardGOIOIDSD,
  ClientCardPortfoliosCountSD,
  ClientCardEditButtonSD,
} from '../ClientCard/styled';
import IconEdit from '../../../components/icons/IconEdit';
import { Link } from 'react-router-dom';
import IconAddPortfolio from '../../../components/icons/IconAddPortfolio';
import moment from 'moment';
import { IClient } from '../../../store/commonStates/clients/types';
import CollapsedRow from './CollapsedRow/CollapsedRow';
import EnhancedTableHead from '../../../components/presentation/EnhancedTableHead/EnhancedTableHead';
import { MY_CLIENTS_HEAD_CELLS } from '../constants/common';
import {ROLES} from "../../../constants/common";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/rootReducer";
import {UserState} from "../../../store/commonStates/user/types";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

interface ClientsTableProps {
  clients: Array<IClient>;
  onEditClient: Function;
}

function ClientsTable(props: ClientsTableProps) {
  const { clients = [], onEditClient } = props;
  const classes = useStyles();
  const [expandIds, setExpandIds] = useState<{ [key: string]: any }>({});

  const showMore = (id: any) => {
    // @ts-ignore
    setExpandIds(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const {
   userInfo
  } = useSelector<RootState, UserState>((state: RootState) => ({
    userInfo: state.user.userInfo
  }));

  return (
    <ClientsTableSD className={classes.table} aria-label="spanning table">
      <EnhancedTableHead cells={MY_CLIENTS_HEAD_CELLS} />
      <TableBody>
        {clients.map(client => {
          const portfoliosByBanks = groupPortfoliosByBank(client.portfolios);

          return (
            <React.Fragment key={client.id}>
              <ClientTableRowSD
                expanded={expandIds[client.id] ? 1 : 0}
                data-test="client-row"
              >
                <ClientTableCellSD
                  expanded={expandIds[client.id] ? 1 : 0}
                  style={{ width: '25%' }}
                >
                  <ClientCardFullNameSD data-test="first-name">
                    {client.firstName || client.lastName ? client.firstName + ' ' + client.lastName: client.nickname}
                  </ClientCardFullNameSD>
                  <Grid container>
                    <Grid item>
                      <ClientCardCustomerNumberSD data-test="customer-number">
                        Client ID : {client.customerNumber}
                      </ClientCardCustomerNumberSD>
                    </Grid>
                    { userInfo.role === ROLES.WMOadvisor &&
                      <Grid item>
                        <ClientCardGOIOIDSD data-test="goio-id">
                          Advisor: {client.wealthManagerName}
                        </ClientCardGOIOIDSD>
                      </Grid>
                    }
                  </Grid>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <ClientCardEditButtonSD
                        color="primary"
                        size="small"
                        variant="text"
                        startIcon={<IconEdit />}
                        onClick={() => onEditClient(client)}
                        data-test="button-edit-client"
                      >
                        Edit
                      </ClientCardEditButtonSD>
                    </Grid>
                    <Grid item>
                      <ClientCardDateSD data-test="date">
                        (Added {moment(client.created).format('DD.MM.YYYY')})
                      </ClientCardDateSD>
                    </Grid>
                  </Grid>
                </ClientTableCellSD>
                <ClientTableCellSD
                  expanded={expandIds[client.id] ? 1 : 0}
                  style={{ width: '25%' }}
                >
                  <ClientCardBanksCountSD data-test="banks-count">
                    {Object.keys(portfoliosByBanks).length} Banks
                  </ClientCardBanksCountSD>
                </ClientTableCellSD>
                <ClientTableCellSD expanded={expandIds[client.id] ? 1 : 0}>
                  <Grid container>
                    <Grid item xs={4}>
                      <ClientCardPortfoliosCountSD data-test="portfolios-count">
                        {client.portfolios.length} Portfolios
                      </ClientCardPortfoliosCountSD>
                    </Grid>
                    <Grid item>
                      <Button
                        component={Link}
                        to={`/add-portfolio/${client.id}`}
                        color="primary"
                        variant="contained"
                        startIcon={<IconAddPortfolio />}
                        data-test="button-add-portfolio"
                      >
                        Add new portfolios
                      </Button>
                    </Grid>
                  </Grid>
                  {client.portfolios.length > 0 && (
                    <ClientTableButtonMoreSD
                      onClick={() => showMore(client.id)}
                      data-test="button-more"
                    >
                      {expandIds[client.id] ? (
                        <ClientTableIconOpened />
                      ) : (
                        <ClientTableIconClosed />
                      )}
                    </ClientTableButtonMoreSD>
                  )}
                </ClientTableCellSD>
              </ClientTableRowSD>
              <Collapse
                in={expandIds[client.id]}
                timeout="auto"
                component={() => (
                  <CollapsedRow
                    portfoliosByBanks={portfoliosByBanks}
                    clientId={client.id}
                  />
                )}
                unmountOnExit
              />
              <tr style={{ height: '5px' }} />
            </React.Fragment>
          );
        })}
      </TableBody>
    </ClientsTableSD>
  );
}

export default ClientsTable;
