import styled, { css } from 'styled-components';
import { addUnit } from '../../../../helpers/common';
import breakpoints from '../../../../helpers/breakpoints';

export const PortfolioTableSD = styled.div`
  overflow: hidden;

  color: ${({ theme }) => theme.colors.textGray};
`;

export const PortfolioTableRowSD = styled.div`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

interface PortfolioTableColProps {
  innerWidth?: any;
}

export const PortfolioTableColSD = styled.div<PortfolioTableColProps>`
  flex-grow: ${({ innerWidth }) => (innerWidth === 'grow' ? 1 : 0)};
  flex-shrink: 0;
  ${({ innerWidth }) => innerWidth === 'grow' && 'flex-basis: 0;'};
  ${({ innerWidth }) =>
    innerWidth === 'grow' &&
    css`
      min-width: 0;
    `};
  ${({ innerWidth }) => {
    if (innerWidth === 'auto' || innerWidth === 'grow')
      return css`
        width: auto;
      `;

    return css`
      width: calc(${addUnit(innerWidth)} + 20px);
    `;
  }};
  padding: 5px;
`;

export const PortfolioTableBodySD = styled.div``;

// table header

export const PortfolioTableHeaderSD = styled.div`
  ${PortfolioTableColSD} {
    padding: 0 5px 10px;
  }
`;

export const PortfolioTableHeaderTitleSD = styled.div`
  font: 700 12px/17px OpenSans, sans-serif;
`;

export const PortfolioTableEllipsisTextSD = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PortfolioTableAccountSD = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const PortfolioTableAccountTitleSD = styled.div`
  margin-right: 10px;
  flex: 0 0 75px;

  ${breakpoints.down('md')} {
    flex: initial;
  }
`;

export const PortfolioTableAccountValueSD = styled.div``;
