import React from 'react';
import styled from 'styled-components';
import { Tooltip, Fab } from '@material-ui/core';

export const TooltipKitSD = styled(props => (
  <Tooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props}
  />
))`
  & .tooltip {
    width: 220px;
    padding: 10px 20px 10px 10px;
    background-color: #6693b4;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
`;

export const TooltipKitButtonSD = styled(Fab)`
  width: 20px;
  height: 20px;
  min-height: initial;
  background-color: transparent;
  box-shadow: none;

  svg {
    fill: #b3c9da;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: transparent;

    svg {
      fill: #6693b4;
    }
  }
`;

export const TooltipKitTitleSD = styled.strong`
  color: #fff;
  font: 400 12px/16px OpenSans, Arial, sans-serif;
`;
