import styled from 'styled-components';
import { TableCell, TableRow } from '@material-ui/core';

export const CollapsedRowSD = styled(TableRow)`
  border-top: 0;
`;

export const CollapsedRowCellSD = styled(TableCell)`
  padding-top: 0;
`;
