import styled, { css } from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import React from 'react';

export const FeedbackFormTitleSD = styled(Typography)`
  color: #336f9b;
  margin-bottom: 20px;
`;

interface FeedbackFormFieldset {
  gutterBottom?: boolean;
}

export const FeedbackFormFieldsetSD = styled(({ gutterBottom, ...props }) => (
  <fieldset {...props} />
))<FeedbackFormFieldset>`
  padding: 0;
  margin: 0 0 ${({ gutterBottom }) => (gutterBottom ? '24px' : 0)};
  border: 0;
`;

export const FeedbackFormLegendSD: any = styled(Typography)`
  margin-bottom: 30px;
`;

interface FeedbackFormTextField {
  gutterBottom?: boolean;
}

export const FeedbackFormTextFieldSD = styled(({ gutterBottom, ...props }) => (
  <TextField {...props} />
))<FeedbackFormTextField>`
  ${({ gutterBottom }) =>
    gutterBottom &&
    css`
      margin-bottom: 30px;
    `};
`;

interface FeedbackFormTextarea {
  isValid?: boolean;
}

export const FeedbackFormTextareaSD: any = styled(({ isValid, ...props }) => (
  <TextField {...props} />
))<FeedbackFormTextarea>`
  ${({ isValid, theme }) =>
    isValid &&
    css`
      .MuiFilledInput-multiline:before {
        border-color: ${theme.palette.primary[40]};
      }
    `};
`;
