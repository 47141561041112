import styled from 'styled-components';

export const PaginationKitSD = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaginationKitTitleSD = styled.div`
  position: absolute;
  top: 7px;
  left: 0;

  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px OpenSans, sans-serif;
`;

export const PaginationKitMUPaginationSD = styled.div`
  margin-bottom: 20px;
`;
