import { Action, applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk, { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from './rootReducer';

export const middleware = [ReduxThunk];

// In development, use the browser's Redux dev tools extension if installed
const isDevelopment = process.env.NODE_ENV === 'development';

// use the old version (2.15.5) of the redux dev tools extension, because version 2.16 has issue
// https://github.com/zalmoxisus/redux-devtools-extension/issues/588

// noinspection JSUnresolvedVariable
const composeEnhancers =
  // @ts-ignore
  (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
