import styled from 'styled-components';
import { Button, ExtendButtonBase, Typography } from '@material-ui/core';
import breakepoints from '../../helpers/breakpoints';

export const RouteLoginSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 376px;

  ${breakepoints.down('md')} {
    width: 308px;
  }

  ${breakepoints.down('sm')} {
    width: 348px;
  }
`;

export const RouteLoginTitleSD = styled(Typography)`
  margin-bottom: 59px;
`;

export const RouteLoginFormSD = styled.div`
  margin-bottom: 60px;
`;

export const RouteLoginButtonSD: ExtendButtonBase<any> = styled(Button)`
  align-self: flex-end;
`;
