import styled from 'styled-components';
import {
  Button,
  Typography,
  IconButton,
  TextField,
  FormControl,
} from '@material-ui/core';
import breakpoints from '../../../helpers/breakpoints';
import {LinkProps} from "react-router-dom";

export const AddPortfolioFormSD = styled.form``;

interface AddPortfolioFormTitleProps {
  mb?: Number;
}

export const AddPortfolioFormTitleSD = styled(Typography)<
  AddPortfolioFormTitleProps
>`
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '20px')};
`;

interface AddPortfolioFormFieldProps {
  mb?: Number;
}

export const AddPortfolioFormFieldSD = styled.div<AddPortfolioFormFieldProps>`
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '33px')};
`;

export const AddPortfolioFormFieldListSD = styled.ul`
  padding: 0;
  margin: 0 0 31px;
  list-style: none;
`;

export const AddPortfolioFormFieldItemSD = styled.li`
  position: relative;
  z-index: 1;
  margin-bottom: 26px;
`;

const AddPortfolioFormCloseButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: -40px;
  transform: rotate(45deg);
  color: ${({ theme }) => theme.palette.secondary.main};
  padding: 5px;

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.dark};
    background-color: transparent;
  }
`;

export const AddPortfolioFormButtonSASD: any = styled(Button)``;

export const AddPortfolioFormButtonSARemoveSD: any = styled(
  AddPortfolioFormCloseButton
)``;

export const AddPortfolioFormCAFieldItemSD = styled(
  AddPortfolioFormFieldItemSD
)`
  display: flex;

  ${breakpoints.down('md')} {
    flex-direction: column;
  }
`;

export const AddPortfolioFormCATextFieldSD = styled(TextField)<
  typeof TextField
>`
  flex-grow: 1;
  margin-right: 16px;

  ${breakpoints.down('md')} {
    margin-bottom: 25px;
  }
`;

export const AddPortfolioFormCASelectSD = styled(FormControl)<
  typeof FormControl
>`
  flex: 0 0 130px;
  margin-right: 16px;

  ${breakpoints.down('md')} {
    width: 130px;
  }
`;

export const AddPortfolioFormButtonCASD: any = styled(Button)``;

export const AddPortfolioFormButtonCARemoveSD: any = styled(
  AddPortfolioFormCloseButton
)``;

export const AddPortfolioFormButtonsSD = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
`;

export const AddPortfolioFormButtonSD = styled(Button)`
  &:not(:last-child) {
    margin-right: 20px;
  }
`;

export const PoliciesButtonSD = styled(Button)<LinkProps>`
  margin-bottom: 50px;
`;