import styled from 'styled-components';
import { Table, TableCell } from '@material-ui/core';

export const ReconciliationsTableSD = styled(Table)``;

export const ReconciliationsTableCellSD = styled(TableCell)`
  padding: 8px 18px;

  &:nth-child(2) {
    width: 180px;
  }

  &:nth-child(3) {
    width: 195px;
  }

  &:nth-child(4) {
    width: 210px;
  }

  &:last-child {
    width: 320px;
  }
`;
