import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ClientCardSD = styled.div``;

export const ClientCardFullNameSD = styled.div`
  margin-bottom: 5px;
  font: ${({ theme }) => theme.fontWeight.bold} 16px/22px OpenSans, sans-serif;
`;

export const ClientCardBanksCountSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px OpenSans, sans-serif;
`;

export const ClientCardPortfoliosCountSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px OpenSans, sans-serif;
`;

export const ClientCardDateSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.normal} 12px/17px OpenSans, sans-serif;
`;

export const ClientCardCustomerNumberSD = styled.div`
  margin-bottom: 3px;
  font: ${({ theme }) => theme.fontWeight.normal} 12px/17px OpenSans, sans-serif;
`;

export const ClientCardGOIOIDSD = styled.div`
  margin-bottom: 3px;
  font: ${({ theme }) => theme.fontWeight.normal} 12px/17px OpenSans, sans-serif;
`;

export const ClientCardEditButtonSD = styled(Button)`
  padding-left: 0;
`;

export const ClientCardBankSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.bold} 14px/19px OpenSans, sans-serif;
`;

export const ClientCardPortfolioSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.bold} 14px/19px OpenSans, sans-serif;
`;

export const ClientCardAccountSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px OpenSans, sans-serif;
`;
