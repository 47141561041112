import React from 'react';
import { FormControl, FormLabel, RadioGroup } from '@material-ui/core';
import RadioKit from '../../../../../components/kit/Fields/Radio/RadioKit';
import { RadioKitLabelSD } from '../../../../../components/kit/Fields/Radio/styled';
import { RadioSortByGroupControlLabelSD } from './styled';

export interface RadioSortByGroupProps {
  value: string;
  onChange: (value: string) => void;
}

function RadioSortByGroup(props: RadioSortByGroupProps) {
  const { value, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset" data-test="component-radio-sort-by-group">
      <FormLabel component="legend">Sort by:</FormLabel>
      <RadioGroup
        aria-label="sort-by"
        name="sort-by"
        value={value}
        onChange={handleChange}
      >
        <RadioSortByGroupControlLabelSD
          data-test="control-label"
          value="custodian"
          control={<RadioKit color="primary" />}
          label={<RadioKitLabelSD small>Custodian, A to Z</RadioKitLabelSD>}
        />
        <RadioSortByGroupControlLabelSD
          data-test="control-label"
          control={<RadioKit color="primary" />}
          value="count"
          label={
            <RadioKitLabelSD small>
              Amount of reconciliation, highest to lowest
            </RadioKitLabelSD>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}

export default RadioSortByGroup;
