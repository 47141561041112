export const TRANSACTION_TYPES = {
  buy: 'BUY',
  sell: 'SELL',
};

export const TRANSACTION_STATUSES = {
  error: 'error',
};

export const TRANSACTION_LABELS_BY_TYPE = {
  [TRANSACTION_TYPES.buy]: 'Buy',
  [TRANSACTION_TYPES.sell]: 'Sell',
};
