import styled, { css } from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import React from 'react';

export const FormReadOnlyFieldSD = styled;

export const FormReadOnlyLegendSD: any = styled(Typography)`
  margin-bottom: 20px;
`;

export const FormReadOnlyFieldsetSD = styled.fieldset`
  padding: 0;
  margin: 0 0 50px;
  border: 0;
`;

interface FormReadOnlyTextField {
  gutterBottom?: boolean;
}

export const FormReadOnlyTextFieldSD = styled(({ gutterBottom, ...props }) => (
  <TextField {...props} />
))<FormReadOnlyTextField>`
  .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.primary[40]};
  }

  .MuiFilledInput-root.Mui-disabled {
    background-color: #fff;
  }

  .MuiFilledInput-root.Mui-disabled:before {
    border-color: ${({ theme }) => theme.colors.lineGrey};
  }

  ${({ gutterBottom }) =>
    gutterBottom &&
    css`
      margin-bottom: 20px;
    `}
`;
