import React, { useEffect } from 'react';
import AppRouting from './AppRouting/AppRouting';
import { ThemeProvider } from 'styled-components';
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import { GOIOTheme } from '../themes/goioStyledTheme';

import mainTheme from '../themes/mainTheme';
import { SnackbarProvider } from 'notistack';
import {
  SnackbarKitImageSD,
  SnackbarKitWrapImageSD,
} from '../components/kit/SnackbarKit/styled';
import iconCheckSnackbar from '../static/icons/check-snackbar.svg';
import iconWarningSnackbar from '../static/icons/warning-snackbar.svg';
import { useDispatch } from 'react-redux';
import {
  actionGetUserInfo,
  setAuthorizedAction,
} from '../store/commonStates/user/actions';
import { AppDispatch } from '../store/configureStore';
import Notifier from '../components/smart/Notifier/Notifier';

function App() {
  const dispatch = useDispatch();
  const appDispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      dispatch(setAuthorizedAction());
      appDispatch(actionGetUserInfo());
    }
  }, []);

  return (
    <div data-test="component-app">
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={mainTheme}>
          <CssBaseline />
          <ThemeProvider theme={GOIOTheme}>
            <SnackbarProvider
              classes={{
                variantSuccess: 'variantSuccess',
                variantError: 'variantError',
              }}
              iconVariant={{
                success: (
                  <SnackbarKitWrapImageSD>
                    <SnackbarKitImageSD src={iconCheckSnackbar} />
                  </SnackbarKitWrapImageSD>
                ),
                error: (
                  <SnackbarKitWrapImageSD>
                    <SnackbarKitImageSD src={iconWarningSnackbar} />
                  </SnackbarKitWrapImageSD>
                ),
              }}
            >
              <>
                <AppRouting />
                <Notifier />
              </>
            </SnackbarProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </div>
  );
}

export default App;
