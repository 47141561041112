import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';

export const CustodiansFilterHeaderSD = styled.header`
  padding-top: 30px;
  padding-bottom: 18px;
  background-color: ${({ theme }) => theme.palette.primary[90]};
`;

export const CustodiansFilterContainerSD = styled.div`
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const CustodiansFilterContentSD = styled.div`
  padding-top: 28px;
  padding-bottom: 50px;
`;

export const CustodiansFilterTitleSD = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 32px;
  line-height: 43px;
`;

export const CustodiansFilterCounterSD = styled.div`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/18px Open Sans, Arial,
    sans-serif;
`;

export const CustodiansFilterClearButtonSD = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.palette.primary.light};

  .MuiButton-iconSizeSmall > *:first-child {
    font-size: 20px;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: inherit;
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const CustodiansFilterSearchFieldSD = styled.div`
  margin-top: 14px;
`;

export const CustodiansFilterSortByGroupSD = styled.div`
  max-width: 200px;
  margin-left: auto;
`;

export const CustodiansFilterCancelButtonSD = styled(Button)`
  padding-left: 7px;
  padding-right: 7px;
  margin-top: 15px;
`;
