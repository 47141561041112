import React from 'react';
import moment from 'moment';
import {
  ReconciliationInfoHeaderSD,
  ReconciliationInfoItemSD,
  ReconciliationInfoSD,
  ReconciliationInfoTitleSD,
  ReconciliationInfoValueSD,
} from './styled';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

export interface IReconciliationInfoSelector {
  isin: string | null;
  status: string | undefined;
  date: Date | undefined;
  id: number | null;
  custodian: string | undefined;
  portfolioNumber: string | undefined;
}
function ReconciliationInfo() {
  const { isin, status, date, custodian, id, portfolioNumber } = useSelector<
    RootState,
    IReconciliationInfoSelector
  >(store => ({
    isin: store.qualityEngineer.reconciliationDetails.baseInfo.isin,
    status:
      store.qualityEngineer.reconciliationDetails.baseInfo.reconciliationStatus
        ?.label,
    date:
      store.qualityEngineer.reconciliationDetails.baseInfo.reconciliationDate,
    custodian:
      store.qualityEngineer.reconciliationDetails.baseInfo.portfolio?.custodian,
    id: store.qualityEngineer.reconciliationDetails.baseInfo.id,
    portfolioNumber:
      store.qualityEngineer.reconciliationDetails.baseInfo.portfolio
        ?.portfolioNumber,
  }));

  return (
    <ReconciliationInfoSD data-test="component-reconciliation-info">
      <ReconciliationInfoHeaderSD>
        <ReconciliationInfoItemSD>
          <ReconciliationInfoTitleSD>ISIN:</ReconciliationInfoTitleSD>
          <ReconciliationInfoValueSD data-test="isin">
            {isin ? isin : 'N/A'}
          </ReconciliationInfoValueSD>
        </ReconciliationInfoItemSD>
      </ReconciliationInfoHeaderSD>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <ReconciliationInfoValueSD data-test="status">
            {status}
          </ReconciliationInfoValueSD>
        </Grid>
        <Grid item xs={3}>
          <ReconciliationInfoItemSD>
            <ReconciliationInfoTitleSD>
              Date of break:
            </ReconciliationInfoTitleSD>
            <ReconciliationInfoValueSD data-test="date">
              {moment(date).format('DD.MM.YYYY - HH:MM')}
            </ReconciliationInfoValueSD>
          </ReconciliationInfoItemSD>
          <ReconciliationInfoItemSD>
            <ReconciliationInfoTitleSD>
              Reconcilliation ID:
            </ReconciliationInfoTitleSD>
            <ReconciliationInfoValueSD data-test="id">
              {id}
            </ReconciliationInfoValueSD>
          </ReconciliationInfoItemSD>
        </Grid>
        <Grid item xs={6}>
          <ReconciliationInfoItemSD>
            <ReconciliationInfoTitleSD>Custodian:</ReconciliationInfoTitleSD>
            <ReconciliationInfoValueSD data-test="custodian">
              {custodian}
            </ReconciliationInfoValueSD>
          </ReconciliationInfoItemSD>
          <ReconciliationInfoItemSD>
            <ReconciliationInfoTitleSD>
              Portfolio number:
            </ReconciliationInfoTitleSD>
            <ReconciliationInfoValueSD data-test="portfolio-number">
              {portfolioNumber}
            </ReconciliationInfoValueSD>
          </ReconciliationInfoItemSD>
        </Grid>
      </Grid>
    </ReconciliationInfoSD>
  );
}

export default ReconciliationInfo;
