import React from 'react';
import { Dialog, Button } from '@material-ui/core';
import {
  DialogSaveSuccessActionsSD,
  DialogSaveSuccessContentSD,
  DialogSaveSuccessIconSD,
} from './styled';
import { DialogKitCloseButtonSD } from '../../../components/kit/DialogKit/styled';
import IconClose from '../../../components/icons/IconClose';
import IconCheckCircle from '../../../components/icons/IconCheckCircle';
import Form from './Form/Form';

interface DialogSaveSuccessProps {
  onClose: Function;
  open: boolean;
  customerNumber?: string;
  fullName: string;
  addAnother?: boolean;
  bankName: string;
}

function DialogSaveSuccess(props: DialogSaveSuccessProps) {
  const {
    open,
    onClose,
    fullName,
    customerNumber,
    addAnother,
    bankName,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (value?: string) => {
    onClose(value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogKitCloseButtonSD aria-label="close" onClick={handleClose}>
        <IconClose />
      </DialogKitCloseButtonSD>
      <DialogSaveSuccessContentSD>
        <DialogSaveSuccessIconSD>
          <IconCheckCircle />
        </DialogSaveSuccessIconSD>
        The portfolio is successfully added for{' '}
        <strong>
          {fullName} (Customer Number {customerNumber}) .
        </strong>
      </DialogSaveSuccessContentSD>
      {addAnother ? (
        <Form handleSubmit={handleSubmit} bankName={bankName} />
      ) : (
        <DialogSaveSuccessActionsSD>
          <Button
            color="primary"
            variant="contained"
            aria-label="close"
            onClick={handleClose}
          >
            Go to My Clients
          </Button>
        </DialogSaveSuccessActionsSD>
      )}
    </Dialog>
  );
}

export default DialogSaveSuccess;
