import React from 'react';
import { RouteQEFeedbackLinkSD, RouteQEFeedbackSD } from './styled';
import { Link } from 'react-router-dom';
import IconChevronLeft from '../../../components/icons/IconChevronLeft';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import { ContainerSD } from '../../../app/styled';

function RouteQEFeedback() {
  return (
    <RouteQEFeedbackSD data-test="component-route-qe-feedback">
      <ContainerSD>
        <RouteQEFeedbackLinkSD
          component={Link}
          to="/"
          color="primary"
          variant="text"
          data-test="link-my-clients"
          startIcon={<IconChevronLeft />}
        >
          Go back to Reconciliation Breaks page
        </RouteQEFeedbackLinkSD>
        <FeedbackForm
          onSubmit={(values, setErrors, setSubmitting) => {
            console.log(values);
          }}
        />
      </ContainerSD>
    </RouteQEFeedbackSD>
  );
}

export default RouteQEFeedback;
