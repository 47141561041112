import React from 'react';
import EnhancedTableHead from '../../../../components/presentation/EnhancedTableHead/EnhancedTableHead';
import {
  RECONCILIATION_STATUS_LABELS,
  ReconciliationTableHeadCells,
} from './constants/common';
import { Button, Grid, TableBody, TableRow } from '@material-ui/core';
import {
  ReconciliationCardCustodianSD,
  ReconciliationCardDateSD,
  ReconciliationCardIdSD,
  ReconciliationCardNumberSD,
  ReconciliationCardStatusSD,
} from './ReconciliationCard/styled';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ReconciliationsTableCellSD, ReconciliationsTableSD } from './styled';
import { IReconciliation } from '../../store/types';

type Order = 'asc' | 'desc';

export interface IReconciliationsTableProps {
  data: IReconciliation[];
}

function ReconciliationsTable(props: IReconciliationsTableProps) {
  const { data = [] } = props;

  return (
    <ReconciliationsTableSD
      stickyHeader
      aria-label="reconciliations table"
      size="small"
    >
      <EnhancedTableHead cells={ReconciliationTableHeadCells} />

      <TableBody>
        {data.map(r => (
          <TableRow key={r.id}>
            <ReconciliationsTableCellSD>
              <ReconciliationCardCustodianSD data-test="custodian">
                {r.portfolio.custodian}
              </ReconciliationCardCustodianSD>
            </ReconciliationsTableCellSD>
            <ReconciliationsTableCellSD>
              <ReconciliationCardIdSD data-test="reconciliation-id">
                {r.id}
              </ReconciliationCardIdSD>
            </ReconciliationsTableCellSD>
            <ReconciliationsTableCellSD>
              <ReconciliationCardNumberSD data-test="portfolio-number">
                {r.portfolio.portfolioNumber}
              </ReconciliationCardNumberSD>
            </ReconciliationsTableCellSD>
            <ReconciliationsTableCellSD>
              <ReconciliationCardStatusSD status={r.status} data-test="status">
                {RECONCILIATION_STATUS_LABELS[r.status]}
              </ReconciliationCardStatusSD>
            </ReconciliationsTableCellSD>
            <ReconciliationsTableCellSD>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <ReconciliationCardDateSD data-test="date">
                    {moment(r.updatedAt).format('DD.MM.YYYY - HH.MM')}
                  </ReconciliationCardDateSD>
                </Grid>
                <Grid item>
                  <Button
                    component={Link}
                    to={`/reconciliations/${r.id}`}
                    color="primary"
                    size="small"
                    variant="text"
                    data-test="link-reconciliation-details"
                  >
                    See Reconciliation
                  </Button>
                </Grid>
              </Grid>
            </ReconciliationsTableCellSD>
          </TableRow>
        ))}
      </TableBody>
    </ReconciliationsTableSD>
  );
}

export default ReconciliationsTable;
