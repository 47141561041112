import React from 'react';
import { StatisticItemSD, StatisticNumberSD, StatisticTitleSD } from './styled';
import { Grid } from '@material-ui/core';

export interface IStatisticItemProps {
  number: Number;
  title: String;
  abbr: String;
}

function StatisticItem(props: IStatisticItemProps) {
  const { number, title, abbr } = props;

  return (
    <StatisticItemSD data-test="component-statistic-item">
      <Grid container spacing={1}>
        <Grid item>
          <StatisticNumberSD data-test="number">{number}</StatisticNumberSD>
        </Grid>
        <Grid item>
          <StatisticTitleSD data-test="title">{title}</StatisticTitleSD>
          <abbr data-test="abbr">{abbr}</abbr>
        </Grid>
      </Grid>
    </StatisticItemSD>
  );
}

export default StatisticItem;
