import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import iconChecked from '../../../../static/icons/check.svg';

export const CheckboxKitIconSD = styled.span`
  width: 20px;
  height: 20px;
  border: 1px solid #b3c9da;
  border-radius: 3px;

  input:disabled ~ & {
    border-color: #c9c9c9;
    background-color: #eaedf3;
  }
`;

export const CheckboxKitCheckedIcon = styled(CheckboxKitIconSD)`
  position: relative;
  border-color: #2d9c3c;
  background-color: #35ad46;
  box-shadow: 0 1px 1px #2d9c3c;
  &:before {
    content: url(${iconChecked});
    position: absolute;
    top: -4px;
    left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
`;

export const CheckboxKitSD = styled(Checkbox)`
  &&& {
    &:hover {
      background-color: transparent;

      ${CheckboxKitIconSD} {
        border-color: #6693b4;
      }
    }

    input:disabled ~ ${CheckboxKitIconSD} {
      border-color: #c9c9c9;
      background-color: #eaedf3;
    }
  }
`;

export const CheckboxKitControlLabelSD = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;
  max-width: 430px;
`;

export const CheckboxKitWrapLabelSD = styled.div`
  padding-top: 9px;
  color: #707070;
`;

export const CheckboxKitLabelSD = styled.div`
  margin-bottom: 5px;
  font: 400 16px/22px Open Sans, Arial, sans-serif;
`;

export const CheckboxKitAdditionalLabelSD = styled.div`
  font: 400 14px/19px Open Sans, Arial, sans-serif;
`;
