import styled from 'styled-components';

export const StatusBarKitSD = styled.ul`
  position: relative;
  z-index: 0;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    height: 1px;
  }

  &:before {
    left: 0;
    width: 25%;
    background-color: ${({ theme }) => theme.palette.primary[90]};
  }

  &:after {
    left: 25%;
    width: 25%;
    background-color: ${({ theme }) => theme.palette.secondary[70]};
  }
`;

export const StatusBarKitIconSD = styled.div`
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.palette.primary[90]};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.primary[90]};
`;

export const StatusBarKitTitleSD = styled.div`
  margin-bottom: 2px;
  color: ${({ theme }) => theme.colors.textLightGrey};
  font: ${({ theme }) => theme.fontWeight.bold} 14px/19px OpenSans, sans-serif;
`;

export const StatusBarKitTimeSD = styled.time`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 12px/17px OpenSans, sans-serif;
`;

interface StepProps {
  isActive?: boolean;
}

const Step = styled.li<StepProps>`
  position: relative;
  z-index: 1;
  width: 25%;

  ${StatusBarKitTitleSD} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.palette.primary.light : theme.colors.textLightGrey};
  }
`;

export const StatusBarKitStepSubmittedSD = styled(Step)`
  ${StatusBarKitIconSD} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.palette.primary[70] : theme.palette.primary[90]};
    background-color: ${({ theme, isActive }) =>
      isActive ? '#fff' : theme.palette.primary[90]};
  }
  }
`;

export const StatusBarKitStepRequestedSD = styled(Step)`
  ${StatusBarKitIconSD} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.palette.primary[40] : theme.palette.primary[90]};
    background-color: ${({ theme, isActive }) =>
      isActive ? '#fff' : theme.palette.primary[90]};
  }
`;

export const StatusBarKitStepActivatedSD = styled(Step)`
  ${StatusBarKitIconSD} {
    background-color: ${({ theme }) => theme.palette.secondary[70]};
    color: ${({ theme }) => theme.palette.secondary[70]};
  }

  ${StatusBarKitIconSD} {
    color: ${({ theme, isActive }) =>
      isActive ? theme.palette.secondary.main : theme.palette.secondary[70]};
    background-color: ${({ theme, isActive }) =>
      isActive ? '#fff' : theme.palette.secondary[70]};
  }
`;
