import React, { useRef } from 'react';
import { PaginationFormInputSD, PaginationFormSD } from './styled';
import { Button } from '@material-ui/core';
import _ from 'lodash';

export interface IPaginationFormProps {
  onSubmit: Function;
  maxValue: number;
}

function PaginationForm(props: IPaginationFormProps) {
  const input = useRef(null);
  const { onSubmit, maxValue } = props;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(+_.get(input, 'current.value', 1));
  };

  return (
    <PaginationFormSD onSubmit={handleSubmit}>
      <PaginationFormInputSD
        type="number"
        pattern="\d*"
        min="1"
        max={maxValue}
        defaultValue={1}
        // @ts-ignore
        inputmode="numeric"
        name="page"
        ref={input}
      />
      <Button type="submit" color="primary" variant="contained">
        Go to This Page
      </Button>
    </PaginationFormSD>
  );
}

export default PaginationForm;
