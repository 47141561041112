import React, { Fragment } from 'react';
import { TableCell, TableRow, Button } from '@material-ui/core';
import { TRANSACTION_LABELS_BY_TYPE } from '../constants/common';
import moment from 'moment';
import {
  ReconciliationsTableCellSD,
  ReconciliationTableBodySD,
  ReconciliationTableRowSD,
  ReconciliationTableSD,
  ReconciliationTableHeadSD,
} from './styled';
import {
  TransactionCardAmountSD,
  TransactionCardCalculationSD,
} from './TransactionCard/styled';
import { ITransaction } from '../store/types';
import { formatMoney } from '../../../../helpers/common';

export interface IReconciliationTableProps {
  startStatement: {
    positionId: number | null;
    positionDate: string | undefined;
    amount: number | null;
    statementsFile: string | null;
  };
  endStatement: {
    positionId: number | null;
    positionDate: string | undefined;
    amount: number | null;
    goioComputation: number | null;
    reconciliationCorrect: boolean | null;
    statementsFile: string | null;
  };
  transactions: ITransaction[];
  onClickRow: (id: number) => void;
  activeTransaction: number | null;
  onClickDocument: (file_id: string) => void;
}

function ReconciliationTable(props: IReconciliationTableProps) {
  const {
    startStatement,
    endStatement,
    transactions,
    onClickRow,
    activeTransaction,
    onClickDocument
  } = props;

  return (
    <ReconciliationTableSD
      stickyHeader
      aria-label="reconciliation table"
      size="small"
    >
      <ReconciliationTableHeadSD>
        <TableRow>
          <TableCell>Transcation</TableCell>
          <TableCell>Date</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right">Goio Calculations</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <tr style={{ height: '10px' }} />
      </ReconciliationTableHeadSD>

      <ReconciliationTableBodySD>
        <ReconciliationTableRowSD data-test="start-statement">
          <ReconciliationsTableCellSD>Start</ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD>
            {startStatement.positionDate
              ? moment(startStatement.positionDate).format('DD.MM.YYYY')
              : 'N/A'}
          </ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD align="right">
            {formatMoney(startStatement.amount)}
          </ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD />
          <ReconciliationsTableCellSD align="right">
            <Button color="primary" size="small" variant="outlined"
                    onClick={() => startStatement.statementsFile ? onClickDocument(startStatement.statementsFile) : null}>
              See document
            </Button>
          </ReconciliationsTableCellSD>
        </ReconciliationTableRowSD>
        <tr style={{ height: '5px' }} />

        {transactions.map((item, i) => (
          <Fragment key={item.transactionId}>
            <ReconciliationTableRowSD
              data-test="transaction"
              isActive={item.transactionId === activeTransaction}
              isError={!item.reconciliationCorrect}
            >
              <ReconciliationsTableCellSD>
                <div data-test="type">
                  {TRANSACTION_LABELS_BY_TYPE[item.operation]}
                </div>
              </ReconciliationsTableCellSD>
              <ReconciliationsTableCellSD>
                <time data-test="date">
                  {moment(item.transactionDate).format('DD.MM.YYYY')}
                </time>
              </ReconciliationsTableCellSD>
              <ReconciliationsTableCellSD align="right">
                <TransactionCardAmountSD
                  data-test="amount"
                  type={item.operation}
                >
                  {formatMoney(item.amount)}
                </TransactionCardAmountSD>
              </ReconciliationsTableCellSD>
              <ReconciliationsTableCellSD align="right">
                <TransactionCardCalculationSD data-test="calculation">
                  {formatMoney(item.goioComputation)}
                </TransactionCardCalculationSD>
              </ReconciliationsTableCellSD>
              <ReconciliationsTableCellSD align="right">
                <Button
                  data-test="button"
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={() => item.statementsFile ? onClickDocument(item.statementsFile) : null}>
                  See document
                </Button>
              </ReconciliationsTableCellSD>
            </ReconciliationTableRowSD>
            <tr style={{ height: '5px' }} />
          </Fragment>
        ))}

        <ReconciliationTableRowSD
          isError={!endStatement.reconciliationCorrect}
          data-test="end-statement"
        >
          <ReconciliationsTableCellSD>End</ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD>
            {moment(endStatement.positionDate).format('DD.MM.YYYY')}
          </ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD align="right">
            <TransactionCardAmountSD>
              {formatMoney(endStatement.amount)}
            </TransactionCardAmountSD>
          </ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD align="right">
            <TransactionCardCalculationSD>
              {formatMoney(endStatement.goioComputation)}
            </TransactionCardCalculationSD>
          </ReconciliationsTableCellSD>
          <ReconciliationsTableCellSD align="right">
            <Button color="primary" size="small" variant="outlined"
                    onClick={() => endStatement.statementsFile ? onClickDocument(endStatement.statementsFile) : null}>
              See document
            </Button>
          </ReconciliationsTableCellSD>
        </ReconciliationTableRowSD>
      </ReconciliationTableBodySD>
    </ReconciliationTableSD>
  );
}

export default ReconciliationTable;
