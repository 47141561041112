import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const FilterSD = styled.ul`
  padding: 30px 0 0;
  margin: 0;
`;

export const FilterRowSD = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const FilterTitleSD = styled.div`
  flex: 0 0 58px;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;
`;

export const FilterComponentSD = styled.div`
  flex-grow: 1;
`;

export const FilterClearCustodiansButtonSD = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.palette.primary.light};

  .MuiButton-iconSizeSmall > *:first-child {
    font-size: 20px;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: inherit;
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
