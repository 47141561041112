import React from 'react';
import RegistrationForm, { FormValues } from './RegistrationForm/RegistrationForm';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import {RouteLoginButtonSD, RouteLoginFormSD, RouteLoginSD, RouteLoginTitleSD} from "./styled";
import Api from "../../api/api";

function RouteRegistration() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const sendLoginRequest = (
    values: FormValues,
    setErrors: Function,
    setSubmitting: Function
  ) => {
    Api.registration(values).then(() => {
      enqueueSnackbar('The registration was successful. A message has been sent to your email. ' +
          'Please confirm the mail to activate your account', {
        variant: 'success',
        autoHideDuration: 8000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      history.push(`/login`);
    }).catch(err => {
      setSubmitting(false);
      setErrors(err.response.data);
      enqueueSnackbar('Registration not success', {
        variant: 'error',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });

    })
  };

  return (
    <RouteLoginSD data-test="component-route-login">
      <RouteLoginTitleSD variant="subtitle1">
        <Box fontSize={20} textAlign="center">
          Registration
        </Box>
      </RouteLoginTitleSD>
      <RouteLoginFormSD>
        <RegistrationForm onSubmit={sendLoginRequest} />
      </RouteLoginFormSD>
      <RouteLoginButtonSD
        component={Link}
        to="/login"
        color="primary"
        size="small"
        variant="text"
        data-test="forgot-link"
      >
        Back to Login
      </RouteLoginButtonSD>
    </RouteLoginSD>
  );
}

export default RouteRegistration;