import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconZoomIn(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M164.979,60.345a10.007,10.007,0,1,0-4.6,9.084.922.922,0,0,1,1.153.12l1.211,1.211a.8.8,0,0,0,.538.24A1.68,1.68,0,0,0,165,69.278a.8.8,0,0,0-.24-.538l-1.211-1.211a.922.922,0,0,1-.12-1.153A9.953,9.953,0,0,0,164.979,60.345Zm-10.71,7.761a7.144,7.144,0,1,1,7.838-7.838A7.17,7.17,0,0,1,154.269,68.106Z"
        transform="translate(-145 -51)"
      />
      <path
        d="M169.289,71.491h-.806a.85.85,0,0,1-.85-.85v-.806a.85.85,0,0,0-.85-.85h-.444a.85.85,0,0,0-.85.85v.806a.85.85,0,0,1-.85.85h-.806a.85.85,0,0,0-.85.85v.444a.85.85,0,0,0,.85.85h.806a.85.85,0,0,1,.85.85v.806a.85.85,0,0,0,.85.85h.444a.85.85,0,0,0,.85-.85v-.806a.85.85,0,0,1,.85-.85h.806a.85.85,0,0,0,.85-.85v-.444A.85.85,0,0,0,169.289,71.491Z"
        transform="translate(-156.562 -62.562)"
      />
    </SvgIcon>
  );
}

export default IconZoomIn;
