import {useEffect, useState} from "react";
import Api from "../../../api/api";
import {propValueOr} from "../../../helpers/common";

export interface IAdvisor {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}


function useAdvisors() {
  const [advisors, setAdvisors] = useState<Array<IAdvisor>>([]);

  useEffect(() => {
    Api.getAdvisors()
      .then(response => {
        let data = propValueOr(response, 'data', []);
        setAdvisors(data);
      })
      .catch(() => {});
  }, []);

  return advisors;
}

export { useAdvisors };
