import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import breakpoints from '../../../../helpers/breakpoints';

export const BankInfoSD = styled.article`
  background-color: ${({ theme }) => theme.palette.primary['90']};
`;

export const BankInfoTitleSD = styled(Typography)<typeof Typography>`
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
`;

export const BankInfoCountSD = styled(Typography)<typeof Typography>`
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 24px;
  font: 700 14px/19px OpenSans, sans-serif;
`;

export const BankInfoArrowDownSD = styled(KeyboardArrowDown)`
  position: absolute;
  right: 0;
`;

export const BankInfoHeaderSD = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  cursor: pointer;

  ${breakpoints.down('md')} {
    padding: 18px 16px 16px;
  }
`;

export const BankInfoTableSD = styled.div`
  padding: 0 25px 20px;

  ${breakpoints.down('md')} {
    padding: 0 16px 16px;
  }
`;
