import styled from "styled-components";

export const ContentWrapper = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const PageHeading = styled.h1`
  color: #336F9B;
  font: 300 43px 'Open Sans', serif;
`;

export const PageHeadingCentered = styled(PageHeading)`
  text-align: center;
  font-size: 42px;
`;

export const WelcomeBox = styled.div`
  border: 5px dashed #E6EDF3;
  padding: 20px 50px 30px;
  text-align: center;
`;

export const WelcomeText = styled.p`
  max-width: 572px;
  margin: 0 auto;
  color: #707070;
  font: 16px 'Open Sans', serif;
  text-align: center;
`;

export const WelcomeImage = styled.img`
  display: block;
  margin: 20px auto 0;
  max-width: 100%;
`;
