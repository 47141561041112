import React from 'react';
import * as Yup from 'yup';
import {FormikProps, getIn, withFormik} from 'formik';
import { TextField } from '@material-ui/core';
import { LoginFormButtonSD, LoginFormFieldSD, LoginFormSD } from './styled';
import PasswordField from '../../../components/kit/Fields/PasswordField/PasswordField';

export interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  company_id: string;
  first_name: string;
  last_name: string;
}

interface FormProps {
  email?: string;
  password?: string;
  confirmPassword?: string;
  company_id?: string;
  last_name?: string;
  first_name?: string;
  onSubmit: Function;
}

function RegistrationForm(props: FormikProps<FormValues>) {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
  } = props;

  return (
    <LoginFormSD onSubmit={handleSubmit} autoComplete="off">
      <LoginFormFieldSD>
        <TextField
          name="company_id"
          label="Company ID"
          variant="filled"
          fullWidth
          error={Boolean(touched.company_id && errors.company_id)}
          value={values.company_id}
          helperText={getIn(errors, 'company_id')}
          onChange={handleChange('company_id')}
          onBlur={handleBlur('company_id')}
        />
      </LoginFormFieldSD>
      <LoginFormFieldSD>
        <TextField
          name="first_name"
          label="First Name"
          variant="filled"
          fullWidth
          error={Boolean(touched.first_name && errors.first_name)}
          value={values.first_name}
          helperText={getIn(errors, 'first_name')}
          onChange={handleChange('first_name')}
          onBlur={handleBlur('first_name')}
        />
      </LoginFormFieldSD>
      <LoginFormFieldSD>
        <TextField
          name="last_name"
          label="Last Name"
          variant="filled"
          fullWidth
          error={Boolean(touched.last_name && errors.last_name)}
          value={values.last_name}
          helperText={getIn(errors, 'last_name')}
          onChange={handleChange('last_name')}
          onBlur={handleBlur('last_name')}
        />
      </LoginFormFieldSD>
      <LoginFormFieldSD>
        <TextField
          name="email"
          label="E-Mail-Address"
          variant="filled"
          fullWidth
          error={Boolean(touched.email && errors.email)}
          helperText={getIn(errors, 'email')}
          value={values.email}
          onChange={handleChange('email')}
          onBlur={handleBlur('email')}
        />
      </LoginFormFieldSD>
      <LoginFormFieldSD>
        <PasswordField
          error={Boolean(touched.password && errors.password)}
          onChange={handleChange('password')}
          onBlur={handleBlur('password')}
          helperText={getIn(errors, 'password')}
          value={values.password}
        />
      </LoginFormFieldSD>
      <LoginFormFieldSD>
        <PasswordField
          label='Confirm your password'
          name='confirm your password'
          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
          onChange={handleChange('confirmPassword')}
          onBlur={handleBlur('confirmPassword')}
          helperText={getIn(errors, 'confirmPassword')}
          value={values.confirmPassword}
        />
      </LoginFormFieldSD>
      <LoginFormButtonSD type="submit" color="primary" variant="contained">
        Register
      </LoginFormButtonSD>
    </LoginFormSD>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ email = '', password = '', confirmPassword='', company_id='', first_name='', last_name=''}) => ({ email, password, confirmPassword, company_id, first_name, last_name }),
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    password: Yup.string().required('Password is required')
      .min(8, 'Must Contain 8 Characters')
      .matches(/.*[A-Z].*/, 'Must Contain One Uppercase')
      .matches(/.*[a-z].*/, 'Must Contain One Lowercase')
      .matches(/.*\d.*/, 'Must Contain One Number'),
    confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref("password"), null], "Passwords must match"),
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    company_id: Yup.string().required('Company id is required'),
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
  }),
  handleSubmit: (values: FormValues, { props, setErrors, setSubmitting }) => {
    setSubmitting(true);
    props.onSubmit(values, setErrors, setSubmitting);
  },
})(RegistrationForm);
