import React, { useEffect } from 'react';
import { Button, Drawer, Grid, InputAdornment } from '@material-ui/core';
import RadioSortByGroup from './RadioSortByGroup/RadioSortByGroup';
import reducer, { initialState } from './reducer/reducer';
import { CUSTODIANS_FILTER_ACTIONS } from './reducer/types';
import RadioStatusGroup from './RadioStatusGroup/RadioStatusGroup';
import { SearchFieldSD } from '../../../../components/presentation/SearchField/styled';
import SearchIcon from '@material-ui/icons/Search';
import CheckboxCustodiansGroup from './CheckboxCustodiansGroup/CheckboxCustodiansGroup';
import IconAddCircle from '../../../../components/icons/IconAddCircle';
import {
  CustodiansFilterHeaderSD,
  CustodiansFilterContainerSD,
  CustodiansFilterContentSD,
  CustodiansFilterTitleSD,
  CustodiansFilterClearButtonSD,
  CustodiansFilterCounterSD,
  CustodiansFilterSearchFieldSD,
  CustodiansFilterSortByGroupSD,
  CustodiansFilterCancelButtonSD,
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { AppDispatch } from '../../../../store/configureStore';
import { closeCustodiansFilter, setSelectedCustodians } from '../store/actions';
import { HEADER_HEIGHT } from '../../../../constants/common';
import Api from '../../../../api/api';

interface CustodiansFilterSelectorProps {
  isOpen: boolean;
  selectedCustodians: string[];
}

function CustodiansFilter() {
  const appDispatch: AppDispatch = useDispatch();
  const { isOpen, selectedCustodians } = useSelector<
    RootState,
    CustodiansFilterSelectorProps
  >((state: RootState) => {
    return {
      isOpen: state.qualityEngineer.filter.isCustodiansFilterOpen,
      selectedCustodians: state.qualityEngineer.filter.selectedCustodians,
    };
  });

  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
  });

  // this not coverage with tests
  useEffect(() => {
    if (isOpen) {
      dispatch({
        type: CUSTODIANS_FILTER_ACTIONS.setSelectedCustodians,
        payload: { selectedCustodians },
      });
    }
  }, [isOpen]);

  // TODO create custom hook
  useEffect(() => {
    Api.reconsiliations
      .getCustodians({
        search: state.search,
        order_by: state.sortBy,
        order: state.sortBy === 'custodian' ? 'asc' : 'desc',
        ...(state.status !== 'all' ? { status: state.status } : {}),
      })
      .then(response => {
        dispatch({
          type: CUSTODIANS_FILTER_ACTIONS.setCustodians,
          payload: {
            custodians: response.data.results,
          },
        });
      });
  }, [state.search, state.sortBy, state.status]);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: CUSTODIANS_FILTER_ACTIONS.setSearch,
      payload: { search: (event.target as HTMLInputElement).value },
    });
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      PaperProps={{ style: { top: HEADER_HEIGHT, left: 0 } }}
      open={isOpen}
      style={{ zIndex: 1 }}
    >
      <CustodiansFilterHeaderSD>
        <CustodiansFilterContainerSD>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={3} container justify="space-between">
              <Grid
                item
                xs={12}
                container
                direction="row"
                alignItems="flex-end"
                justify="space-between"
              >
                <Grid item>
                  <CustodiansFilterTitleSD data-test="title" variant="h2">
                    Filter <br /> Custodians
                  </CustodiansFilterTitleSD>
                </Grid>
                <Grid item>
                  <CustodiansFilterCounterSD data-test="counter">
                    ( {state.selectedCustodians.length} selected )
                  </CustodiansFilterCounterSD>
                  <CustodiansFilterClearButtonSD
                    data-test="button-clear-filter"
                    variant="text"
                    size="small"
                    startIcon={<IconAddCircle />}
                    disabled={!state.selectedCustodians.length}
                    onClick={() =>
                      dispatch({
                        type: CUSTODIANS_FILTER_ACTIONS.clearCustodians,
                      })
                    }
                  >
                    Clear filter
                  </CustodiansFilterClearButtonSD>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustodiansFilterSearchFieldSD>
                  <SearchFieldSD
                    data-test="search-field"
                    placeholder={'Search custodian'}
                    fullWidth
                    value={state.search}
                    onChange={handleChangeSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </CustodiansFilterSearchFieldSD>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <CustodiansFilterSortByGroupSD>
                <RadioSortByGroup
                  value={state.sortBy}
                  onChange={value =>
                    dispatch({
                      type: CUSTODIANS_FILTER_ACTIONS.setSortBy,
                      payload: { sortBy: value },
                    })
                  }
                />
              </CustodiansFilterSortByGroupSD>
            </Grid>
            <Grid item xs={4}>
              <RadioStatusGroup
                value={state.status}
                onChange={value =>
                  dispatch({
                    type: CUSTODIANS_FILTER_ACTIONS.setStatus,
                    payload: { status: value },
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                data-test="button-apply"
                color="primary"
                variant="contained"
                onClick={() => {
                  appDispatch(setSelectedCustodians(state.selectedCustodians));
                  appDispatch(closeCustodiansFilter());
                }}
                fullWidth
              >
                Apply filter
              </Button>
              <CustodiansFilterCancelButtonSD
                onClick={() => appDispatch(closeCustodiansFilter())}
                color="primary"
                variant="outlined"
                data-test="button-cancel"
                fullWidth
              >
                Cancel and close filter
              </CustodiansFilterCancelButtonSD>
            </Grid>
          </Grid>
        </CustodiansFilterContainerSD>
      </CustodiansFilterHeaderSD>

      <CustodiansFilterContentSD>
        <CustodiansFilterContainerSD>
          <CheckboxCustodiansGroup
            custodians={state.custodians}
            selectedCustodians={state.selectedCustodians}
            onChange={(custodian: string) =>
              dispatch({
                type: CUSTODIANS_FILTER_ACTIONS.toggleCustodian,
                payload: { custodian },
              })
            }
          />
        </CustodiansFilterContainerSD>
      </CustodiansFilterContentSD>
    </Drawer>
  );
}

export default CustodiansFilter;
