import React from 'react';
import { Grid } from '@material-ui/core';
import FilterButtonKit from '../../../components/kit/FilterButtonKit/FilterButtonKit';
import { useQuery } from '../../../hooks/useQuery';

export interface FilterButtonsGroupProps {
  small?: boolean;
}

function FilterButtonsGroup(props: FilterButtonsGroupProps) {
  const { small } = props;
  const [{ filter = 'progress' }, setQuery] = useQuery();

  return (
    <Grid container spacing={2} data-test="component-filter-buttons-group">
      <Grid item>
        <FilterButtonKit
          count={122}
          title={'Portfolios in progress'}
          subtitle={'In 10 Custodians'}
          onClick={() => setQuery({ filter: 'progress' })}
          data-test="button-filter"
          isActive={filter === 'progress'}
          small={small}
        />
      </Grid>
      <Grid item>
        <FilterButtonKit
          count={90}
          title={'Connected portfolios'}
          subtitle={'In 5 Custodians'}
          onClick={() => setQuery({ filter: 'custodian' })}
          data-test="button-filter"
          isActive={filter === 'custodian'}
          small={small}
        />
      </Grid>
      <Grid item>
        <FilterButtonKit
          count={212}
          title={'All portfolios'}
          subtitle={'In 15 Custodians'}
          onClick={() => setQuery({ filter: 'portfolio' })}
          data-test="button-filter"
          isActive={filter === 'portfolio'}
          small={small}
        />
      </Grid>
    </Grid>
  );
}

export default FilterButtonsGroup;
