import styled from 'styled-components';
import { DialogActions, DialogContent } from '@material-ui/core';

export const DialogSaveSuccessContentSD = styled(DialogContent)`
  position: relative;
  margin-bottom: 35px;
  padding-left: 30px;
  overflow: initial;

  color: ${({ theme }) => theme.colors.textGray};
  font-size: 16px;
  line-height: 22px;
`;

export const DialogSaveSuccessIconSD = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const DialogSaveSuccessActionsSD = styled(DialogActions)``;
