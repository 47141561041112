import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateField from './DateField/DateField';
import {
  FilterClearCustodiansButtonSD,
  FilterComponentSD,
  FilterRowSD,
  FilterSD,
  FilterTitleSD,
} from './styled';
import { Button, Grid, InputAdornment } from '@material-ui/core';
import { SearchFieldSD } from '../../../components/presentation/SearchField/styled';
import SearchIcon from '@material-ui/icons/Search';
import CustodiansFilter from './CustodiansFilter/CustodiansFilter';
import {
  clearSelectedCustodians,
  openCustodiansFilter,
  setDateRange,
  setSearch,
} from './store/actions';
import { AppDispatch } from '../../../store/configureStore';
import { RootState } from '../../../store/rootReducer';
import IconAddCircle from '../../../components/icons/IconAddCircle';

interface FilterSelectorProps {
  search: string;
  countSelectedCustodians: number;
  dateRange: string[];
}

function Filter() {
  const dispatch: AppDispatch = useDispatch();
  const { search, countSelectedCustodians, dateRange } = useSelector<
    RootState,
    FilterSelectorProps
  >((state: RootState) => {
    return {
      search: state.qualityEngineer.filter.search || '',
      countSelectedCustodians:
        state.qualityEngineer.filter.selectedCustodians.length,
      dateRange: state.qualityEngineer.filter.dateRange,
    };
  });

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch((event.target as HTMLInputElement).value));
  };

  const handleChangeDateField = (date: []) => {
    dispatch(setDateRange(date));
  };

  return (
    <FilterSD data-test="component-filter">
      <FilterRowSD>
        <FilterTitleSD>Filter:</FilterTitleSD>
        <FilterComponentSD>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Button
                data-test="button-custodians"
                color="primary"
                variant="contained"
                onClick={() => dispatch(openCustodiansFilter())}
              >
                Showing{' '}
                {countSelectedCustodians > 0 ? countSelectedCustodians : 'all'}{' '}
                custodians
              </Button>
            </Grid>
            <Grid item>
              {countSelectedCustodians > 0 && (
                <FilterClearCustodiansButtonSD
                  data-test="button-clear-custodians"
                  variant="text"
                  size="small"
                  startIcon={<IconAddCircle />}
                  onClick={() => dispatch(clearSelectedCustodians())}
                >
                  Clear filter
                </FilterClearCustodiansButtonSD>
              )}
            </Grid>
          </Grid>
        </FilterComponentSD>
      </FilterRowSD>
      <FilterRowSD>
        <FilterTitleSD>Search:</FilterTitleSD>
        <FilterComponentSD>
          <SearchFieldSD
            data-test="search-input"
            fullWidth
            placeholder="Custodian, recon. ID, or portfolio Nr."
            value={search}
            onChange={handleChangeSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FilterComponentSD>
      </FilterRowSD>
      <FilterRowSD>
        <FilterTitleSD>Date:</FilterTitleSD>
        <FilterComponentSD>
          <DateField onChange={handleChangeDateField} dateRange={dateRange} />
        </FilterComponentSD>
      </FilterRowSD>
      <CustodiansFilter />
    </FilterSD>
  );
}

export default Filter;
