import React from 'react';
import { FormControl, FormLabel, RadioGroup } from '@material-ui/core';
import RadioKit from '../../../../../components/kit/Fields/Radio/RadioKit';
import { RadioKitLabelSD } from '../../../../../components/kit/Fields/Radio/styled';
import { RadioStatusGroupControlLabelSD } from './styled';
import { RECONCILIATION_STATUSES } from '../../../constants/common';

export interface RadioStatusGroupProps {
  value: string | number;
  onChange: (value: string | number) => void;
}

function RadioStatusGroup(props: RadioStatusGroupProps) {
  const { value, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onChange(value !== 'all' ? +value : value);
  };

  return (
    <FormControl component="fieldset" data-test="component-radio-status-group">
      <FormLabel component="legend">Show custodians with</FormLabel>
      <RadioGroup
        aria-label="status"
        name="status"
        value={value}
        onChange={handleChange}
      >
        <RadioStatusGroupControlLabelSD
          data-test="control-label"
          value={RECONCILIATION_STATUSES.notProcessed}
          control={<RadioKit color="primary" />}
          label={
            <RadioKitLabelSD small>
              Broken reconciliations, not processed
            </RadioKitLabelSD>
          }
        />
        <RadioStatusGroupControlLabelSD
          data-test="control-label"
          value={RECONCILIATION_STATUSES.processed}
          control={<RadioKit color="primary" />}
          label={
            <RadioKitLabelSD small>
              Broken reconciliations, processed
            </RadioKitLabelSD>
          }
        />
        <RadioStatusGroupControlLabelSD
          data-test="control-label"
          value={RECONCILIATION_STATUSES.success}
          control={<RadioKit color="primary" />}
          label={
            <RadioKitLabelSD small>
              Successfully processed reconciliation breaks
            </RadioKitLabelSD>
          }
        />
        <RadioStatusGroupControlLabelSD
          data-test="control-label"
          value="all"
          control={<RadioKit color="primary" />}
          label={<RadioKitLabelSD small>All reconciliations</RadioKitLabelSD>}
        />
      </RadioGroup>
    </FormControl>
  );
}

export default RadioStatusGroup;
