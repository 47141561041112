import { AxiosInstance } from 'axios';

class ClientsApi {
  client: AxiosInstance;
  basePath: string;
  constructor(client: AxiosInstance) {
    this.client = client;
    this.basePath = `clients/`;
  }

  getAll(params?: { [key: string]: any }) {
    return this.client.get(`${this.basePath}`, { params: params });
  }

  getOne(clientId: string) {
    return this.client.get(`${this.basePath}${clientId}/`);
  }

  update(client: any) {
    const body = {
      customer_number: client.customerNumber,
      first_name: client.firstName,
      last_name: client.lastName,
      nickname: client.nickname,
      wealth_manager: client.wealthManager,
    };

    return this.client.put(`${this.basePath}${client.id}/`, body);
  }

  createClient(values: any) {
    return this.client.post(this.basePath, {
      goio_id: values.goioID,
      customer_number: values.customerNumber,
      first_name: values.firstName,
      last_name: values.lastName,
      nickname: values.nickname,
      wealth_manager: values.wealthManager,
    });
  }

  getGoioId() {
    return this.client.get(this.basePath + 'goid/');
  }
}

export default ClientsApi;
