import {
    ContentWrapper,
    PageHeading,
    PageHeadingCentered,
    WelcomeBox,
    WelcomeImage,
    WelcomeText
} from "./styled";
import Button from "@material-ui/core/Button";
import PersonAdd from "@material-ui/icons/PersonAdd";
import iconOnboardingImage from "../../../static/icons/onboarding-image.svg";
import React from "react";

interface WelcomePageProps {
    heading: string;
    welcomeHeading: string;
    welcomeText: string;
    buttonLabel: string;

    buttonAction(): any;
}

function WelcomePage(props: WelcomePageProps) {
    return (
        <ContentWrapper>
            <PageHeading>{props.heading}</PageHeading>
            <WelcomeBox>
                <PageHeadingCentered>{props.welcomeHeading}</PageHeadingCentered>
                <WelcomeText dangerouslySetInnerHTML={{__html: props.welcomeText}}/>
                <br />
                <br />
                <Button color="primary" variant="contained" startIcon={<PersonAdd />}
                        onClick={props.buttonAction}>{props.buttonLabel}</Button>
            </WelcomeBox>
            <WelcomeImage src={iconOnboardingImage} alt="img" />
        </ContentWrapper>
    );
}

export default WelcomePage;
