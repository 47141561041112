import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconVisibility(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 19.995 12.928">
      <path
        d="M356.821-299.185c-.135,0-.267-.008-.4-.02l-1.922,1.923a11.3,11.3,0,0,0,2.321.241,11.244,11.244,0,0,0,9.779-5.687,1.5,1.5,0,0,0,0-1.484,11.3,11.3,0,0,0-2.318-2.853l-3.2,3.2c.013.131.02.264.02.4A4.29,4.29,0,0,1,356.821-299.185Z"
        transform="translate(-346.821 309.971)"
      />
      <g transform="translate(-0.003 -0.002)">
        <circle
          cx="2.864"
          cy="2.864"
          r="2.864"
          transform="translate(7.136 3.58)"
        />
        <path d="M10,12.889a11.271,11.271,0,0,0,9.8-5.7,1.5,1.5,0,0,0,0-1.487A11.275,11.275,0,0,0,.2,5.7a1.5,1.5,0,0,0,0,1.487A11.271,11.271,0,0,0,10,12.889ZM10,2.148a4.3,4.3,0,1,1-4.3,4.3A4.3,4.3,0,0,1,10,2.148Z" />
      </g>
    </SvgIcon>
  );
}

export default IconVisibility;
