import styled from 'styled-components';
import { ONBOARDING_PORTFOLIO_STATUSES } from '../../constants/common';

export const PortfolioCardFullNameSD = styled.div`
  margin-bottom: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const PortfolioCardCustomerNumberSD = styled.div`
  font-size: 12px;
  line-height: 17px;
`;

export const PortfolioCardGOIOIDSD = styled.div`
  font-size: 12px;
  line-height: 17px;
`;

export const PortfolioCardCustodianSD = styled.div`
  margin-bottom: 5px;
`;

export const PortfolioCardPortfolioNumberSD = styled.div``;

interface PortfolioCardStatus {
  status: String;
}

export const PortfolioCardStatusSD = styled.div<PortfolioCardStatus>`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: ${getBGColorByStatus};
  }
`;

function getBGColorByStatus(props: any) {
  switch (props.status) {
    case ONBOARDING_PORTFOLIO_STATUSES.submitted:
      return props.theme.palette.primary[70];
    case ONBOARDING_PORTFOLIO_STATUSES.requested:
      return props.theme.palette.primary[40];
    case ONBOARDING_PORTFOLIO_STATUSES.activated:
      return props.theme.palette.secondary.main;
  }
}

export const PortfolioCardDataSD = styled.data`
  display: block;
  margin-bottom: 5px;
`;

export const PortfolioCardTimeSD = styled.time``;
