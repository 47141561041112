import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconCheckPassword(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 10">
      <path
        d="M305.7,885.1l-3-4a1,1,0,1,1,1.6-1.2l2.26,3.013,6.188-7.071a1,1,0,1,1,1.5,1.316l-7,8A1,1,0,0,1,305.7,885.1Z"
        transform="translate(-302.5 -875.5)"
      />
    </SvgIcon>
  );
}

export default IconCheckPassword;
