import styled, { DefaultTheme } from 'styled-components';
import { IconButton } from '@material-ui/core';

interface IconButtonSDProps {
  visible?: Number;
  theme: DefaultTheme;
}

export const PasswordFieldIconButtonSD = styled(IconButton)`
  color: ${({ theme, visible }: IconButtonSDProps) =>
    visible ? theme.palette.primary.light : theme.palette.primary[70]};

  svg {
    font-size: 1.25rem;
  }
`;
