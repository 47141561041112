import { INavItem } from '../../../app/types';

export const QE_NAV_ITEMS: INavItem[] = [
  {
    title: 'Reconciliation',
    to: '/',
    hover: '',
  },
  {
    title: 'Data Quality',
    to: '/data-quality',
    hover: '',
  },
  {
    title: 'Onboarding Package',
    to: '/onboarding-package',
    hover: '',
  },
  {
    title: 'Feedback',
    to: '/feedback',
    hover: '',
  },
];
