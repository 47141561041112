import styled from 'styled-components';

export const PaginationFormSD = styled.form`
  display: flex;
`;

export const PaginationFormInputSD = styled.input`
  width: 61px;
  margin-right: 4px;
  border: 1px solid ${({ theme }) => theme.palette.primary['70']};
  font: ${({ theme }) => theme.fontWeight.bold} 16px/22px OpenSans, sans-serif;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & {
    -moz-appearance: textfield;
  }
`;
