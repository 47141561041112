import styled, { css } from 'styled-components';
import {
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';

export const RouteQEOnboardingPackageSD = styled.div`
  padding-top: 45px;
`;

export const RouteQEOnboardingPackageContainerSD = styled.div`
  max-width: 1190px;
  padding: 0 15px 55px;
  margin: 0 auto;
`;

export const RouteQEOnboardingPackageBlankSD = styled.div`
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const RouteQEOnboardingPackageHeaderSD = styled(Grid)`
  margin-bottom: 30px;
`;

export const RouteQEOnboardingPackageTitleSD = styled(Typography)`
  margin-bottom: 30px;
`;

export const TableSD = styled(Table)``;

export const TableRowSD = styled(TableRow)`
  vertical-align: top;
`;

export const TableHeadCell = styled(
  ({ sortable, sortableActive, nowrap, ...rest }) => <TableCell {...rest} />
)`
  padding: 10px 25px;
  vertical-align: middle;
  font-size: 14px !important;

  ${({ sortableActive }) =>
    sortableActive &&
    css`
      font-weight: 600;
    `}

  ${({ sortable }) =>
    !sortable &&
    css`
      color: #707070 !important;
    `}

  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
    `}
`;
