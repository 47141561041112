import React from 'react';
import { TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import IconSort from '../../icons/IconSort';
import { EnhancedTableHeadCellSD } from './styled';
import { useQuery } from '../../../hooks/useQuery';

type Order = 'asc' | 'desc';

export interface IHeadCell {
  id: string;
  label: string;
  isSortable?: boolean;
  width?: string;
}

export interface IEnhancedTableHeadProps {
  cells: IHeadCell[];
  gutterBottom?: number;
}

function EnhancedTableHead(props: IEnhancedTableHeadProps) {
  const { cells, gutterBottom = 10 } = props;
  const [{ order, order_by }, setQuery] = useQuery();
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    const isAsc = order_by === property && order === 'asc';
    setQuery({
      order: isAsc ? 'desc' : 'asc',
      order_by: property,
    });
  };

  return (
    <TableHead data-test="enhanced-table-head">
      <TableRow>
        {cells.map(cell => (
          <EnhancedTableHeadCellSD
            key={cell.id}
            sortDirection={order_by === cell.id ? order : false}
            width={cell.width}
            data-test="cell"
          >
            {cell.isSortable ? (
              <TableSortLabel
                active={order_by === cell.id}
                direction={order_by === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
                IconComponent={IconSort}
              >
                {cell.label}
              </TableSortLabel>
            ) : (
              cell.label
            )}
          </EnhancedTableHeadCellSD>
        ))}
      </TableRow>
      <tr style={{ height: `${gutterBottom}px` }} data-test="gutter-bottom" />
    </TableHead>
  );
}

export default EnhancedTableHead;
