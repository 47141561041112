import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const EnhancedDateRangePickerSD = styled.div`
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;

export const EnhancedDateRangePickerFooterSD = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px 16px;
`;

export const EnhancedDateRangePickerTitleSD = styled.div`
  margin-right: 25px;
`;

export const EnhancedDateRangePickerCancelButtonSD = styled(Button)`
  margin-right: 20px;
`;
