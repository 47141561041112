import styled from 'styled-components';

export const SnackbarKitWrapImageSD = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
`;

export const SnackbarKitImageSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
`;
