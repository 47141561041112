import React from 'react';
import FilterButton from '../../../../components/presentation/FilterButton/FilterButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { Grid } from '@material-ui/core';
import {
  RECONCILIATION_STATUSES,
  STATUSES_FILTER_LABELS,
} from '../../constants/common';

interface SelectorProps {
  brokenNotProcessed: number;
  brokenProcessed: number;
  success: number;
  total: number;
}

export interface StatusesFilterProps {
  activeStatus: string | number;
  onChangeStatus: (status: number | string) => void;
}

function StatusesFilter(props: StatusesFilterProps) {
  const { activeStatus, onChangeStatus } = props;
  const { brokenNotProcessed, brokenProcessed, success, total } = useSelector<
    RootState,
    SelectorProps
  >((state: RootState) => {
    return {
      brokenNotProcessed:
        state.qualityEngineer.root.statistics.brokenNotProcessed,
      brokenProcessed: state.qualityEngineer.root.statistics.brokenProcessed,
      success: state.qualityEngineer.root.statistics.success,
      total: state.qualityEngineer.root.statistics.total,
    };
  });

  return (
    <Grid container spacing={2} data-test="component-statuses-filter">
      <Grid item xs={3}>
        <FilterButton
          data-test="button-broken-not-processed"
          isActive={activeStatus === RECONCILIATION_STATUSES.notProcessed}
          onClick={() => onChangeStatus(RECONCILIATION_STATUSES.notProcessed)}
          number={brokenNotProcessed}
          title={STATUSES_FILTER_LABELS[RECONCILIATION_STATUSES.notProcessed]}
        />
      </Grid>

      <Grid item xs={3}>
        <FilterButton
          data-test="button-broken-processed"
          isActive={activeStatus === RECONCILIATION_STATUSES.processed}
          onClick={() => onChangeStatus(RECONCILIATION_STATUSES.processed)}
          number={brokenProcessed}
          title={STATUSES_FILTER_LABELS[RECONCILIATION_STATUSES.processed]}
        />
      </Grid>

      <Grid item xs={3}>
        <FilterButton
          data-test="button-success-processed"
          isActive={activeStatus === RECONCILIATION_STATUSES.success}
          onClick={() => onChangeStatus(RECONCILIATION_STATUSES.success)}
          number={success}
          title={STATUSES_FILTER_LABELS[RECONCILIATION_STATUSES.success]}
        />
      </Grid>

      <Grid item xs={3}>
        <FilterButton
          data-test="button-total"
          isActive={activeStatus === 'total'}
          onClick={() => onChangeStatus('total')}
          number={total}
          title={STATUSES_FILTER_LABELS.total}
        />
      </Grid>
    </Grid>
  );
}

export default StatusesFilter;
