import React from 'react';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { StaticDateRangePicker, DateRange } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import moment from 'moment';
import {
  EnhancedDateRangePickerCancelButtonSD,
  EnhancedDateRangePickerFooterSD,
  EnhancedDateRangePickerSD,
  EnhancedDateRangePickerTitleSD,
} from './styled';

export interface IEnhancedDateRangePickerProps {
  onClose: () => void;
  onChange: Function;
  dateRange: string[];
}

function EnhancedDateRangePicker(props: IEnhancedDateRangePickerProps) {
  const {
    onClose,
    onChange,
    dateRange: [start, end],
  } = props;
  const [selectedDate, setSelectedDate] = React.useState<DateRange>([
    moment(start),
    moment(end),
  ]);
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <EnhancedDateRangePickerSD data-test="component-enhanced-date-range-picker">
        {/*
          // @ts-ignore */}
        <StaticDateRangePicker
          value={selectedDate}
          displayStaticWrapperAs="desktop"
          onChange={date => setSelectedDate(date)}
        />
        <EnhancedDateRangePickerFooterSD>
          {selectedDate[0] && selectedDate[1] && (
            <EnhancedDateRangePickerTitleSD data-test="title">
              {moment(selectedDate[0]).format('DD.MM.YYYY')} -{' '}
              {moment(selectedDate[1]).format('DD.MM.YYYY')}
            </EnhancedDateRangePickerTitleSD>
          )}
          <EnhancedDateRangePickerCancelButtonSD
            data-test="button-cancel"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </EnhancedDateRangePickerCancelButtonSD>
          <Button
            data-test="button-apply"
            color="primary"
            variant="contained"
            onClick={() => onChange(selectedDate)}
          >
            Apply
          </Button>
        </EnhancedDateRangePickerFooterSD>
      </EnhancedDateRangePickerSD>
    </LocalizationProvider>
  );
}

export default EnhancedDateRangePicker;
