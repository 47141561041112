import { useEffect, useState } from 'react';
import Api from '../../../../api/api';
import { propValueOr } from '../../../../helpers/common';

export interface IAccountType {
  value: string;
  name: string;
}


function useAccountType() {
  const [accountTypes, setAccountTypes] = useState<Array<IAccountType>>([]);

  useEffect(() => {
    Api.getAccountTypes()
      .then(response => {
        let data = propValueOr(response, 'data', []);
        setAccountTypes(data);
      })
      .catch(() => {});
  }, []);

  return accountTypes;
}

export { useAccountType };
