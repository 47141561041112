import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconClose(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 15">
      <path
        d="M568.136-145.9l3.535,3.535a.827.827,0,0,1,0,1.17l-3.535,3.535a.828.828,0,0,0,0,1.17l1.967,1.967a.827.827,0,0,0,1.17,0l3.535-3.535a.827.827,0,0,1,1.17,0l3.535,3.535a.828.828,0,0,0,1.17,0l1.967-1.967a.828.828,0,0,0,0-1.17l-3.536-3.535a.827.827,0,0,1,0-1.17l3.535-3.535a.828.828,0,0,0,0-1.17l-1.967-1.967a.827.827,0,0,0-1.17,0l-3.535,3.535a.827.827,0,0,1-1.17,0l-3.535-3.535a.827.827,0,0,0-1.17,0l-1.967,1.967A.828.828,0,0,0,568.136-145.9Z"
        transform="translate(-567.894 149.284)"
      />
    </SvgIcon>
  );
}

export default IconClose;
