import React from 'react';
import {
  FilterButtonNumberSD,
  FilterButtonSD,
  FilterButtonTitleSD,
} from './styled';

export interface IFilterButtonProps {
  number: Number;
  title: String;
  isActive?: Boolean;
  onClick: () => void;
}

function FilterButton(props: IFilterButtonProps) {
  const { number, title, isActive, onClick } = props;

  return (
    <FilterButtonSD
      data-test="component-filter-button"
      isActive={isActive}
      onClick={onClick}
    >
      <FilterButtonNumberSD data-test="number">{number}</FilterButtonNumberSD>
      <FilterButtonTitleSD data-test="title">{title}</FilterButtonTitleSD>
    </FilterButtonSD>
  );
}

export default FilterButton;
