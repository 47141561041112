import React from 'react';
import { InputAdornment } from '@material-ui/core';
import SearchType from './SearchType/SearchType';
import {
  SearchBarContentSD,
  SearchBarInputSD,
  SearchBarSD,
  SearchBarTitleSD,
} from './styled';
import SearchIcon from '@material-ui/icons/Search';
import { useQuery } from '../../../hooks/useQuery';

function SearchBar() {
  const [{ search = '', type = 'name' }, setQuery] = useQuery();

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery({ search: event.target.value, type: type });
  };

  return (
    <SearchBarSD data-test="component-search-bar">
      <SearchBarTitleSD>Search & filter:</SearchBarTitleSD>

      <SearchBarContentSD>
        <SearchBarInputSD
          type="search"
          data-test="search-input"
          value={search}
          onChange={handleChangeSearch}
          placeholder="Type in client name, custodian, or portfolio number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <SearchType
          value={type}
          onChange={(type: string) => setQuery({ type })}
          data-test="search-type"
        />
      </SearchBarContentSD>
    </SearchBarSD>
  );
}

export default SearchBar;
