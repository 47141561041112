export const PDF_VIEWER_ACTIONS = {
  setNumPages: 'PDF_VIEWER_ACTIONS / SET_NUM_PAGES',
  setPageNumber: 'PDF_VIEWER_ACTIONS / SET_PAGE_NUMBER',
  setScale: 'PDF_VIEWER_ACTIONS / SET_SCALE',
};

export interface PdfViewerState {
  numPages: number | null;
  pageNumber: number;
  scale: number;
}

interface SetNumPagesAction {
  type: typeof PDF_VIEWER_ACTIONS.setNumPages;
  payload: { pages: number };
}

interface SetPageNumberAction {
  type: typeof PDF_VIEWER_ACTIONS.setPageNumber;
  payload: { page: number };
}

interface SetScaleAction {
  type: typeof PDF_VIEWER_ACTIONS.setScale;
  payload: { scale: number };
}

export type PdfViewerTypes =
  | SetNumPagesAction
  | SetPageNumberAction
  | SetScaleAction;
