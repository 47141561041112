import React from 'react';
import {
  StatusBarKitSD,
  StatusBarKitTitleSD,
  StatusBarKitIconSD,
  StatusBarKitStepSubmittedSD,
  StatusBarKitTimeSD,
  StatusBarKitStepRequestedSD,
  StatusBarKitStepActivatedSD,
} from './styled';
import IconCheckCircle from '../../icons/IconCheckCircle';
import moment from 'moment-timezone';
import { STATUS_BAR_TIME_FORMAT } from './constants/common';

export interface IStatusBarKit {
  dataSubmitted?: String;
  dataRequested?: String;
  dataActivated?: String;
}

const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

function StatusBarKit(props: IStatusBarKit) {
  const { dataSubmitted, dataRequested, dataActivated } = props;

  return (
    <StatusBarKitSD data-test="component-status-bar">
      <StatusBarKitStepSubmittedSD isActive={!!dataSubmitted}>
        <StatusBarKitIconSD>
          <IconCheckCircle />
        </StatusBarKitIconSD>
        <StatusBarKitTitleSD data-test="step-submitted">
          Portfolio submitted
        </StatusBarKitTitleSD>
        {dataSubmitted && (
          <StatusBarKitTimeSD>
            {moment
              .tz(dataSubmitted, timeZoneName)
              .format(STATUS_BAR_TIME_FORMAT)}
          </StatusBarKitTimeSD>
        )}
      </StatusBarKitStepSubmittedSD>
      <StatusBarKitStepRequestedSD
        isActive={!!dataRequested}
        data-test="step-requested"
      >
        <StatusBarKitIconSD>
          <IconCheckCircle />
        </StatusBarKitIconSD>
        <StatusBarKitTitleSD>Data requested</StatusBarKitTitleSD>
        {dataRequested && (
          <StatusBarKitTimeSD>
            {moment
              .tz(dataRequested, timeZoneName)
              .format(STATUS_BAR_TIME_FORMAT)}
          </StatusBarKitTimeSD>
        )}
      </StatusBarKitStepRequestedSD>
      <StatusBarKitStepActivatedSD
        isActive={!!dataActivated}
        data-test="step-activated"
      >
        <StatusBarKitIconSD>
          <IconCheckCircle />
        </StatusBarKitIconSD>
        <StatusBarKitTitleSD>Account activated</StatusBarKitTitleSD>
        {dataActivated && (
          <StatusBarKitTimeSD>
            {moment
              .tz(dataActivated, timeZoneName)
              .format(STATUS_BAR_TIME_FORMAT)}
          </StatusBarKitTimeSD>
        )}
      </StatusBarKitStepActivatedSD>
    </StatusBarKitSD>
  );
}

export default StatusBarKit;
