export interface NotifierState {
  notifications: any[];
}

export const NOTIFIER_ACTIONS = {
  enqueueSnackbar: 'NOTIFIER_ACTIONS / ENQUEUE_SNACKBAR',
  removeSnackbar: 'NOTIFIER_ACTIONS / REMOVE_SNACKBAR',
};

interface EnqueueSnackbarAction {
  type: typeof NOTIFIER_ACTIONS.enqueueSnackbar;
  payload: {
    notification: object;
  };
}

interface RemoveSnackbarAction {
  type: typeof NOTIFIER_ACTIONS.removeSnackbar;
  payload: { key: number };
}

export type NotifierActionTypes = EnqueueSnackbarAction | RemoveSnackbarAction;
