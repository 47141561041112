import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconZoomOut(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M239.033,61.815a10,10,0,0,0,15.343,7.614.922.922,0,0,1,1.153.12l1.211,1.211a.8.8,0,0,0,.538.24A1.68,1.68,0,0,0,259,69.278a.8.8,0,0,0-.24-.538l-1.211-1.211a.922.922,0,0,1-.12-1.153,10,10,0,1,0-18.4-4.561Zm10.7-7.921a7.144,7.144,0,1,1-7.838,7.838A7.17,7.17,0,0,1,249.731,53.894Z"
        transform="translate(-239 -51)"
      />
      <rect
        width="7.153"
        height="2.143"
        rx="1.071"
        transform="translate(6.424 8.929)"
      />
    </SvgIcon>
  );
}

export default IconZoomOut;
