import styled from 'styled-components';
import { Card, CardMedia } from '@material-ui/core';

export const RouteNotFoundSD = styled('main')`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RouteNotFoundImageSD = styled(CardMedia)`
  height: 300px;
  background-size: contain;
`;

export const RouteNotFoundCardSD = styled(Card)`
  max-width: 500px;
`;
