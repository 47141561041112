import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const HeaderDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 5px #00000026;
  background-color: #ffffff;
`;

export const Wrapper = styled(Toolbar)`
  height: 72px;
`;

interface HeaderMenuLink {
  applyActive?: Boolean;
}

export const OnboardingLink = styled(NavLink)<HeaderMenuLink>`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
  font: bold 16px 'Open Sans', serif;
  text-transform: uppercase;
  color: #84cc59;
  text-decoration: none;
  margin-right: 30px;
  border-bottom: 4px solid transparent;

  &.active {
    color: #004b82;
    border-bottom-color: #004b82;
  }
  
  &:before {
    content: attr(data-hover);
    visibility: hidden;
    opacity: 0;
    width: 140px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    transition: opacity 1s ease-in-out;
  
    position: absolute;
    z-index: 1;
    top: 110%;
  }

  &:hover:before {
    opacity: 1;
    visibility: visible;
  }
`;

export const OnboardingStatusWrapLogoSD = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 964px) {
    display: none;
  }
`;

export const OnboardingStatusLogoSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

export const HeaderProfileWrapper = styled.div`
  margin-left: auto;
  height: 100%;
  display: flex;
  position: relative;
`;

export const HeaderProfile = styled.div`
  border-left: 1px solid #eaedf3;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HeaderProfileAvatar = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 5px;
  margin-left: 20px;
`;

export const HeaderProfileFullName = styled.h1`
  color: #84cc59;
  margin: 0;
  font: bold 16px 'Open Sans', serif;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderProfileDropdown = styled.div`
  position: absolute;
  display: block;
  top: 72px;
  background-color: #fff;
  width: 100%;
  padding: 10px 0;
  border: 1px solid #eaedf3;
`;

export const HeaderProfileDropdownButton = styled(IconButton)`
  color: #84cc59;
  margin-left: 10px;
`;

export const HeaderProfileDropdownIconClosed = styled(KeyboardArrowDownIcon)`
  font-size: 32px;
`;

export const HeaderProfileDropdownIconOpened = styled(KeyboardArrowUpIcon)`
  font-size: 32px;
`;
