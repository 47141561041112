import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuList from './MenuList/MenuList';
import { DATE_FIELD_MENU_LIST, DATE_PERIODS } from './constants/common';
import EnhancedDateRangePicker from './EnhancedDateRangePicker/EnhancedDateRangePicker';
import {
  DateFieldButtonPlaceholderSD,
  DateFieldButtonSD,
  DateFieldContentSD,
  DateFieldPopoverSD,
} from './styled';
import IconCalendar from '../../../../components/icons/IconCalendar';
import moment from 'moment';

interface State {
  anchorEl?: HTMLButtonElement | null;
  isPickerOpen?: boolean;
}

export const initialState: State = {
  anchorEl: null,
  isPickerOpen: false,
};

type Action =
  | {
      type: 'setAnchorEl';
      payload: { anchorEl: HTMLButtonElement | null };
    }
  | {
      type: 'setIsPickerOpen';
      payload: { value: boolean };
    };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setAnchorEl':
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
      };
    case 'setIsPickerOpen':
      return {
        ...state,
        isPickerOpen: action.payload.value,
      };
    default:
      return state;
  }
};

export interface IDateFieldProps {
  onChange: (data: any) => void;
  dateRange: string[];
}

function DateField(props: IDateFieldProps) {
  const [{ anchorEl, isPickerOpen }, dispatch] = React.useReducer(
    reducer,
    initialState
  );
  const { onChange, dateRange } = props;

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch({
      type: 'setAnchorEl',
      payload: { anchorEl: event.currentTarget },
    });
  };

  const handleClosePopover = () => {
    dispatch({ type: 'setAnchorEl', payload: { anchorEl: null } });
  };

  const handleClickMenuItem = (property: any) => {
    switch (property) {
      case DATE_PERIODS.range:
        dispatch({ type: 'setIsPickerOpen', payload: { value: true } });
        break;
      case DATE_PERIODS.today:
      case DATE_PERIODS.week:
      case DATE_PERIODS.month:
        onChange([
          moment()
            .startOf(property)
            .format('YYYY-MM-DD'),
          moment()
            .endOf(property)
            .format('YYYY-MM-DD'),
        ]);
        handleClosePopover();
        break;
      case DATE_PERIODS.all:
        onChange([]);
        handleClosePopover();
        break;
      default:
        handleClosePopover();
    }
  };

  const handleChangeDateRange = (date: any) => {
    const [m1, m2] = date;
    onChange([m1.format('YYYY-MM-DD'), m2.format('YYYY-MM-DD')]);
    handleClosePopover();
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <div data-test="component-date-field">
      <DateFieldButtonSD
        data-test="button"
        color="primary"
        variant="outlined"
        startIcon={<IconCalendar />}
        endIcon={<ExpandMoreIcon />}
        onClick={handleOpenPopover}
        active={anchorEl ? 1 : 0}
      >
        {dateRange.length === 0 ? (
          <DateFieldButtonPlaceholderSD>
            Pick a date
          </DateFieldButtonPlaceholderSD>
        ) : (
          `${dateRange[0]} - ${dateRange[1]}`
        )}
      </DateFieldButtonSD>
      <DateFieldPopoverSD
        onClose={handleClosePopover}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DateFieldContentSD>
          {isPickerOpen && (
            <EnhancedDateRangePicker
              onClose={handleClosePopover}
              onChange={handleChangeDateRange}
              dateRange={dateRange}
            />
          )}
          <MenuList
            list={DATE_FIELD_MENU_LIST}
            onClickItem={handleClickMenuItem}
          />
        </DateFieldContentSD>
      </DateFieldPopoverSD>
    </div>
  );
}

export default DateField;
