import React from 'react';
import { FormGroup, Grid } from '@material-ui/core';
import CheckboxKit from '../../../../../components/kit/Fields/Checkbox/CheckboxKit';
import { ICustodianFilter } from '../reducer/types';
import { CheckboxCustodiansGroupSD } from './styled';

export interface CheckboxCustodiansGroupProps {
  custodians: ICustodianFilter[];
  selectedCustodians: string[];
  onChange: (custodian: string) => void;
}

function CheckboxCustodiansGroup(props: CheckboxCustodiansGroupProps) {
  const { custodians, selectedCustodians, onChange } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.name);
  };

  return (
    <CheckboxCustodiansGroupSD
      component="fieldset"
      data-test="component-checkbox-custodians-group"
    >
      <FormGroup>
        <Grid container spacing={2}>
          {custodians.map(c => (
            <Grid item key={c.custodian} xs={3}>
              <CheckboxKit
                data-test="checkbox"
                label={c.custodian}
                additionalLabel={`(${c.count})`}
                checked={selectedCustodians.includes(c.custodian)}
                name={c.custodian}
                onChange={handleChange}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </CheckboxCustodiansGroupSD>
  );
}

export default CheckboxCustodiansGroup;
