import { DefaultTheme } from 'styled-components';

const GOIOTheme: DefaultTheme = {
  colors: {
    primary: '#004B82',
    secondary: '#84CC59',
    textGray: '#707070',
    textLightGrey: '#C9C9C9',
    lineGrey: '#EAEDF3',
  },
  palette: {
    primary: {
      dark: '#003C68',
      main: '#004B82',
      light: '#336F9B',
      '40': '#6693B4',
      '70': '#B3C9DA',
      '90': '#E6EDF3',
    },
    secondary: {
      dark: '#6AA347',
      main: '#84CC59',
      light: '#9DD67A',
      '40': '#B5E09B',
      '70': '#DAF0CD',
      '90': '#F3FAEE',
    },
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 600,
    extraBold: 700,
  },
};

export { GOIOTheme };
