import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

interface EnhancedTableHeadCellProps {
  width?: string;
}

export const EnhancedTableHeadCellSD = styled(TableCell)<
  EnhancedTableHeadCellProps
>`
  ${({ width }) => width && `width: ${width}`}
`;
