import { IHeadCell } from '../../../../../components/presentation/EnhancedTableHead/EnhancedTableHead';
import { RECONCILIATION_STATUSES } from '../../../constants/common';

export const ReconciliationTableHeadCells: IHeadCell[] = [
  {
    id: 'custodian',
    label: 'Custodian',
    isSortable: true,
  },
  {
    id: 'id',
    label: 'Reconciliation ID',
  },
  {
    id: 'number',
    label: 'Portfolio number',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'updated',
    label: 'Updated on',
    isSortable: true,
  },
];

export const RECONCILIATION_STATUS_LABELS = {
  [RECONCILIATION_STATUSES.success]: 'Successfully processed',
  [RECONCILIATION_STATUSES.processed]: 'Break - processed',
  [RECONCILIATION_STATUSES.notProcessed]: 'Break - not processed',
};
