import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconEdit(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 13 13">
      <path
        d="M333.37-377.564l3.761-.75a.309.309,0,0,0,.158-.522l-3.011-3.011a.31.31,0,0,0-.522.158l-.75,3.761A.309.309,0,0,0,333.37-377.564Z"
        transform="translate(-333 390.558)"
      />
      <path
        d="M372.46-419a3.64,3.64,0,0,0-1.958.531.563.563,0,0,0-.094.876l2.764,2.764a.568.568,0,0,0,.891-.117,3.642,3.642,0,0,0,.516-1.867v0A2.176,2.176,0,0,0,372.46-419Z"
        transform="translate(-361.58 419)"
      />
      <path
        d="M345.388-401.338a1,1,0,0,0,1.412,0l4.376-4.386a.6.6,0,0,0,0-.849l-2.875-2.874a.6.6,0,0,0-.85,0l-4.381,4.381a1,1,0,0,0,0,1.411Z"
        transform="translate(-340.516 411.793)"
      />
    </SvgIcon>
  );
}

export default IconEdit;
