import React from 'react';
import {
  PortfolioTableBodySD,
  PortfolioTableHeaderSD,
  PortfolioTableSD,
  PortfolioTableRowSD,
  PortfolioTableColSD,
  PortfolioTableHeaderTitleSD,
  PortfolioTableEllipsisTextSD,
} from './styled';

export interface PortfolioDataColumn {
  renderItem?: Function;
  propName: string;
  title?: string;
  innerWidth?: any;
}

interface PortfolioTableProps {
  columns: Array<PortfolioDataColumn>;
  data: Array<any>;
}

function PortfolioTable({ data, columns }: PortfolioTableProps) {
  return (
    <PortfolioTableSD>
      <PortfolioTableHeaderSD>
        <PortfolioTableRowSD>
          {columns.map(column => (
            <PortfolioTableColSD
              key={column.propName}
              innerWidth={column.innerWidth}
            >
              <PortfolioTableHeaderTitleSD>
                {column.title}
              </PortfolioTableHeaderTitleSD>
            </PortfolioTableColSD>
          ))}
        </PortfolioTableRowSD>
      </PortfolioTableHeaderSD>
      <PortfolioTableBodySD>
        {data.map((dataRow, rowIndex) => {
          return (
            <PortfolioTableRowSD key={rowIndex + `${new Date().getTime()}`}>
              {columns.map(column => (
                <PortfolioTableColSD
                  key={column.propName}
                  innerWidth={column.innerWidth}
                >
                  {typeof column.renderItem === 'function' ? (
                    column.renderItem(dataRow)
                  ) : (
                    <PortfolioTableEllipsisTextSD
                      title={dataRow[column.propName]}
                    >
                      {dataRow[column.propName]} {dataRow['status'] == 'inactive' && <b>  --inactive--</b>}
                    </PortfolioTableEllipsisTextSD>
                  )}
                </PortfolioTableColSD>
              ))}
            </PortfolioTableRowSD>
          );
        })}
      </PortfolioTableBodySD>
    </PortfolioTableSD>
  );
}

export default PortfolioTable;
