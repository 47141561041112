import {POLICIES_ACTIONS, PoliciesActionTypes} from "./types";
import {Reducer} from "redux";

export interface IPolicyState {
  policies: any,
  changed: boolean,
  search: string,
  selectedPolicies: any;
}

export const initialState: IPolicyState = {
  policies: [],
  changed: false,
  search: '',
  selectedPolicies: [],
};

const reducer: Reducer<IPolicyState> = (
  state = initialState,
  action: PoliciesActionTypes
): IPolicyState => {
  switch (action.type) {
    case POLICIES_ACTIONS.setPolicies:
      if (action.payload.policies){
        return {
          ...state,
          // @ts-ignore
          policies: action.payload.policies,
        };
      } else {
        return {
          ...state,
          // @ts-ignore
          selectedPolicies: action.payload.selectedPolicies,
        };
      }

    case POLICIES_ACTIONS.setPoliciesSearch:
      return {
        ...state,
        // @ts-ignore
        search: action.payload.search,
      };
    case POLICIES_ACTIONS.togglePolicy:
      return {
        ...state,
        // @ts-ignore
        policies: (() => {
          // @ts-ignore
          let policyIndex = state.policies.findIndex((obj => obj.id.toString() === action.payload.policyID));
          if (state.policies[policyIndex]){
            state.policies[policyIndex].own = !state.policies[policyIndex].own
          }
          return state.policies
        })(),
        selectedPolicies: (() => {
          // @ts-ignore
          let policyIndex = state.selectedPolicies.findIndex((obj => obj.id.toString() === action.payload.policyID));
          if (state.selectedPolicies[policyIndex]) {
              state.selectedPolicies[policyIndex].own = !state.selectedPolicies[policyIndex].own
          }
          return state.selectedPolicies
        })(),
      };
    case POLICIES_ACTIONS.setPolicyValue:
      return {
        ...state,
        policies: (() => {
          // @ts-ignore
          let policyIndex = state.policies.findIndex((obj => obj.id.toString() === action.payload.policyID.toString()));
          if (state.policies[policyIndex]){
            state.policies[policyIndex].value = action.payload.value
          }
          return state.policies
        })(),
        selectedPolicies: (() => {
          // @ts-ignore
          let policyIndex = state.selectedPolicies.findIndex((obj => obj.id.toString() === action.payload.policyID.toString()));
          if (state.selectedPolicies[policyIndex]) {
            state.selectedPolicies[policyIndex].value = action.payload.value
          }
          return state.selectedPolicies
        })(),
      }
    case POLICIES_ACTIONS.clearPoliciesSearch:
      return {
        ...state,
        // @ts-ignore
        search: '',
      };
    default:
      return state;
  }
}

export default reducer;
