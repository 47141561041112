import styled, { css } from 'styled-components';
import { Table, TableCell, TableRow } from '@material-ui/core';

export const PortfolioTableSD = styled(Table)``;

interface PortfolioTableRowProps {
  expanded?: number;
}

export const PortfolioTableRowSD = styled(TableRow)<PortfolioTableRowProps>`
  ${({ expanded }) =>
    expanded &&
    css`
      border-bottom: 0;
    `}
`;

export const PortfolioTableCellSD = styled(TableCell)`
  width: 25%;
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px Open Sans, Arial;
  vertical-align: top;
`;
