import { AppDispatch } from '../../../store/configureStore';
import { QUALITY_ENGINEER_ACTIONS, IReconciliation } from './types';
import Api from '../../../api/api';
import _ from 'lodash';
import { ReconciliationsParams } from '../../../api/reconciliations/reconciliationsApi';

export const getReconsiliations = (params: ReconciliationsParams): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: QUALITY_ENGINEER_ACTIONS.reconsiliations.request,
  });

  return Api.reconsiliations
    .getAll(params)
    .then(response => {
      dispatch({
        type: QUALITY_ENGINEER_ACTIONS.reconsiliations.success,
        payload: {
          total: _.get(response, 'data.count'),
          reconsiliations: _.get(response, 'data.results', []).map(
            transformReconciliation
          ),
        },
      });

      return response;
    })
    .catch(err => {
      dispatch({
        type: QUALITY_ENGINEER_ACTIONS.reconsiliations.error,
      });

      return err;
    });
};

export const getReconsiliationsStatistics = (): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: QUALITY_ENGINEER_ACTIONS.statistics.request,
  });

  return Api.reconsiliations
    .getStatistics()
    .then(response => {
      dispatch({
        type: QUALITY_ENGINEER_ACTIONS.statistics.success,
        payload: {
          statistics: transformReconciliationStatistics(response.data),
        },
      });

      return response;
    })
    .catch(err => {
      dispatch({
        type: QUALITY_ENGINEER_ACTIONS.statistics.error,
      });
      return err;
    });
};

export const transformReconciliation = (data: any): IReconciliation => ({
  id: data.id,
  status: data.status,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
  portfolio: {
    id: data.portfolio.id,
    custodian: data.portfolio.custodian,
    portfolioNumber: data.portfolio.portfolio_number,
  },
});

export const transformReconciliationStatistics = (data: any) => ({
  brokenNotProcessed: data.broken_not_processed,
  brokenProcessed: data.broken_processed,
  success: data.success_processed,
  total: data.total,
});
