import React from 'react';
import _ from 'lodash';

import iconLogo from '../../../static/icons/logo.svg';
import iconUserAvatar from '../../../static/icons/user_icon.svg';

import {
  HeaderDiv,
  HeaderProfile,
  HeaderProfileAvatar,
  HeaderProfileDropdown,
  HeaderProfileDropdownButton,
  HeaderProfileDropdownIconClosed,
  HeaderProfileDropdownIconOpened,
  HeaderProfileFullName,
  HeaderProfileWrapper,
  OnboardingLink,
  OnboardingStatusLogoSD,
  OnboardingStatusWrapLogoSD,
  Wrapper,
} from './styled';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { AppDispatch } from '../../../store/configureStore';
import { logoutAction } from '../../../store/commonStates/user/actions';
import { INavItem } from '../../../app/types';

interface AppRoutingProps {
  firstName: string;
  lastName: string;
}

export interface IHeader {
  navItems: Array<INavItem>;
}

function Header({ navItems = [] }: IHeader) {
  const [open, setOpen] = React.useState<Boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const { firstName, lastName } = useSelector<RootState, AppRoutingProps>(
    (state: RootState) => {
      return {
        firstName: _.get(state, 'user.userInfo.firstName'),
        lastName: _.get(state, 'user.userInfo.lastName'),
      };
    }
  );
  const fullName = `${firstName} ${lastName}`;

  return (
    <HeaderDiv>
      <Wrapper>
        <OnboardingStatusWrapLogoSD>
          <OnboardingStatusLogoSD src={iconLogo} alt={'GOIO DATA'} />
        </OnboardingStatusWrapLogoSD>
        {navItems.map(item => (
          <OnboardingLink key={item.to} to={item.to} data-hover={item.hover} exact>
            {item.title}
          </OnboardingLink>
        ))}
        <HeaderProfileWrapper>
          <HeaderProfile data-test="component-header-profile">
            <HeaderProfileAvatar src={iconUserAvatar} />
            <div>
              <HeaderProfileFullName data-test="full-name">
                {fullName}
              </HeaderProfileFullName>
              {/*<HeaderProfileJob>{company}</HeaderProfileJob>*/}
            </div>
            <HeaderProfileDropdownButton
              onClick={() => {
                setOpen(!open);
              }}
              data-test="dropdown-button"
            >
              {open ? (
                <HeaderProfileDropdownIconOpened />
              ) : (
                <HeaderProfileDropdownIconClosed />
              )}
            </HeaderProfileDropdownButton>
          </HeaderProfile>
          {open ? (
            <HeaderProfileDropdown data-test="dropdown">
              <Button
                fullWidth={true}
                onClick={() => {
                  dispatch(logoutAction());
                }}
                data-test="button-logout"
              >
                Logout
              </Button>
            </HeaderProfileDropdown>
          ) : null}
        </HeaderProfileWrapper>
      </Wrapper>
    </HeaderDiv>
  );
}

export default Header;
