import styled from 'styled-components';

export const ReconciliationInfoSD = styled.div`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px Open Sans Arial,
    sans-serif;
`;

export const ReconciliationInfoHeaderSD = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
  margin-bottom: 10px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.lineGrey};
`;

export const ReconciliationInfoItemSD = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const ReconciliationInfoTitleSD = styled.div`
  margin-right: 5px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ReconciliationInfoValueSD = styled.div``;
