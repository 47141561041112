import { PdfViewerTypes, PdfViewerState, PDF_VIEWER_ACTIONS } from './types';

export const initialState: PdfViewerState = {
  numPages: null,
  pageNumber: 1,
  scale: 0.9,
};

export default (
  state = initialState,
  action: PdfViewerTypes
): PdfViewerState => {
  switch (action.type) {
    case PDF_VIEWER_ACTIONS.setNumPages:
      return {
        ...state,
        // @ts-ignore
        numPages: action.payload.pages,
      };
    case PDF_VIEWER_ACTIONS.setPageNumber:
      return {
        ...state,
        // @ts-ignore
        pageNumber: action.payload.page,
      };
    case PDF_VIEWER_ACTIONS.setScale:
      return {
        ...state,
        // @ts-ignore
        scale: action.payload.scale,
      };
    default:
      return state;
  }
};
