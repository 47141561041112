import { USER_ACTIONS, UserActionTypes } from './types';
import _ from 'lodash';
import Api from '../../../api/api';
import { AppDispatch } from '../../configureStore';
import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from '../../../components/smart/Notifier/store/actions';

export const loginAction = (values: {
  password: string;
  email: string;
}): any => (dispatch: AppDispatch) => {
  dispatch({ type: USER_ACTIONS.login.request });

  return Api.login(values)
    .then((resp: AxiosResponse) => {
      Api.setTokens(resp.data.access, resp.data.refresh);

      dispatch(actionGetUserInfo());
      dispatch({ type: USER_ACTIONS.login.success });

      return resp;
    })
    .catch(err => {
      dispatch({ type: USER_ACTIONS.login.error });
      dispatch(
        enqueueSnackbar({
          message: 'Incorrect E-Mail-or Password!',
          options: {
            variant: 'error',
          },
        })
      );
      throw err;
    });
};

// export const registrationAction = (values: {
//   password: string;
//   email: string;
//   company_id: string;
// }): any => (dispatch: AppDispatch) => {
//   // dispatch({ type: USER_ACTIONS.registration.request });
//
//   return Api.registration(values)
//     .then((resp: AxiosResponse) => {
//       history.push(`/login`);
//     })
//     .catch(err => {
//         console.log('err', err)
//       // dispatch({ type: USER_ACTIONS.registration.error });
//       dispatch(
//         enqueueSnackbar({
//           message: 'Some of the data are Incorrect!',
//           options: {
//             variant: 'error',
//           },
//         })
//       );
//       throw err;
//     });
// };

export const logoutAction = (): any => (dispatch: AppDispatch) => {
  return Api.logout();
};

export const setAuthorizedAction = (): UserActionTypes => ({
  type: USER_ACTIONS.update.authorize,
});

export const actionGetUserInfo = (): any => async (dispatch: AppDispatch) => {
  const res = await Api.userInfo();
  const data = _.get(res, 'data', {});
    dispatch({
      type: USER_ACTIONS.update.userInfo,
      payload: {
        userInfo: {
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          role: data.role,
        },
      },
    });
};
