import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconUserDefault(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <g transform="translate(-324 427.949)">
        <ellipse
          cx="5.136"
          cy="5.502"
          rx="5.136"
          ry="5.502"
          transform="translate(338.846 -418.949)"
        />
        <path
          d="M352.978-384.961A19.267,19.267,0,0,0,344-387a19.267,19.267,0,0,0-8.978,2.039A3.909,3.909,0,0,0,333-381.507h0a3.951,3.951,0,0,0,3.947,3.955h14.106A3.951,3.951,0,0,0,355-381.507h0A3.909,3.909,0,0,0,352.978-384.961Z"
          transform="translate(0 -19.398)"
        />
      </g>
    </SvgIcon>
  );
}

export default IconUserDefault;
