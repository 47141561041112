import {
  QualityEngineerState,
  QualityEngineerActionTypes,
  QUALITY_ENGINEER_ACTIONS,
} from './types';

export const initialQualityEngineerState: QualityEngineerState = {
  isReconsiliationsFetching: false,
  reconsiliations: [],
  total: null,

  isStatisticsFetching: false,
  statistics: {
    brokenNotProcessed: 0,
    brokenProcessed: 0,
    success: 0,
    total: 0,
  },
};

const reducer = (
  state = initialQualityEngineerState,
  action: QualityEngineerActionTypes
): QualityEngineerState => {
  switch (action.type) {
    case QUALITY_ENGINEER_ACTIONS.reconsiliations.request:
      return {
        ...state,
        isReconsiliationsFetching: true,
      };
    case QUALITY_ENGINEER_ACTIONS.reconsiliations.success:
      return {
        ...state,
        isReconsiliationsFetching: false,
        // @ts-ignore
        total: action.payload.total,
        // @ts-ignore
        reconsiliations: action.payload.reconsiliations,
      };
    case QUALITY_ENGINEER_ACTIONS.reconsiliations.error:
      return {
        ...state,
        isReconsiliationsFetching: false,
      };
    case QUALITY_ENGINEER_ACTIONS.statistics.request:
      return {
        ...state,
        isStatisticsFetching: true,
      };
    case QUALITY_ENGINEER_ACTIONS.statistics.success:
      return {
        ...state,
        isStatisticsFetching: false,
        // @ts-ignore
        statistics: action.payload.statistics,
      };
    case QUALITY_ENGINEER_ACTIONS.statistics.error:
      return {
        ...state,
        isStatisticsFetching: false,
      };
    default:
      return state;
  }
};

export default reducer;
