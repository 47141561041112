import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';

export const RouteQEReconciliationBreaksSD = styled.div`
  padding-top: 45px;
`;

export const RouteQEReconciliationBreaksContainerSD = styled.div`
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const RouteQEReconciliationBreaksContentSD = styled.div`
  padding-bottom: 55px;
`;

export const RouteQEReconciliationBreaksHeaderSD = styled(Grid)`
  margin-bottom: 30px;
`;

export const RouteQEReconciliationBreaksFilterSD = styled(Grid)``;

export const RouteQEReconciliationBreaksTitleSD = styled(Typography)`
  margin-bottom: 30px;
`;

export const RouteQEReconciliationBreaksTableSD = styled.div`
  margin-bottom: 40px;
`;
