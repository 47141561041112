import React, { useState } from 'react';
import {
  PortfolioTableCellSD,
  PortfolioTableSD,
  PortfolioTableRowSD,
} from './styled';
import { Grid, TableBody, Button, Collapse } from '@material-ui/core';
import { useQuery } from '../../../hooks/useQuery';
import {
  PortfolioCardCustodianSD,
  PortfolioCardCustomerNumberSD,
  PortfolioCardDataSD,
  PortfolioCardFullNameSD,
  PortfolioCardGOIOIDSD,
  PortfolioCardPortfolioNumberSD,
  PortfolioCardStatusSD,
  PortfolioCardTimeSD,
} from './PortfolioCard/styled';
import moment from 'moment';
import { KeyboardArrowDown } from '@material-ui/icons';
import {
  ONBOARDING_STATUS_HEAD_CELLS,
  STATUS_LABELS,
} from '../constants/common';
import CollapsedRow from './CollapsedRow/CollapsedRow';
import EnhancedTableHead from '../../../components/presentation/EnhancedTableHead/EnhancedTableHead';

export interface IPortfolioTable {
  portfolios: Array<any>;
}

function PortfoliosTable({ portfolios }: IPortfolioTable) {
  const [{ order, order_by }, setQuery] = useQuery();
  const [expandIds, setExpandIds] = useState<{ [key: string]: any }>([]);

  const showMore = (id: any) => {
    // @ts-ignore
    setExpandIds(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleRequestSort = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    const isAsc = order_by === property && order === 'asc';
    setQuery({
      order: isAsc ? 'desc' : 'asc',
      order_by: property,
    });
  };

  return (
    <PortfolioTableSD
      aria-label="portfolios table"
      stickyHeader
      data-test="component-portfolio-table"
    >
      <EnhancedTableHead cells={ONBOARDING_STATUS_HEAD_CELLS} />
      <TableBody>
        {portfolios.map((p: any) => {
          return (
            <React.Fragment key={p.id}>
              <PortfolioTableRowSD expanded={expandIds[p.id] ? 1 : 0}>
                <PortfolioTableCellSD>
                  <PortfolioCardFullNameSD data-test="full-name">
                    {p.accountHolder.firstName} {p.accountHolder.lastName}
                  </PortfolioCardFullNameSD>
                  <Grid container spacing={2}>
                    <Grid item>
                      <PortfolioCardCustomerNumberSD data-test="customer-number">
                        Client ID: {p.accountHolder.customerNumber}
                      </PortfolioCardCustomerNumberSD>
                    </Grid>
                    <Grid item>
                      <PortfolioCardGOIOIDSD data-test="goio-id">
                        GOIO ID : {p.accountHolder.goioID}
                      </PortfolioCardGOIOIDSD>
                    </Grid>
                  </Grid>
                </PortfolioTableCellSD>

                <PortfolioTableCellSD>
                  <PortfolioCardCustodianSD data-test="custodian">
                    {p.custodian.name}
                  </PortfolioCardCustodianSD>
                  <PortfolioCardPortfolioNumberSD data-test="portfolio-number">
                    {p.portfolioNumber}
                  </PortfolioCardPortfolioNumberSD>
                </PortfolioTableCellSD>

                <PortfolioTableCellSD>
                  <PortfolioCardStatusSD status={p.status} data-test="status">
                    {STATUS_LABELS[p.status]}
                  </PortfolioCardStatusSD>
                </PortfolioTableCellSD>

                <PortfolioTableCellSD>
                  <Grid container justify="space-between">
                    <Grid item>
                      <PortfolioCardDataSD>
                        {moment(p.updated).format('DD.MM.YYYY')}
                      </PortfolioCardDataSD>
                      <PortfolioCardTimeSD>
                        {moment(p.updated).format('HH:MM')}
                      </PortfolioCardTimeSD>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        size="small"
                        variant="outlined"
                        endIcon={<KeyboardArrowDown />}
                        data-test="button-details"
                        onClick={() => showMore(p.id)}
                      >
                        View details
                      </Button>
                    </Grid>
                  </Grid>
                </PortfolioTableCellSD>
              </PortfolioTableRowSD>
              <Collapse
                in={expandIds[p.id]}
                timeout="auto"
                component={() => <CollapsedRow portfolio={p} />}
                unmountOnExit
              />
              <tr style={{ height: '5px' }} />
            </React.Fragment>
          );
        })}
      </TableBody>
    </PortfolioTableSD>
  );
}

export default PortfoliosTable;
