import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const RouteEditPortfolioSD = styled.div`
  max-width: 1300px;
  padding: 30px 15px 80px;
  margin: 0 auto;
`;

export const RouteEditPortfolioLinkBackSD: any = styled(Button)`
  margin-bottom: 30px;
`;
