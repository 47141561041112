import styled, { css } from 'styled-components';
import { Popover, PopoverProps, Button } from '@material-ui/core';

export const DateFieldSD = styled.div``;

interface DateFieldButtonProps {
  active?: number;
}

export const DateFieldButtonSD = styled(Button)<DateFieldButtonProps>`
  display: flex;

  min-width: 250px;
  border-color: ${({ theme }) => theme.palette.primary[70]};
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;

  .MuiButton-endIcon {
    margin-left: auto;
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    color: ${({ theme }) => theme.palette.primary[70]};
  }

  ${({ active, theme }) =>
    !!active &&
    css`
      border-color: ${theme.palette.secondary.main};

      .MuiButton-startIcon,
      .MuiButton-endIcon {
        color: ${theme.palette.secondary.main};
      }
    `}

  &:hover {
    border-color: inherit;
    color: inherit;
  }
`;

export const DateFieldButtonPlaceholderSD = styled.span`
  color: ${({ theme }) => theme.colors.textLightGrey};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;
`;

export const DateFieldPopoverSD = styled(Popover)<PopoverProps>`
  .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
    overflow: initial;
  }
`;

export const DateFieldContentSD = styled.div`
  display: flex;
  align-items: flex-start;
`;
