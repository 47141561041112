import Api from '../../../api/api';
import { AppDispatch } from '../../configureStore';
import { AxiosResponse } from 'axios';

export const createClient = (values: any): any => (dispatch: AppDispatch) => {
  return Api.clients
    .createClient(values)
    .then((resp: AxiosResponse) => {
      return resp;
    })
    .catch((err: any) => {
      throw err;
    });
};

export const getGoioId = (): any => (dispatch: AppDispatch) => {
  return Api.clients
    ?.getGoioId()
    .then((resp: AxiosResponse) => {
      return resp;
    })
    .catch((err: any) => {
      throw err;
    });
};
