import { createMuiTheme } from '@material-ui/core';
import { HEADER_HEIGHT } from '../constants/common';

export default createMuiTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#84CC59',
      light: '#AED37B',
      dark: '#6AA347',
      contrastText: '#fff',
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      dark: '#003C68',
      main: '#004B82',
      light: '#336F9B',
      contrastText: '#fff',
    },
    error: {
      main: '#EA3349',
      light: '#EA3349',
      dark: '#EA3349',
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },
      },
    },
    // Style sheet name
    MuiButton: {
      // Name of the rule
      root: {
        borderRadius: 0,
        textTransform: 'none',
        padding: '10px 20px 8px',
      },

      textPrimary: {
        color: '#84CC59',
        font: '400 16px/22px Open Sans, sans-serif',
        '&:hover': {
          color: '#6AA347',
          backgroundColor: 'transparent',
        },
      },

      containedPrimary: {
        backgroundColor: '#84CC59',
        background:
          'transparent linear-gradient(103deg, #AED37B 0%, #78B454 100%) 0% 0% no-repeat padding-box',
        boxShadow: '2px 2px 10px #0000001A',
        font: '400 16px/22px Open Sans, sans-serif',
        '&:hover': {
          backgroundColor: '#84CC59',
          background:
            'transparent linear-gradient(103deg, #AED37B 0%, #78B454 100%) 0% 0% no-repeat padding-box',
          boxShadow: '2px 2px 10px #00000033',
        },
        '&:active': {
          backgroundColor: '#6AA347',
          background: 'initial',
        },
      },

      outlinedPrimary: {
        padding: '9px 20px 7px',
        font: '400 16px/22px Open Sans, sans-serif',
        '&:hover': {
          color: '#6AA347',
          borderColor: '#6AA347',
          backgroundColor: 'transparent',
        },
      },

      sizeSmall: {
        font: '700 14px/19px Open Sans, sans-serif',
        padding: '7px 10px',
      },

      endIcon: {
        marginLeft: 5,
      },

      startIcon: {
        marginRight: 5,
      },

      iconSizeSmall: {
        '& > *:first-child': {
          fontSize: '13px',
        },
      },
    },

    // Behavior for override snackbar https://www.gitmemory.com/iamhosseindhv
    MuiSnackbarContent: {
      root: {
        width: 450,
        '&[class*="variantError"]': {
          backgroundColor: '#EA3349',
        },
        '&[class*="variantSuccess"]': {
          backgroundColor: '#707070',
        },
      },
    },

    MuiFilledInput: {
      root: {
        backgroundColor: '#FBFBFD',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: '#FBFBFD',
        },
        '&.Mui-disabled': {
          backgroundColor: '#FBFBFD',
        },
        '&.Mui-focused': {
          backgroundColor: '#FBFBFD',
        },
      },
      underline: {
        '&:before': {
          borderBottom: '2px solid #707070',
        },
        '&.Mui-disabled:before': {
          borderBottom: '2px solid #C9C9C9',
        },
        '&:hover:before': {
          borderBottom: '2px solid #707070',
        },
      },
      input: {
        padding: '28px 14px 12px',
        color: '#707070',
        fontSize: 16,
        lineHeight: '22px',
        fontFamily: 'Open Sans, sans-serif',
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
        '&:-webkit-autofill:hover': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
        '&:-webkit-autofill:focus': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
        '&:-webkit-autofill:active': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },

    MuiInputLabel: {
      filled: {
        color: '#C9C9C9',
        font: '600 16px/22px Open Sans',
        '&.MuiInputLabel-shrink': {
          font: '600 12px/17px Open Sans, sans-serif',
          transform: 'translate(14px, 6px)',
        },
      },
      shrink: {
        '&:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)': {
          color: '#6693B4',
        },
        '&:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error) + .MuiFilledInput-root:before': {
          borderColor: '#6693B4',
        },
      },
    },

    MuiFormHelperText: {
      root: {
        color: '#707070',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '12px',
        lineHeight: '17px',
        letterSpacing: 0,
      },
    },

    MuiInputAdornment: {
      root: {
        marginTop: 8,
      },
    },

    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
    },

    MuiDialog: {
      paper: {
        boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.16)',
        borderRadius: 0,
      },
      paperWidthSm: {
        maxWidth: '572px',
        padding: '50px 50px 25px',
      },
      paperWidthMd: {
        maxWidth: '768px',
        padding: '45px 98px 50px',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: 0,
        marginBottom: '30px',
        '&> h2': {
          font: '400 28px/38px "Open Sans", sans-serif',
        },
      },
    },

    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },

    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },

    MuiTypography: {
      root: {
        color: '#707070',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 1.375,
      },
      h1: {
        fontSize: 42,
        lineHeight: 1.3571,
        fontWeight: 300,
      },
      h2: {
        fontSize: 28,
        lineHeight: 1.3571,
        fontWeight: 400,
      },
      h3: {
        fontSize: 20,
        lineHeight: 1.35,
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: 16,
        lineHeight: 1.375,
      },
      colorPrimary: {
        color: '#004B82',
      },
      colorSecondary: {
        color: '#84CC59',
      },
      colorError: {
        color: '#EA3349',
      },
    },

    // Table
    MuiTable: {
      stickyHeader: {
        borderCollapse: 'collapse',
        '& th': {
          backgroundColor: '#E6EDF3',
          // -1px fix indent when scrolling the table
          top: `${HEADER_HEIGHT - 1}px`,
          zIndex: 1,
        },
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#E6EDF3',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.04)',
        marginBottom: '10px',
        '& > tr': {
          height: '41px;',
        },
        '& th': {
          font: '400 16px/22px OpenSans, Arial, sans-serif',
          color: '#336F9B',
          paddingTop: '10px',
          paddingBottom: '9px',
          backgroundColor: '#E6EDF3',
          '&:hover': {
            color: '#004B82',
          },
        },
        '& .MuiTableCell-sizeSmall': {
          font: '400 14px/19px OpenSans, Arial, sans-serif',
          color: '#707070',
          paddingTop: '11px',
          paddingBottom: '11px',
        },
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: '#fff',
        border: '1px solid #EAEDF3',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.04)',
      },
    },

    MuiTableCell: {
      root: {
        padding: '25px',
        borderBottom: 0,
      },
      body: {
        color: '#707070',
      },
    },

    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: '#004B82',
        },
        '&$active': {
          color: '#336F9B',
          fontWeight: '700',

          '&:hover': {
            color: '#004B82',

            '&& $icon': {
              color: '#004B82',
            },
          },
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            opacity: 1,
            color: '#336F9B',
          },
        },
      },
      icon: {
        fontSize: '14px',
      },
    },

    // Pagination
    // @ts-ignore
    MuiPagination: {
      root: {
        backgroundColor: '#fff',
      },
    },
    // @ts-ignore
    MuiPaginationItem: {
      root: {
        color: '#84CC59',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '22px',
        height: '35px',
      },
      page: {
        '&.Mui-selected': {
          color: '#707070',
          backgroundColor: 'transparent',
        },
      },
      ellipsis: {
        color: '#707070',
      },
      icon: {
        fontSize: '35px',
        backgroundColor: '#84CC59',
        '& > path': {
          color: '#fff',
        },
      },
    },
  },
});
