import React from 'react';
import { RadioProps } from '@material-ui/core';
import { RadioKitCheckedIcon, RadioKitIconSD, RadioKitSD } from './styled';

function RadioKit(props: RadioProps) {
  return (
    <RadioKitSD
      disableRipple
      color="default"
      checkedIcon={<RadioKitCheckedIcon />}
      icon={<RadioKitIconSD />}
      {...props}
    />
  );
}

export default RadioKit;
