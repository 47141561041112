import styled from 'styled-components';

export const MyClientsSD = styled.div`
  background-color: #fbfbfd;
`;

export const MyClientsContainerSD = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px 127px;
`;

export const MyClientsHeaderSD = styled.header`
  padding: 57px 0 46px;
`;

export const DialogH1 = styled.h1`
  margin-top: 0;
  font-weight: 300;
  color: #707070;
`;

export const MyClientsTableSD = styled.div`
  margin-bottom: 40px;
`;
