import styled from 'styled-components';

export const StatisticItemSD = styled.article`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;
`;

export const StatisticNumberSD = styled.div`
  font: ${({ theme }) => theme.fontWeight.light} 28px/38px Open Sans, Arial,
    sans-serif;
`;

export const StatisticTitleSD = styled.div`
  padding-top: 10px;
`;
