import React from 'react';
import { CollapsedRowCellSD, CollapsedRowSD } from './styled';
import StatusBarKit from '../../../../components/kit/StatusBarKit/StatusBarKit';
import {
  MOCK_REQUESTED_TIME,
  MOCK_SUBMITTED_TIME,
} from '../../../../components/kit/StatusBarKit/mocks/common';
import { IPortfolio } from '../../../../store/commonStates/clients/types';
import BankInfo from './BankInfo/BankInfo';
import { BANK_TYPE } from '../../../../constants/common';
import _ from 'lodash';
import { Grid } from '@material-ui/core';

interface CollapsedRowProps {
  portfolio: IPortfolio;
}

function CollapsedRow({ portfolio }: CollapsedRowProps) {
  const advisorBankInfo = portfolio.bankContacts.find(
    b => b.contactType === BANK_TYPE.advisor
  );
  const iTBankInfo = portfolio.bankContacts.find(
    b => b.contactType === BANK_TYPE.it
  );

  return (
    <CollapsedRowSD data-test="component-collapsed-row">
      <CollapsedRowCellSD colSpan={3}>
        <StatusBarKit
          dataSubmitted={MOCK_SUBMITTED_TIME}
          dataRequested={MOCK_REQUESTED_TIME}
        />
      </CollapsedRowCellSD>
      <CollapsedRowCellSD>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BankInfo
              data-test="advisor-bank-info"
              title="Advisor"
              fullName={_.get(advisorBankInfo, 'fullName', '')}
              phone={_.get(advisorBankInfo, 'phoneNumber', '')}
            />
          </Grid>
          <Grid item xs={12}>
            <BankInfo
              data-test="it-bank-info"
              title="IT"
              fullName={_.get(iTBankInfo, 'fullName', '')}
              phone={_.get(iTBankInfo, 'phoneNumber', '')}
            />
          </Grid>
        </Grid>
      </CollapsedRowCellSD>
    </CollapsedRowSD>
  );
}

export default CollapsedRow;
