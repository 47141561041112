import { NOTIFIER_ACTIONS, NotifierActionTypes, NotifierState } from './types';
import _ from 'lodash';

export const initialNotifierState: NotifierState = {
  notifications: [],
};

const reducer = (
  state = initialNotifierState,
  action: NotifierActionTypes
): NotifierState => {
  switch (action.type) {
    case NOTIFIER_ACTIONS.enqueueSnackbar:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          _.get(action, 'payload.notification'),
        ],
      };
    case NOTIFIER_ACTIONS.removeSnackbar:
      return {
        ...state,
        notifications: state.notifications.filter(
          i => i.key !== _.get(action, 'payload.key')
        ),
      };
    default:
      return state;
  }
};

export default reducer;
