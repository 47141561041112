import React from 'react';
import { Link } from 'react-router-dom';
import {
  RouteForgotPasswordSD,
  RouteForgotPasswordTitleSD,
  RouteForgotPasswordFormSD,
  RouteForgotPasswordButtonSD,
  RouteForgotPasswordWrapImageSD,
  RouteForgotPasswordImageSD,
} from './styled';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';
import IconChevronLeft from '../../components/icons/IconChevronLeft';
import imageDecor from '../../static/images/route-forgot-password.svg';
import { Box } from '@material-ui/core';
import Api from '../../api/api';
import { useSnackbar } from 'notistack';
import { propValueOr } from '../../helpers/common';

function RouteForgotPassword() {
  const [wasSend, setWasSend] = React.useState<Boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const resetPassword = (email: string, setSubmitting: Function) => {
    Api.forgotPassword(email)
      .then(() => {
        setWasSend(true);
      })
      .catch(response => {
        const errorMessage = propValueOr(
          response,
          'response.data.email',
          'Error'
        );
        setSubmitting(false);
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      });
  };

  if (wasSend) {
    return (
      <RouteForgotPasswordSD data-test="component-route-forgot-password">
        <RouteForgotPasswordTitleSD
          variant="subtitle1"
          sended={wasSend ? 1 : 0}
        >
          <Box fontSize={20} textAlign="center" lineHeight={1.5}>
            We’ve sent a password reset link and instructions to your email.
            <br />
            Please check your inbox.
          </Box>
        </RouteForgotPasswordTitleSD>
        <RouteForgotPasswordButtonSD
          component={Link}
          to="/login"
          color="primary"
          size="small"
          variant="text"
          startIcon={<IconChevronLeft />}
          sended={wasSend ? 1 : 0}
          data-test="link-sign-in"
        >
          Go Back to Sign In
        </RouteForgotPasswordButtonSD>
        <RouteForgotPasswordWrapImageSD>
          <RouteForgotPasswordImageSD src={imageDecor} alt={'Decor image'} />
        </RouteForgotPasswordWrapImageSD>
      </RouteForgotPasswordSD>
    );
  }

  return (
    <RouteForgotPasswordSD>
      <RouteForgotPasswordTitleSD variant="subtitle1">
        <Box fontSize={20} textAlign="center" lineHeight={1.5}>
          Forgot your password? No problem!
          <br /> Enter the email for your goiodata account below and we’ll send
          you a password reset link.
        </Box>
      </RouteForgotPasswordTitleSD>
      <RouteForgotPasswordFormSD>
        <ForgotPasswordForm handleSubmit={resetPassword} />
        <RouteForgotPasswordButtonSD
          component={Link}
          to="/login"
          color="primary"
          size="small"
          variant="text"
          startIcon={<IconChevronLeft />}
          data-test="link-sign-in"
        >
          Go Back to Sign In
        </RouteForgotPasswordButtonSD>
      </RouteForgotPasswordFormSD>
    </RouteForgotPasswordSD>
  );
}

export default RouteForgotPassword;
