import React, { ReactChild } from 'react';
import { ContainerSD } from '../../../app/styled';
import {
  StickyScrollSD,
  StickyScrollFilterSD,
  StickyScrollTableHeadSD,
} from './styled';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Slide } from '@material-ui/core';

export interface StickyScrollProps {
  threshold: number;
  children: {
    filter: ReactChild;
    tableHead: ReactChild;
  };
}

function StickyScroll(props: StickyScrollProps) {
  const {
    children: { filter, tableHead },
    threshold,
  } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: threshold,
  });

  return (
    <Slide data-test="slide" in={trigger}>
      <StickyScrollSD data-test="component-sticky-scroll">
        <ContainerSD>
          <StickyScrollFilterSD data-test="filter">
            {filter}
          </StickyScrollFilterSD>
          <StickyScrollTableHeadSD data-test="table-head">
            {tableHead}
          </StickyScrollTableHeadSD>
        </ContainerSD>
      </StickyScrollSD>
    </Slide>
  );
}

export default StickyScroll;
