import React, { useState } from 'react';
import { Button, Drawer, Grid, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import IconSend from '../../icons/IconSend';
import {
  DialogEmailContainerSD,
  DialogEmailHeaderSD,
  DialogEmailPaperSD,
  DialogEmailSelectSD,
  DialogEmailTextareaSD,
  DialogEmailTitleSD,
} from './styled';

interface EmailTemplate {
  id: number;
  label: string;
  template: string;
}

export interface DialogEmailProps {
  title: string;
  templates: EmailTemplate[];
  buttonText?: string;
  buttonIcon?: React.ReactElement;
  onSubmit: Function;
}

function DialogEmail(props: DialogEmailProps) {
  const {
    title,
    templates,
    buttonText = 'Send',
    buttonIcon = <IconSend />,
    onSubmit,
  } = props;
  const [form, setForm] = useState<{ type: number; template: string }>({
    type: templates[0].id,
    template: templates[0].template,
  });

  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      type: +event.target.value,
      template: _.get(
        templates.find(i => i.id === +event.target.value),
        'template',
        ''
      ),
    });
  };

  const handleChangeTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      template: event.target.value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(form);
  };

  return (
    <Drawer
      anchor="bottom"
      variant="permanent"
      PaperProps={{
        component: DialogEmailPaperSD,
      }}
    >
      <DialogEmailContainerSD>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item>
              <DialogEmailHeaderSD>
                <DialogEmailTitleSD
                  variant="h3"
                  data-test="title"
                  color="primary"
                >
                  {title}
                </DialogEmailTitleSD>
                <DialogEmailSelectSD
                  data-test="select"
                  select
                  variant="filled"
                  label="Message Template"
                  value={form.type}
                  onChange={handleChangeType}
                >
                  {templates.map((template: EmailTemplate) => (
                    <MenuItem
                      key={template.id}
                      data-test="select-option"
                      value={template.id}
                    >
                      {template.label}
                    </MenuItem>
                  ))}
                </DialogEmailSelectSD>
              </DialogEmailHeaderSD>
            </Grid>
            <Grid item xs>
              <DialogEmailTextareaSD
                data-test="textarea"
                variant="filled"
                value={form.template}
                onChange={handleChangeTemplate}
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={2} container alignItems="flex-end">
              <Button
                color="primary"
                variant="contained"
                data-test="button-submit"
                type="submit"
                startIcon={buttonIcon}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogEmailContainerSD>
    </Drawer>
  );
}

export default DialogEmail;
