import React from 'react';
import { BankInfoFullNameSD, BankInfoSD, BankInfoPhoneSD } from './styled';

export interface IBankInfoProps {
  title: string;
  fullName: string;
  phone: string;
}

function BankInfo(props: IBankInfoProps) {
  const { title, fullName, phone } = props;

  return (
    <BankInfoSD>
      <BankInfoFullNameSD>
        <span>{title}:</span>
        {fullName}
      </BankInfoFullNameSD>
      <BankInfoPhoneSD>{phone}</BankInfoPhoneSD>
    </BankInfoSD>
  );
}

export default BankInfo;
