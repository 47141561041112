import styled from "styled-components";
import {Switch} from "@material-ui/core";


export const SwitchKitSD = styled(Switch)`
  &&& {
    &:hover {
      background-color: transparent;

  }
`;

export const SwitchKitContainerSD = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


export const SwitchKitWrapLabelSD = styled.div`
  padding-top: 9px;
  color: #707070;
`;
