import {Link, useParams} from "react-router-dom";
import {RouteAddPortfolioPolicyLinkBackSD, RouteAddPortfolioPolicySD} from "./styled";
import IconChevronLeft from "../../components/icons/IconChevronLeft";
import React, {useEffect} from "react";
import AddPortfolioPoliciesForm from "./AddPortfolioPoliciesForm/AddPortfolioPoliciesForm";



function RouteAddPortfolioPolicies() {
  const { clientId = '', portfolioId = '' } = useParams();
  return (
      <RouteAddPortfolioPolicySD>
        <RouteAddPortfolioPolicyLinkBackSD
          component={Link}
          to={`/edit-portfolio/${clientId}/${portfolioId}`}
          color="primary"
          size="small"
          variant="text"
          startIcon={<IconChevronLeft />}
        >
          Go back to Portfolio
        </RouteAddPortfolioPolicyLinkBackSD>
          <AddPortfolioPoliciesForm />
      </RouteAddPortfolioPolicySD>

  )

}

export default RouteAddPortfolioPolicies;