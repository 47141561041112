import React from 'react';
import { IClient } from '../../../store/commonStates/clients/types';
import { Dialog, DialogTitle } from '@material-ui/core';
import _ from 'lodash';
import Api from '../../../api/api';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import ClientForm from '../../../routes/my-clients/ClientForm/ClientForm';
import { useHistory } from 'react-router-dom';
import { DialogKitCloseButtonSD } from '../../kit/DialogKit/styled';
import IconClose from '../../icons/IconClose';
import { getErrorsByTemplate } from '../../../helpers/common';

export interface IDialogClientForm {
  client: Partial<IClient>;
  onSaveSuccess: Function;
  onClose: Function;
  open: boolean;
}

function DialogClientForm(props: IDialogClientForm) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { open, onClose, client = {}, onSaveSuccess } = props;

  const handleSubmit = (
    values: any,
    setErrors: Function,
    setSubmitting: Function
  ) => {
    let mapping: { [key: string]: string } = {
      customer_number: 'customerNumber',
      goio_id: 'goioId',
      wealth_manager: 'wealthManager',
      first_name: 'firstName',
      last_name: 'lastName',
      nickname: 'nickname',
    };

    if (_.get(client, 'id')) {
      Api.clients
        .update({ ...client, ...values })
        .then(response => {
          onSaveSuccess({
            id: response.data.id,
            goioID: response.data.goio_id,
            customerNumber: response.data.customer_number,
            firstName: response.data.first_name,
            lastName: response.data.last_name,
            nickname: response.data.nickname,
            advisor: response.data.wealth_manager,
          });
        })
        .catch((res: any) => {
          const response = res.response;

          if (response.status === 400 && Object.keys(response.data).length) {
            setErrors(getErrorsByTemplate(response.data, mapping));
          }

          if (!Object.keys(response.data).length) {
            enqueueSnackbar('Internal error', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          }

          setSubmitting(false);
        });
    } else {
      Api.clients
        .createClient(values)
        .then((response: AxiosResponse) => {
          if (values.addPortfolio) {
            history.push(`/add-portfolio/${response.data.id}`);
          } else {
            onSaveSuccess(values);
          }
        })
        .catch((res: any) => {
          const response = res.response;

          if (
            _.get(response, 'status') === 400 &&
            Object.keys(response.data).length
          ) {
            setErrors(getErrorsByTemplate(response.data, mapping));
          }

          if (!Object.keys(response.data).length) {
            enqueueSnackbar('Internal error', {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          }
        });
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        {_.get(client, 'id') ? 'Edit' : 'Add'} Client Profile
      </DialogTitle>
      <DialogKitCloseButtonSD aria-label="close" onClick={handleClose}>
        <IconClose />
      </DialogKitCloseButtonSD>
      <ClientForm
        type={_.get(client, 'id') ? 'edit' : 'create'}
        onCancel={handleClose}
        onSubmit={handleSubmit}
        goioID={_.get(client, 'goioID')}
        customerNumber={_.get(client, 'customerNumber')}
        firstName={_.get(client, 'firstName')}
        lastName={_.get(client, 'lastName')}
        nickname={_.get(client, 'nickname')}
        wealthManager={_.get(client, 'wealthManager')}
      />
    </Dialog>
  );
}

export default DialogClientForm;
