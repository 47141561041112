import styled, { css } from 'styled-components';

export const FilterButtonKitHeaderSD = styled.header`
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 8px;
`;

export const FilterButtonKitCountSD = styled.div`
  min-width: 0;
  margin-right: 5px;
  font: ${({ theme }) => theme.fontWeight.bold} 28px/38px Open Sans, Arial,
    sans-serif;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FilterButtonKitTitlesSD = styled.div`
  flex: 0 0 85px;
  max-height: 41px;
  font: ${({ theme }) => theme.fontWeight.normal} 16px/19px Open Sans, Arial,
    sans-serif;
  overflow: hidden;
`;

export const FilterButtonKitSubtitleSD = styled.div`
  padding-right: 12px;
  padding-left: 8px;
  margin-top: 18px;
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
    sans-serif;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FilterButtonKitDescriptionSD = styled.div`
  padding-top: 5px;
  margin-top: 7px;
  border-top: 1px solid ${({ theme }) => theme.colors.lineGrey};
  color: ${({ theme }) => theme.palette.secondary.main};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial;

  text-align: right;
  text-decoration: underline;
  transition: all 0.15s ease-in-out;
`;

interface FilterButtonKitProps {
  isActive: boolean;
  small?: boolean;
}

export const FilterButtonKitSD = styled.article<FilterButtonKitProps>`
  width: 180px;
  height: ${({ small }) => (small ? '97px' : '130px')};
  padding: 15px 7px 5px;
  color: ${({ theme }) => theme.colors.textGray};
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  background-color: #fff;

  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: #fff;
          background-color: ${theme.palette.secondary.dark};

          ${FilterButtonKitDescriptionSD} {
            color: #fff;
            text-decoration: none;
          }
        `
      : css`
          &:hover {
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
            background-color: rgba(243, 250, 238, 1);
          }
        `}
`;
