import { combineReducers } from 'redux';
import userReducer from './commonStates/user/reducer';
import clientsReducer from './commonStates/clients/reducer';
import policyReducer from './commonStates/policies/reducer';
import qualityEngineerReducer from '../routes/quality-engineer/rootQualityEngineerReducer';
import notifierReducer from '../components/smart/Notifier/store/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  clients: clientsReducer,
  policies: policyReducer,
  qualityEngineer: qualityEngineerReducer,
  notifier: notifierReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
