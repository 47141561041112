import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconCheckCircle(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M-7175,12903a10.011,10.011,0,0,1-10-10,10.011,10.011,0,0,1,10-10,9.938,9.938,0,0,1,7.071,2.928A9.934,9.934,0,0,1-7165,12893,10.011,10.011,0,0,1-7175,12903Zm-4.65-10.61a.9.9,0,0,0-.651.277l-.431.446a.971.971,0,0,0,0,1.341l3.458,3.563a.589.589,0,0,0,.431.185.6.6,0,0,0,.434-.185l7.134-7.351a.964.964,0,0,0,0-1.338l-.434-.447a.894.894,0,0,0-.648-.277.9.9,0,0,0-.651.277l-5.835,6.011-2.157-2.225A.9.9,0,0,0-7179.651,12892.39Z"
        transform="translate(7185 -12883.001)"
      />
    </SvgIcon>
  );
}

export default IconCheckCircle;
