import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconVisibilityOff(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M356.821-299.185c-.135,0-.267-.008-.4-.02l-1.922,1.923a11.3,11.3,0,0,0,2.321.241,11.244,11.244,0,0,0,9.779-5.687,1.5,1.5,0,0,0,0-1.484,11.3,11.3,0,0,0-2.318-2.853l-3.2,3.2c.013.131.02.264.02.4A4.29,4.29,0,0,1,356.821-299.185Z"
        transform="translate(-346.821 313.471)"
      />
      <path
        d="M351.571-325h0a1.428,1.428,0,0,0-1.01.419l-3.8,3.8a11.229,11.229,0,0,0-3.763-.646,11.244,11.244,0,0,0-9.779,5.687,1.5,1.5,0,0,0,0,1.484,11.309,11.309,0,0,0,3.35,3.666l-3.153,3.153a1.427,1.427,0,0,0-.419,1.01h0A1.429,1.429,0,0,0,334.429-305h0a1.42,1.42,0,0,0,.684-.182,1.4,1.4,0,0,0,.326-.236l3.8-3.8,1.92-1.92,5.706-5.706,2.565-2.565,3.153-3.153a1.406,1.406,0,0,0,.236-.326,1.421,1.421,0,0,0,.182-.684A1.429,1.429,0,0,0,351.571-325Zm-12.857,10A4.29,4.29,0,0,1,343-319.286a4.252,4.252,0,0,1,1.843.422l-5.706,5.706A4.252,4.252,0,0,1,338.714-315Z"
        transform="translate(-333 325)"
      />
    </SvgIcon>
  );
}

export default IconVisibilityOff;
