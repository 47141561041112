import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { LinkProps } from 'react-router-dom';
import { EMAIL_DIALOG_HEIGHT } from '../../../constants/common';

export const RouteQEReconciliationDetailsSD = styled.div`
  max-width: 1190px;
  padding: 30px 15px ${EMAIL_DIALOG_HEIGHT + 30}px 15px;
  margin: 0 auto;
`;

export const RouteQEReconciliationDetailsLinkBackSD = styled(Button)<LinkProps>`
  margin-bottom: 30px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const RouteQEReconciliationDetailsTitleSD = styled(Typography)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const RouteQEReconciliationDetailsInfoSD = styled.div`
  margin-bottom: 30px;
`;
