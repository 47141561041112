import React from 'react';
import {
  ClientInformationCustomerNumberSD,
  ClientInformationNameSD,
  ClientInformationSD,
  ClientInformationGoioIDSD,
  ClientInformationCountSD,
  ClientInformationAvatarSD,
  ClientInformationAvatarWrapSD,
  ClientInformationTitleSD,
  ClientInformationContentSD,
  ClientInformationBankListSD,
  ClientInformationBankItemSD,
  ClientInformationHeaderSD,
  ClientInformationAvatarDefaultSD, ClientInformationNicknameSD,
} from './styled';
import BankInfo from './BankInfo/BankInfo';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { IClient } from '../../../store/commonStates/clients/types';
import { groupPortfoliosByBank, propValueOr } from '../../../helpers/common';

interface IClientInformationProps {
  client: Partial<IClient>;
}

function ClientInformation() {
  const { client } = useSelector<RootState, IClientInformationProps>(
    (state: RootState) => ({ client: state.clients.currentClient || {} })
  );
  const portfolios = groupPortfoliosByBank(
    propValueOr(client, 'portfolios', [])
  );

  return (
    <ClientInformationSD data-test="component-client-information">
      <ClientInformationHeaderSD>
        <ClientInformationTitleSD variant="h3" color="primary">
          Client Information
        </ClientInformationTitleSD>
        <Grid container wrap="nowrap">
          <Grid item>
            <ClientInformationAvatarWrapSD>
              {client.avatar ? (
                <ClientInformationAvatarSD
                  src={client.avatar}
                  alt="user avatar"
                />
              ) : (
                <ClientInformationAvatarDefaultSD />
              )}
            </ClientInformationAvatarWrapSD>
          </Grid>
          <Grid item>
            <ClientInformationContentSD>
              <ClientInformationNameSD data-test="first-name">
                {client.firstName} {client.lastName}
              </ClientInformationNameSD>
              <ClientInformationCustomerNumberSD data-test="customer-number">
                Client ID : {client.customerNumber}
              </ClientInformationCustomerNumberSD>
              <ClientInformationNicknameSD data-test="client-nickname">
                Nickname : {client.nickname}
              </ClientInformationNicknameSD>
              <ClientInformationGoioIDSD data-test="goio-id">
                Advisor: {client.wealthManagerName}
              </ClientInformationGoioIDSD>
              <ClientInformationCountSD data-test="count">
                {propValueOr(client, 'portfolios', []).length} Portfolio
              </ClientInformationCountSD>
            </ClientInformationContentSD>
          </Grid>
        </Grid>
      </ClientInformationHeaderSD>
      {Object.keys(portfolios).length > 0 && (
        <ClientInformationBankListSD>
          {Object.keys(portfolios).map((key: any) => (
            <ClientInformationBankItemSD key={key}>
              <BankInfo name={key} portfolios={portfolios[key]} />
            </ClientInformationBankItemSD>
          ))}
        </ClientInformationBankListSD>
      )}
    </ClientInformationSD>
  );
}

export default ClientInformation;
