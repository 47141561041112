import styled from 'styled-components';
import { Button, Grid } from '@material-ui/core';
import { LinkProps } from 'react-router-dom';

export const PortfolioSD = styled.article`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/19px OpenSans, sans-serif;
`;

export const PortfolioNumberSD = styled(Grid)``;

export const PortfolioSecurityAccountSD = styled(Grid)``;

export const PortfolioSecurityAccountNumberSD = styled.div``;

export const PortfolioCashAccountSD = styled(Grid)``;

export const PortfolioCashAccountTitleSD = styled.div``;

export const PortfolioCashAccountNumberSD = styled.div``;

export const PortfolioButtonSD = styled(Button)<LinkProps>`
  padding-left: 0;
`;
