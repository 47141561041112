import styled from 'styled-components';
import { FormControlLabel } from '@material-ui/core';
import { RadioKitIconSD } from '../../../../../components/kit/Fields/Radio/styled';

export const RadioSortByGroupControlLabelSD = styled(FormControlLabel)`
  ${RadioKitIconSD} {
    background-color: #fff;
  }

  input:checked ~ ${RadioKitIconSD} {
    background-color: #35ad46;
  }
`;
