export interface FilterState {
  search: string | null;
  isCustodiansFilterOpen: boolean;
  selectedCustodians: string[];
  dateRange: string[];
}

export const FILTER_ACTIONS = {
  setSearch: 'FILTER / SET_SEARCH',
  setDateRange: 'FILTER / SET_DATE_RANGE',
  setSelectedCustodians: 'FILTER / SET_CUSTODIANS',
  openCustodiansFilter: 'FILTER / OPEN_CUSTODIANS_FILTER',
  closeCustodiansFilter: 'FILTER / CLOSE_CUSTODIANS_FILTER',
  clearSelectedCustodians: 'FILTER / CLEAR_SELECTED_CUSTODIANS',
  reset: 'FILTER / RESET',
};

interface SetSearchAction {
  type: typeof FILTER_ACTIONS.setSearch;
  payload: {
    search: string;
  };
}

interface SetDateRangeAction {
  type: typeof FILTER_ACTIONS.setDateRange;
  payload: {
    dateRange: string[];
  };
}

interface SetSelectedCustodiansAction {
  type: typeof FILTER_ACTIONS.setSelectedCustodians;
  payload: {
    selectedCustodians: string[];
  };
}

interface OpenCustodiansFilterAction {
  type: typeof FILTER_ACTIONS.openCustodiansFilter;
}

interface CloseCustodiansFilterAction {
  type: typeof FILTER_ACTIONS.closeCustodiansFilter;
}

interface ClearSelectedCustodiansAction {
  type: typeof FILTER_ACTIONS.clearSelectedCustodians;
}

interface ResetFilterAction {
  type: typeof FILTER_ACTIONS.reset;
}

export type FilterActionTypes =
  | SetSearchAction
  | SetSelectedCustodiansAction
  | OpenCustodiansFilterAction
  | CloseCustodiansFilterAction
  | ClearSelectedCustodiansAction
  | SetDateRangeAction
  | ResetFilterAction;
