import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LayoutAuth from '../../layouts/auth/LayoutAuth';
import { layoutAuthRoutes } from './routes';
import RouteLogin from '../../routes/login/RouteLogin';

function NotAuthorizedLayoutRouting() {
  return (
    <LayoutAuth>
      <Switch>
        {layoutAuthRoutes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        })}
        <Route component={RouteLogin} />
      </Switch>
    </LayoutAuth>
  );
}

export default NotAuthorizedLayoutRouting;
