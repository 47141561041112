import styled, { css } from 'styled-components';

export const FilterButtonNumberSD = styled.div`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.bold} 28px/38px Open Sans, Arial,
    sans-serif;
`;

export const FilterButtonTitleSD = styled.div`
  height: 37px;
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 14px/18px Open Sans, Arial,
    sans-serif;
  overflow: hidden;
`;

interface FilterButtonSDProps {
  isActive?: Boolean;
}

export const FilterButtonSD = styled.div<FilterButtonSDProps>`
  display: flex;
  flex-direction: column;

  padding: 15px 10px 10px 15px;
  background-color: #fff;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${({ theme }) => theme.palette.secondary.dark};

          ${FilterButtonNumberSD}, ${FilterButtonTitleSD} {
            color: #fff;
          }
        `
      : css`
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

          &:hover {
            background-color: #f3faee;
            box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
          }
        `}
`;
