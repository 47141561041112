import { UserState, UserActionTypes, USER_ACTIONS } from './types';
import { Reducer } from 'redux';

export const initialUserState: UserState = {
  logged: false,
  userInfo: {
    role: 0,
    id: -1,
    firstName: '',
    lastName: '',
  },
};

const reducer: Reducer<UserState> = (
  state = initialUserState,
  action?: UserActionTypes
): UserState => {
  switch (action?.type) {
    case USER_ACTIONS.update.authorize:
      return {
        ...state,
        logged: true,
      };
    case USER_ACTIONS.update.userInfo:
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    case USER_ACTIONS.login.success:
      return {
        ...state,
        logged: true,
      };
    case USER_ACTIONS.login.request:
    case USER_ACTIONS.login.error:
      return {
        ...state,
        logged: false,
      };
    default:
      return state;
  }
};

export default reducer;
