import React from 'react';
import { LayoutAdvisorSD } from './styled';
import Header from '../../components/smart/Header/Header';

interface LayoutAdvisorProps {
  children: React.ReactNode;
}

const navItems = [
  // {
  //   title: 'Onboarding status',
  //   to: '/',
  // },
  {
    title: 'My Clients',
    to: '/my-clients',
    hover: "",
  },
  {
    title: 'Fux4goio',
    to: '',
    hover: "Coming soon..",
  },
  {
    title: 'Your Documents',
    to: '',
    hover: "Coming soon..",
  },
];

function LayoutAdvisor({ children }: LayoutAdvisorProps) {
  return (
    <LayoutAdvisorSD>
      <Header navItems={navItems} />
      {children}
    </LayoutAdvisorSD>
  );
}

export default LayoutAdvisor;
