import { AxiosInstance } from 'axios';

export interface ReconciliationsParams {
  order: string;
  order_by: string;
  page_size: number;
  page: number;
  status?: number;
  search: string | null;
  custodians: string[];
  dateRange: string[];
}

export interface CustodiansParams {
  search: string;
  status?: string | number;
  order_by: string;
  order: string;
}

class ReconciliationsApi {
  client: AxiosInstance;
  basePath: string;
  constructor(client: AxiosInstance) {
    this.client = client;
    this.basePath = 'reconciliation/';
  }

  getOne(id: number) {
    return this.client.get(`${this.basePath}${id}/details/`);
  }

  getAll({
    custodians,
    dateRange: [date_min, date_max],
    ...params
  }: ReconciliationsParams) {
    return this.client.get(`${this.basePath}`, {
      params: {
        custodian: custodians,
        ...(date_min ? { date_min } : {}),
        ...(date_max ? { date_max } : {}),
        ...params,
      },
    });
  }

  getStatistics() {
    return this.client.get(`${this.basePath}statistic/`);
  }

  getCustodians(params: CustodiansParams) {
    return this.client.get(`${this.basePath}custodian-list/`, {
      params,
    });
  }

  getPdfDocument(params: {id: number, pdf_id: string}) {
    return this.client.get(`${this.basePath}${params.id}/statements-file/${params.pdf_id}`, {
      responseType: 'blob'
    })
  }
}

export default ReconciliationsApi;
