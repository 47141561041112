
export const MOCK_CLIENT = {
  id: 24,
  avatar: undefined,
  goioID: '5de23a3308a040ca953c7b52014ffb10',
  wealthManager: 6,
  wealthManagerName: "wealthManagerName",
  customerNumber: '67564-RRTYBY-4325234-JJJHGF',
  firstName: 'Sherloc',
  lastName: 'Holms',
  nickname: 'detective',
  created: '2020-03-31T11:02:25.810968Z',
  portfolios: [],
};

export const MOCK_PORTFOLIO = {
  id: 23,
  custodian: {name: 'Sparkasse Neuwied', address: 'Test custodian address'},
  portfolioNumber: 'DE2309u6092u30925',
  accountHolders: 'Test account Holders',
  mandate: false,
  status: '',
  dateOfCancellation: 0,
  statusChangedOn: '',
  noticeSubmitted: '',
  policiesNum: 0,
  securityAccounts: [
    {
      id: 25,
      securityNumber: 'test Security Number',
    },
  ],
  cashAccounts: [
    {
      iban: 'GB33BUKB20201555555555',
      accountNo: 'GB33BUKB20201555555555',
      currency: {
        id: 1,
        code: 'AED',
      },
      accountType: {
        value: '1',
        name: "Cash"
      }
    },
  ],
  bankContacts: [
    {
      id: 10,
      contactType: 'IT',
      fullName: 'Nina Vagner',
      email: 'datagroup@bifdata.io',
      phoneNumber: '+498955878100',
    },
    {
      id: 9,
      contactType: 'AD',
      fullName: 'Kaliod Semenov',
      email: 'kokd@ya.com',
      phoneNumber: '+380680089234',
    },
  ],
};

export const MOCK_SECURITY_ACCOUNT = {
  id: 99,
  securityNumber: 'test Security Number',
};

export const MOCK_CASH_ACCOUNT = {
  iban: 'GB33BUKB20201555555556',
  currency: {
    id: 1,
    code: 'AED',
  },
  accountType: {
    value: '1',
    name: "Cash"
  }
};

export const MOCK_DATE = '2020-03-31T11:02:25.810968Z';
