import React from 'react';
import {
  PaginationKitMUPaginationSD,
  PaginationKitSD,
  PaginationKitTitleSD,
} from './styled';
import { Pagination } from '@material-ui/lab';
import PaginationForm from './PaginationForm/PaginationForm';
import { useQuery } from '../../../hooks/useQuery';

export interface IPaginationKit {
  limit?: number;
  total: number;
}

function PaginationKit(props: IPaginationKit) {
  const [query, setQuery] = useQuery();
  const { page = 1 } = query;
  const { limit = 10, total = 0 } = props;

  const handleChange = (event: any, page: number) => {
    setQuery({ page });
  };

  const labelDisplayedRows = (from: number, to: number, count: number) =>
    `Showing ${from}-${to} out of ${count}`;

  if (limit >= total) {
    return null;
  }

  return (
    <PaginationKitSD>
      <PaginationKitTitleSD>
        {labelDisplayedRows(
          (page - 1) * limit + 1,
          Math.min(total, page * limit),
          total
        )}
      </PaginationKitTitleSD>
      <PaginationKitMUPaginationSD>
        <Pagination
          count={Math.ceil(total / limit)}
          page={parseInt(page)}
          onChange={handleChange}
          siblingCount={0}
          shape="rounded"
        />
      </PaginationKitMUPaginationSD>
      <PaginationForm
        onSubmit={(page: number) => setQuery({ page })}
        maxValue={Math.ceil(total / limit)}
      />
    </PaginationKitSD>
  );
}

export default PaginationKit;
