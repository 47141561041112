import React from 'react';
import {
  IClient,
  IPortfolio,
} from '../../../../store/commonStates/clients/types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import {
  FormReadOnlyTextFieldSD,
  FormReadOnlyFieldsetSD,
  FormReadOnlyLegendSD,
} from './styled';

export interface FormReadOnlyProps {
  portfolio: IPortfolio;
  client: IClient;
}

function FormReadOnly(props: FormReadOnlyProps) {
  const { portfolio, client } = props;
  const ITContact = portfolio.bankContacts.find(b => b.contactType === 'IT');
  const advisorContact = portfolio.bankContacts.find(
    b => b.contactType === 'AD'
  );

  return (
    <form data-test="component-form-read-only">
      {/* clients fields */}
      <FormReadOnlyFieldsetSD>
        <FormReadOnlyTextFieldSD
          name="client-name"
          value={`${client.firstName} ${client.lastName}`}
          label="Client Name"
          variant="filled"
          fullWidth
          disabled
          gutterBottom
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="client-id"
              value={client.id}
              label="Client ID"
              variant="filled"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="goio-id"
              value={client.goioID}
              label="GOIO ID"
              variant="filled"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </FormReadOnlyFieldsetSD>
      {/* portfolio base info fields */}
      <FormReadOnlyFieldsetSD>
        <FormReadOnlyTextFieldSD
          name="custodian"
          value={portfolio.custodian.name}
          label="Custodian"
          variant="filled"
          fullWidth
          disabled
          gutterBottom
        />
        <FormReadOnlyTextFieldSD
          name="custodian-address"
          value={portfolio.custodian.address}
          label="Custodian Address"
          variant="filled"
          fullWidth
          disabled
          gutterBottom
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="portfolio-number"
              value={portfolio.portfolioNumber}
              label="Portfolio Number"
              variant="filled"
              fullWidth
              disabled
              gutterBottom
            />
          </Grid>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="account-holder"
              value={portfolio.accountHolders}
              label="Account Holder"
              variant="filled"
              fullWidth
              disabled
              gutterBottom
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="mandate"
              value="Yes, Advisor owns a mandate"
              label="Mandate"
              variant="filled"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </FormReadOnlyFieldsetSD>

      <FormReadOnlyFieldsetSD>
        <FormReadOnlyLegendSD component="legend" variant="h3" color="primary">
          Security Accounts
        </FormReadOnlyLegendSD>
        <Grid container spacing={2}>
          {portfolio.securityAccounts.map((account, index) => (
            <Grid key={account.id} item xs={12}>
              <FormReadOnlyTextFieldSD
                name={`security-account-${index}`}
                value={account.securityNumber}
                label="Security Account"
                variant="filled"
                fullWidth
                disabled
              />
            </Grid>
          ))}
        </Grid>
      </FormReadOnlyFieldsetSD>
      <FormReadOnlyFieldsetSD>
        <FormReadOnlyLegendSD variant="h3" color="primary">
          Cash Accounts
        </FormReadOnlyLegendSD>
        <Grid container spacing={2}>
          {portfolio.cashAccounts.map((account, index) => (
            <Grid key={account.iban} item xs={12}>
              <FormReadOnlyTextFieldSD
                name={`cash-account-${index}`}
                value={account.iban}
                label="Cash Account"
                variant="filled"
                fullWidth
                disabled
              />
            </Grid>
          ))}
        </Grid>
      </FormReadOnlyFieldsetSD>
      <FormReadOnlyFieldsetSD>
        <FormReadOnlyLegendSD variant="h3" color="primary">
          Custodian Advisor Contact
        </FormReadOnlyLegendSD>
        <FormReadOnlyTextFieldSD
          name="advisor-contact-name"
          variant="filled"
          label="Name"
          fullWidth
          disabled
          value={_.get(advisorContact, 'fullName')}
          gutterBottom
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="advisor-contact-phone"
              value={_.get(advisorContact, 'phoneNumber')}
              label="Phone"
              disabled
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="advisor-contact-email"
              value={_.get(advisorContact, 'email')}
              label="Email"
              disabled
              fullWidth
              variant="filled"
            />
          </Grid>
        </Grid>
      </FormReadOnlyFieldsetSD>
      <FormReadOnlyFieldsetSD>
        <FormReadOnlyLegendSD variant="h3" color="primary">
          Custodian IT Contact
        </FormReadOnlyLegendSD>
        <FormReadOnlyTextFieldSD
          name="it-contact-name"
          variant="filled"
          label="Name"
          fullWidth
          disabled
          value={_.get(ITContact, 'fullName')}
          gutterBottom
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="it-contact-phone"
              value={_.get(ITContact, 'phoneNumber')}
              label="Phone"
              disabled
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid item xs={6}>
            <FormReadOnlyTextFieldSD
              name="it-contact-email"
              value={_.get(ITContact, 'email')}
              label="Email"
              disabled
              fullWidth
              variant="filled"
            />
          </Grid>
        </Grid>
      </FormReadOnlyFieldsetSD>
    </form>
  );
}

export default FormReadOnly;
