import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import IconUserDefault from '../../../components/icons/IconUserDefault';
import breakpoints from '../../../helpers/breakpoints';
import { HEADER_HEIGHT } from '../../../constants/common';

export const ClientInformationSD = styled.aside`
  position: sticky;
  top: ${HEADER_HEIGHT + 30}px;
  border: 1px solid rgba(234, 237, 243, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`;

export const ClientInformationHeaderSD = styled.div`
  padding: 30px;

  ${breakpoints.down('md')} {
    padding: 24px;
  }
`;

export const ClientInformationTitleSD = styled(Typography)`
  margin-bottom: 20px;
`;

export const ClientInformationContentSD = styled.div`
  display: flex;
  flex-wrap: wrap;

  color: ${({ theme }) => theme.colors.textGray};
`;

export const ClientInformationAvatarWrapSD = styled.figure`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  background-color: ${({ theme }) => theme.colors.lineGrey};
  border-radius: 50%;
`;

export const ClientInformationAvatarDefaultSD = styled(IconUserDefault)`
  color: ${({ theme }) => theme.palette.primary['70']};
  font-size: 2rem;
`;

export const ClientInformationAvatarSD = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ClientInformationNameSD = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font: 700 16px/22px OpenSans, arial, sans-serif;
`;

export const ClientInformationGoioIDSD = styled.div`
  margin-bottom: 10px;
  font: 400 12px/17px OpenSans, Arial, sans-serif;
`;

export const ClientInformationNicknameSD = styled.div`
  width: 100%;
  margin-bottom: 10px;
  font: 400 12px/17px OpenSans, Arial, sans-serif;
`;

export const ClientInformationCustomerNumberSD = styled.div`
  margin: 0 20px 10px 0;
  font: 400 12px/17px OpenSans, Arial, sans-serif;
`;

export const ClientInformationCountSD = styled.div`
  width: 100%;
  font: 400 16px/22px OpenSans, Arial, sans-serif;
`;

export const ClientInformationBankListSD = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const ClientInformationBankItemSD = styled.li`
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;
