import React, {useEffect} from 'react';
import * as Yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import { TextField } from '@material-ui/core';
import { LoginFormButtonSD, LoginFormFieldSD, LoginFormSD } from './styled';
import PasswordField from '../../../components/kit/Fields/PasswordField/PasswordField';
import Api from "../../../api/api";
import {propValueOr} from "../../../helpers/common";
import {useSnackbar} from "notistack";
import {useQuery} from "../../../hooks/useQuery";

export interface FormValues {
  email: string;
  password: string;
}

interface FormProps {
  email?: string;
  password?: string;
  onSubmit: Function;
}

function LoginForm(props: FormikProps<FormValues>) {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  const confirmSignUp = () => {
    if (query[0].id) {
      Api.accountActivation({
        confirmationId: query[0].id,
      })
          .then(() => {
            enqueueSnackbar(String('Your account has been activated. You can now login to your dashboard.'), {
              variant: 'success',
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });

          })
          .catch(err => {
            const errMessage = propValueOr(err, 'response.data.message', 'Error');
            enqueueSnackbar(String(errMessage), {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            });
          });
    }
  };
  useEffect(confirmSignUp, [])

  return (
    <LoginFormSD onSubmit={handleSubmit} autoComplete="off">
      <LoginFormFieldSD>
        <TextField
          name="email"
          label="E-Mail-Address"
          variant="filled"
          fullWidth
          error={Boolean(touched.email && errors.email)}
          value={values.email}
          onChange={handleChange('email')}
          onBlur={handleBlur('email')}
        />
      </LoginFormFieldSD>
      <LoginFormFieldSD>
        <PasswordField
          error={Boolean(touched.password && errors.password)}
          onChange={handleChange('password')}
          onBlur={handleBlur('password')}
          value={values.password}
        />
      </LoginFormFieldSD>
      <LoginFormButtonSD type="submit" color="primary" variant="contained">
        Sign In
      </LoginFormButtonSD>
    </LoginFormSD>
  );
}

export default withFormik<FormProps, FormValues>({
  mapPropsToValues: ({ email = '', password = '' }) => ({ email, password }),
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    password: Yup.string().required('Password is required'),
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
  }),
  handleSubmit: (values: FormValues, { props, setErrors, setSubmitting }) => {
    setSubmitting(true);
    props.onSubmit(values, setErrors, setSubmitting);
  },
})(LoginForm);
