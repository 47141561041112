export const ROLES = {
  advisor: 1,
  qualityEngineer: 2,
  WMOadvisor: 4,
};

export const HEADER_HEIGHT = 72;

export const EMAIL_DIALOG_HEIGHT = 178;

export const BANK_TYPE = {
  advisor: 'AD',
  it: 'IT',
};
