import React from 'react';
import NotAuthorizedLayoutRouting from './NotAuthorizedLayoutRouting';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import AuthorizedLayoutRouting from './AuthorizedLayoutRouting';

interface AppRoutingProps {
  userLogged?: boolean;
}

function AppRouting() {
  const { userLogged } = useSelector<RootState, AppRoutingProps>(
    (state: RootState) => {
      return {
        userLogged: state.user.logged,
      };
    }
  );

  return userLogged ? (
    <AuthorizedLayoutRouting />
  ) : (
    <NotAuthorizedLayoutRouting data-test={'component-not-auth-routing'} />
  );
}

export default AppRouting;
