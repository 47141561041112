import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const FilterSD = styled.div``;

export const FilterFooterSD = styled.footer`
  margin-top: 25px;
`;

export const FilterTitleSD = styled.span`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.bold} 16px/22px Open Sans, Arial,
    sans-serif;
`;

export const FilterSearchSD = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

export const FilterSearchInputSD = styled(TextField)`
  .MuiInput-root {
    border: 1px solid ${({ theme }) => theme.palette.primary[70]};
    background-color: #fff;

    &:after,
    &:before {
      content: none;
    }
  }

  .MuiInput-input {
    padding: 10px 10px 8px;
  }

  .MuiInputAdornment-root {
    margin: 0 5px 0;
    color: ${({ theme }) => theme.palette.primary[40]};
  }
`;
