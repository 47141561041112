import React from 'react';
import {
  FilterButtonKitSD,
  FilterButtonKitHeaderSD,
  FilterButtonKitCountSD,
  FilterButtonKitTitlesSD,
  FilterButtonKitSubtitleSD,
  FilterButtonKitDescriptionSD,
} from './styled';

export interface IFilterButtonKit {
  count: number;
  title: string;
  subtitle: string;
  isActive?: boolean;
  small?: boolean;
  onClick: Function;
}

function FilterButtonKit(props: IFilterButtonKit) {
  const { count, title, subtitle, onClick, isActive, small } = props;

  const handleClick = (e: React.MouseEvent) => {
    onClick();
  };

  return (
    <FilterButtonKitSD
      data-test="component-filter-button-kit"
      onClick={handleClick}
      isActive={Boolean(isActive)}
      small={Boolean(small)}
    >
      <FilterButtonKitHeaderSD>
        <FilterButtonKitCountSD data-test="count">
          {count}
        </FilterButtonKitCountSD>
        <FilterButtonKitTitlesSD data-test="title">
          {title}
        </FilterButtonKitTitlesSD>
      </FilterButtonKitHeaderSD>
      {!small && (
        <FilterButtonKitSubtitleSD data-test="subtitle">
          {subtitle}
        </FilterButtonKitSubtitleSD>
      )}
      <FilterButtonKitDescriptionSD data-test="description">
        {isActive ? 'Shown on the list' : 'Click to show'}
      </FilterButtonKitDescriptionSD>
    </FilterButtonKitSD>
  );
}

export default FilterButtonKit;
