import React, { useEffect, useState, Fragment } from 'react';

import {
  MyClientsContainerSD,
  MyClientsHeaderSD,
  MyClientsSD,
  MyClientsTableSD,
} from './styled';
import Button from '@material-ui/core/Button';
import { AppDispatch } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, Grid, Typography } from '@material-ui/core';
import IconClose from '../../components/icons/IconClose';
import { DialogKitCloseButtonSD } from '../../components/kit/DialogKit/styled';
import { makeStyles } from '@material-ui/core/styles';
import { getGoioId } from '../../store/commonStates/client/actions';
import { useSnackbar } from 'notistack';
import IconCheckSnackbar from '../../components/icons/IconCheckSnackbar';
import { AxiosResponse } from 'axios';
import { RootState } from '../../store/rootReducer';
import WelcomePage from '../../components/smart/WelcomePage/WelcomePage';
import { getClients } from '../../store/commonStates/clients/actions';
import { IClient } from '../../store/commonStates/clients/types';
import { PersonAdd } from '@material-ui/icons';
import ClientsTable from './ClientsTable/ClientsTable';
import PaginationKit from '../../components/kit/PaginationKit/PaginationKit';
import { useQuery } from '../../hooks/useQuery';
import _ from 'lodash';
import DialogClientForm from '../../components/smart/DialogClientForm/DialogClientForm';
import SearchBar from '../../components/presentation/SearchBar/SearchBar';
import StickyScroll from '../../components/presentation/StickyScroll/StickyScroll';
import EnhancedTableHead from '../../components/presentation/EnhancedTableHead/EnhancedTableHead';
import { MY_CLIENTS_HEAD_CELLS } from './constants/common';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  dialogContent: {
    overflowY: 'visible',
  },

  successIcon: {
    float: 'left',
  },
  successText: {
    paddingLeft: 40,
    fontSize: 16,
  },
}));

interface AppRoutingProps {
  clients: Array<IClient>;
  total: number;
  isSearch: boolean;
}

function MyClients() {
  const dispatch: AppDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [openContent, setOpenContent] = useState(false);

  const [isDialogClientFormOpen, setIsDialogClientFormOpen] = useState(false);
  const [client, setClient] = useState();

  const [successLabel, setSuccessLabel] = useState('');
  const [{ page = 1, order = 'desc', order_by = 'created' }] = useQuery();

  const { clients, total, isSearch } = useSelector<RootState, AppRoutingProps>(
    (state: RootState) => {
      return {
        clients: state.clients.list,
        total: state.clients.total,
        isSearch: state.clients.isSearch,
      };
    }
  );

  const openDialogClientForm = () => {
    setIsDialogClientFormOpen(true);
  };

  const closeDialogClientForm = () => {
    setIsDialogClientFormOpen(false);
  };

  const handleClickOpenContent = () => {
    dispatch(getGoioId())
      .then((val: AxiosResponse) => {
        setClient({ goioID: val.data['goio_id'] });
        openDialogClientForm();
      })
      .catch(() => {
        enqueueSnackbar("Could'n retrieve GOIO ID", {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      });
  };

  const onCloseContent = () => {
    setOpenContent(false);
    setSuccessLabel('');
  };

  const handleSaveSuccess = (values: any) => {
    closeDialogClientForm();
    setSuccessLabel(
      `${values.firstName} ${values.lastName} (Client ID ${values.customerNumber})`
    );
    setOpenContent(true);
    dispatch(getClients({ page, order, order_by }));
  };

  const handleEditClient = (client: IClient) => {
    setClient(client);
    openDialogClientForm();
  };

  useEffect(() => {
    dispatch(getClients({ page, order, order_by }));
  }, [page, order, order_by]);

  return (
    <MyClientsSD>
      {clients.length > 0 || isSearch ? (
        <Fragment>
          <StickyScroll threshold={250}>
            {{
              filter: (
                <Grid container spacing={2} justify="flex-end">
                  <Grid item xs={6}>
                    <SearchBar />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PersonAdd />}
                      onClick={handleClickOpenContent}
                      data-test="button-add-client"
                    >
                      Add New Client
                    </Button>
                  </Grid>
                </Grid>
              ),
              tableHead: (
                <EnhancedTableHead
                  cells={MY_CLIENTS_HEAD_CELLS}
                  gutterBottom={0}
                />
              ),
            }}
          </StickyScroll>
          <MyClientsContainerSD>
            <MyClientsHeaderSD>
              <Grid
                container
                justify={'space-between'}
                alignItems={'flex-start'}
                spacing={2}
              >
                <Grid item xs={4}>
                  <Typography color="primary" variant={'h1'}>
                    My Clients
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <SearchBar />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<PersonAdd />}
                    onClick={handleClickOpenContent}
                    data-test="button-add-client"
                  >
                    Add New Client
                  </Button>
                </Grid>
              </Grid>
            </MyClientsHeaderSD>
            <MyClientsTableSD>
              <ClientsTable onEditClient={handleEditClient} clients={clients} />
            </MyClientsTableSD>
            <PaginationKit total={total} />
          </MyClientsContainerSD>
        </Fragment>
      ) : (
        <WelcomePage
          buttonAction={handleClickOpenContent}
          buttonLabel={'Add new client'}
          welcomeText={
            'In this page you would be able to <b>see and edit all details about your clients, portfolios, and portfolio mandates</b>. Let’s get started by adding clients and their portfolios!'
          }
          heading={'My Clients'}
          welcomeHeading={'Your fresh start with goiodata.'}
        />
      )}
      <Dialog
        open={openContent}
        maxWidth={'sm'}
        fullWidth={true}
        scroll={'body'}
        onClose={onCloseContent}
      >
        <DialogKitCloseButtonSD aria-label="close" onClick={onCloseContent}>
          <IconClose />
        </DialogKitCloseButtonSD>
        <div>
          <IconCheckSnackbar
            htmlColor={'#84CC59'}
            className={classes.successIcon}
          />
          {_.get(client, 'id') ? (
            <p className={classes.successText}>
              Client account <b>{successLabel}</b> has been successfully
              updated.
            </p>
          ) : (
            <p className={classes.successText}>
              New client account <b>{successLabel}</b> has been successfully
              added.
            </p>
          )}
        </div>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onCloseContent}>
            Go to my clients
          </Button>
        </DialogActions>
      </Dialog>
      <DialogClientForm
        client={client}
        open={isDialogClientFormOpen}
        onClose={closeDialogClientForm}
        onSaveSuccess={handleSaveSuccess}
      />
    </MyClientsSD>
  );
}

export default MyClients;
