import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const ForgotPasswordFormSD = styled.form`
  text-align: center;
`;

export const ForgotPasswordFormFieldSD = styled.div`
  margin-bottom: 21px;
`;

export const ForgotPasswordFormButtonSD = styled(Button)``;
