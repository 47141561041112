import React from 'react';
import {
  FilledInput,
  FormControl, FormHelperText,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import IconVisibility from '../../../icons/IconVisibility';
import IconVisibilityOff from '../../../icons/IconVisibilityOff';
import { PasswordFieldIconButtonSD } from './styled';

interface PasswordFieldProps {
  name?: string;
  error?: boolean;
  value?: string;
  label?: string;
  helperText?: string;
  autoComplate?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
}

function PasswordField(props: PasswordFieldProps) {
  const {
    error,
    value,
    onChange,
    onBlur,
    helperText,
    name = 'password',
    label = 'Your password',
    autoComplate = 'new-password'
  } = props;
  const [showPassword, setShowPassword] = React.useState<Boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="filled" error={error} fullWidth>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <FilledInput
        id={name}
        endAdornment={
          <InputAdornment position="end">
            <PasswordFieldIconButtonSD
              visible={showPassword ? 1 : 0}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <IconVisibility /> : <IconVisibilityOff />}
            </PasswordFieldIconButtonSD>
          </InputAdornment>
        }
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={value}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete={autoComplate}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default PasswordField;
