import styled from "styled-components";
import {Button, FormControl, FormControlLabel, Table, TextField, Typography} from "@material-ui/core";

export const AddPortfolioPoliciesFormSD = styled.div``;

interface AddPortfolioPoliciesFormTitleProps {
  mb?: Number;
}

export const AddPortfolioPoliciesTitleSD = styled(Typography)<
  AddPortfolioPoliciesFormTitleProps
>`
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '20px')};
`;

export const PortfolioPolicyFieldListSD = styled.ul`
  padding: 0;
  margin: 0 0 31px;
  list-style: none;
`;

export const PortfolioPolicyFieldItemSD = styled.li`
  position: relative;
  z-index: 1;
  margin-bottom: 26px;
`;

export const PortfolioPolicyTableSD = styled(Table)``;

export const PortfolioPolicyDataSD = styled.div`

`;

export const PortfolioPolicyHeaderSD = styled.header`
  padding-top: 30px;
  padding-bottom: 18px;
  background-color: ${({ theme }) => theme.palette.primary[90]};
`;

export const PortfolioPolicyContainerSD = styled.div`
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const PortfolioPolicyTitleSD = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 32px;
  line-height: 43px;
`;

export const PortfolioPolicySearchFieldSD = styled.div`
  margin-top: 14px;
`;

export const PortfolioPolicySearchClearButtonSD = styled(Button)`
  margin-top: 14px;
  margin-left: 5px;
  padding-left: 0;
  padding-right: 0;
  color: ${({ theme }) => theme.palette.primary.light};

  .MuiButton-iconSizeSmall > *:first-child {
    font-size: 20px;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: inherit;
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;


export const PolicySearchFieldSD = styled(TextField)`
  .MuiInput-root {
    border: 1px solid ${({ theme }) => theme.palette.primary[70]};
    background-color: #fff;

    &:after,
    &:before {
      content: none;
    }
  }

  .MuiInput-input {
    padding: 13px 10px 10px;
    font: ${({ theme }) => theme.fontWeight.normal} 14px/19px Open Sans, Arial,
      sans-serif;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textLightGrey};
    }
  }

  .MuiInputAdornment-root {
    margin: 0 5px 0;
    color: ${({ theme }) => theme.palette.primary[40]};
  }
`;

export const PortfolioPolicyMetadataTitleSD = styled.div`
  padding-bottom: 8px;
  margin-left: 50px;
`;

export const PortfolioPolicyMetadataSD = styled.div`
  margin-left: 50px;
`;

export const PortfolioPolicyContentSD = styled.div`
  padding-top: 28px;
  padding-bottom: 50px;
`;

export const SwitchPoliciesGroupSD: any = styled(FormControl)`
  width: 100%;
`;

export const AddPoliciesFormFieldItemSD = styled.li`
  position: relative;
  z-index: 1;
  margin-bottom: 26px;
`;