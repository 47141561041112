import React from 'react';
import {
  PortfolioNumberSD,
  PortfolioSD,
  PortfolioSecurityAccountSD,
  PortfolioSecurityAccountNumberSD,
  PortfolioCashAccountSD,
  PortfolioCashAccountTitleSD,
  PortfolioCashAccountNumberSD,
  PortfolioButtonSD,
} from './styled';
import { IPortfolio } from '../../../../../store/commonStates/clients/types';
import { Grid } from '@material-ui/core';
import IconEdit from '../../../../../components/icons/IconEdit';
import { Link } from 'react-router-dom';

interface PortfolioProps {
  portfolio: IPortfolio;
  clientId: number;
}

function Portfolio({ portfolio, clientId }: PortfolioProps) {
  return (
    <PortfolioSD data-test="component-portfolio">
      <PortfolioNumberSD
          container
          spacing={1}
          data-test="account-num"
      >
        <Grid item xs={5}>
          {portfolio.portfolioNumber} {portfolio.status === 'inactive' && <b>--inactive--</b>}
        </Grid>
        <Grid item>
          Active portfolio policies: {portfolio.policiesNum}
        </Grid>
      </PortfolioNumberSD>
      {portfolio.securityAccounts.map((account: any) => (
        <PortfolioSecurityAccountSD
          container
          key={account.id}
          spacing={1}
          data-test="security-account"
        >
          <Grid item xs={5}>
            Security
          </Grid>
          <Grid item>
            <PortfolioSecurityAccountNumberSD data-test="security-number">
              {account.securityNumber}
            </PortfolioSecurityAccountNumberSD>
          </Grid>
        </PortfolioSecurityAccountSD>
      ))}

      {portfolio.cashAccounts.map((account: any) => (
        <PortfolioCashAccountSD
          container
          key={`${account.id}_${account.iban}`}
          spacing={1}
          data-test="cash-account"
        >
          <Grid item xs={5}>
            <PortfolioCashAccountTitleSD>
              {account.accountTypeName} ({account.currency.code})
            </PortfolioCashAccountTitleSD>
          </Grid>
          <Grid item>
            <PortfolioCashAccountNumberSD data-test="iban">
              {account.iban ? account.iban: account.accountNo}
            </PortfolioCashAccountNumberSD>
          </Grid>
        </PortfolioCashAccountSD>
      ))}
      <PortfolioButtonSD
        component={Link}
        to={`/edit-portfolio/${clientId}/${portfolio.id}`}
        color="primary"
        size="small"
        variant="text"
        startIcon={<IconEdit />}
        data-test="button-edit-portfolio"
      >
        Edit
      </PortfolioButtonSD>
    </PortfolioSD>
  );
}

export default Portfolio;
