import React from 'react';
import {
  RouteAccountActivationFormSD,
  RouteAccountActivationSD,
  RouteAccountActivationTitleSD,
} from './styled';
import SetUpPasswordForm from '../../components/smart/SetUpPasswordForm/SetUpPasswordForm';
import { Box } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Api from '../../api/api';
import { propValueOr } from '../../helpers/common';

function RouteAccountActivation() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();

  const confirmSignUp = (
    password: string,
    setErrors: Function,
    setSubmitting: Function
  ) => {
    Api.accountActivation({
      password,
      confirmationId: id,
    })
      .then(() => {
        history.push({
          pathname: '/login',
          state: {
            snackbar: {
              show: true,
              message: 'Your password has been saved. You can sign in now.',
            },
          },
        });
      })
      .catch(err => {
        const errMessage = propValueOr(err, 'response.data.message', 'Error');

        setSubmitting(false);
        setErrors({ password: '', confirmPassword: '' });

        enqueueSnackbar(String(errMessage), {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      });
  };

  return (
    <RouteAccountActivationSD>
      <RouteAccountActivationTitleSD variant="subtitle1">
        <Box fontSize={20} textAlign="center">
          Welcome to goiodata. Let’s set up your password to get you started!
        </Box>
      </RouteAccountActivationTitleSD>
      <RouteAccountActivationFormSD>
        <SetUpPasswordForm onSubmit={confirmSignUp} />
      </RouteAccountActivationFormSD>
    </RouteAccountActivationSD>
  );
}

export default RouteAccountActivation;
