import styled from 'styled-components';
import { IconButton, Toolbar, TextField, Typography } from '@material-ui/core';

export const PdfViewerToolbarSD = styled(Toolbar)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  background-color: #fbfbfd;
`;

interface PdfViewerToolbarProps {
  pattern: string;
  min: string;
  max: number;
}

export const PdfViewerToolbarInputSD = styled(TextField)<PdfViewerToolbarProps>`
  width: 35px;
  margin-right: 5px;

  .MuiFilledInput-input {
    padding: 2px 5px;
  }

  .MuiInputBase-input {
    text-align: center;
    font: ${({ theme }) => theme.fontWeight.normal} 14px / 19px Open Sans, Arial,
      sans-serif;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & {
      -moz-appearance: textfield;
    }
  }
`;

export const PdfViewerToolbarTotalSD = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary[40]};
`;

export const PdfViewerToolbarButtonSD = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary[40]};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.light};
    background-color: transparent;
  }

  &.MuiIconButton-root.Mui-disabled {
    color: ${({ theme }) => theme.palette.primary[90]};
  }
`;
