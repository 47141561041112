import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const DialogKitCloseButtonSD = styled(IconButton)`
  position: absolute;
  top: 13px;
  right: 13px;
  color: #b3c9da;

  .MuiSvgIcon-root {
    width: 15px;
    height: 15px;
  }
`;
