import styled from 'styled-components';
import { Button, FormControl } from '@material-ui/core';

export const FormSD = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormTitleSD = styled.p`
  margin: 0 0 13px;

  color: ${({ theme }) => theme.colors.textGray};
  font: 400 16px/30px OpenSans, sans-serif;
`;

export const FormControlSD: any = styled(FormControl)`
  margin-bottom: 41px;
`;

export const FormButtonSD = styled(Button)`
  margin-left: auto;
`;
