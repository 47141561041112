import React, { useEffect } from 'react';
import { RouteEditPortfolioLinkBackSD, RouteEditPortfolioSD } from './styled';
import { Link, useParams, useHistory } from 'react-router-dom';
import IconChevronLeft from '../../../components/icons/IconChevronLeft';
import { Dialog, DialogActions, Grid } from '@material-ui/core';
import ClientInformation from '../../add-portfolio/ClientInformation/ClientInformation';
import AddPortfolioForm from '../../add-portfolio/AddPortfolioForm/AddPortfolioForm';
import {
  getClient, getEmptyPortfolio,
  getPortfolio,
} from '../../../store/commonStates/clients/actions';
import { AppDispatch } from '../../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICashAccount,
  IPortfolio,
} from '../../../store/commonStates/clients/types';
import { RootState } from '../../../store/rootReducer';
import _ from 'lodash';
import Api from '../../../api/api';
import { getErrorsByTemplate } from '../../../helpers/common';
import { DialogKitCloseButtonSD } from '../../../components/kit/DialogKit/styled';
import IconClose from '../../../components/icons/IconClose';
import IconCheckSnackbar from '../../../components/icons/IconCheckSnackbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflowY: 'visible',
  },

  successIcon: {
    float: 'left',
  },
  successText: {
    paddingLeft: 40,
    marginBottom: 35,
    fontSize: 16,
  },
}));

interface IUseSelectorProps {
  portfolio: IPortfolio | {};
}

function RouteEditPortfolio() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const { clientId = '', portfolioId = '' } = useParams();
  const [open, setOpen] = React.useState(false);

  const { portfolio } = useSelector<RootState, IUseSelectorProps>(
    (state: RootState) => ({
      portfolio: {
        ...state.clients.currentPortfolio,
        cashAccounts: _.get(
          state,
          'clients.currentPortfolio.cashAccounts',
          []
        ).map((a: ICashAccount) => ({
          id: a.id,
          accountNo: a.accountNo,
          iban: a.iban,
          currency: _.get(a, 'currency.id'),
          accountType: a.accountType,
        })),
      },
    })
  );

  useEffect(() => {
    dispatch(getClient(clientId));
  }, [clientId]);

  useEffect(() => {
    dispatch(getPortfolio(portfolioId));
  }, [portfolioId]);

  // set empty portfolio when leave the form
  useEffect(() => {
    return () => {
       dispatch(getEmptyPortfolio())
    };
  }, []);

  const updatePortfolio = (
    values: any,
    setErrors: Function,
    setSubmitting: Function,
    setStatus: Function
  ) => {
    Api.portfolios
      .update(portfolioId, values)
      .then(() => {
        setOpen(true);
      })
      .catch(err => {
        const template = {
          portfolio_number: 'portfolioNumber',
          cash_accounts: 'cashAccounts',
          custodian: 'custodian',
          account_holder: 'accountHolder',
          account_holders: 'accountHolders',
          security_accounts: 'securityAccounts',
          security_number: 'securityNumber',
          phone_number: 'phoneNumber',
          bank_contacts: 'bankContacts',
        };

        const transformedErrors = getErrorsByTemplate(
          _.get(err, 'response.data', {}),
          template
        );
        if (_.get(transformedErrors, 'securityAccounts', undefined)) {
          let caseMapping: { [key: string]: string } = {
            security_number: 'securityNumber',
            security_accounts: 'securityAccounts',
          };
          let securityAccounts = Object();
          transformedErrors.securityAccounts.forEach((v: any, k: number) => {
            if (v instanceof String) {
              if (!securityAccounts.hasOwnProperty('securityAccounts')) {
                securityAccounts['securityAccounts'] = []
              }
              securityAccounts['securityAccounts'].push(v)
            } else if (v instanceof Object && 'path' in v && v['path']) { // Object
              let path = v['path'].split(' ').map((vv: any, kk: number) => {
                if (!isNaN(vv)) {
                  return '[' + vv + ']'
                }
                let key = caseMapping[String(vv)] || String(vv);
                return kk === 0 ? key : '.' + key
              }).join('');

              if (!securityAccounts.hasOwnProperty(path)) {
                securityAccounts[path] = []
              }
              securityAccounts[path].push(v['message'])
            }
          });

          for (let prop in securityAccounts) {
            transformedErrors[prop] = securityAccounts[prop];
          }
        }

        if (_.get(transformedErrors, 'cashAccounts', undefined)){
          let caseMapping: { [key: string]: string } = {
            iban: 'iban',
            cash_accounts: 'cashAccounts',
          };
          let cashAccounts = Object();
          transformedErrors.cashAccounts.forEach((v: any, k: number) => {
            if (v instanceof String) {
              if (!cashAccounts.hasOwnProperty('cashAccounts')) {
                cashAccounts['cashAccounts'] = []
              }
              cashAccounts['cashAccounts'].push(v)
            } else if (v instanceof Object && 'path' in v && v['path']) { // Object
              let path = v['path'].split(' ').map((vv: any, kk: number) => {
                if (!isNaN(vv)) {
                  return '[' + vv + ']'
                }
                let key = caseMapping[String(vv)] || String(vv);
                return kk === 0 ? key : '.' + key
              }).join('');
              if (!cashAccounts.hasOwnProperty(path)) {
                cashAccounts[path] = []
              }
              cashAccounts[path].push(v['message'])
            }

          });
          for (let prop in cashAccounts) {
            transformedErrors[prop] = cashAccounts[prop];
          }

        }

        transformedErrors.bankAdvisorContact = _.get(
          transformedErrors,
          'bankContacts[0]',
          {}
        );
        transformedErrors.bankITContact = _.get(
          transformedErrors,
          'bankContacts[1]',
          {}
        );

        setErrors(transformedErrors);
        setStatus({ error: true });
      });
  };

  const closeSuccessDialog = () => {
    history.push('/my-clients');
  };

  return (
    <RouteEditPortfolioSD data-test="component-edit-portfolio">
      <RouteEditPortfolioLinkBackSD
        component={Link}
        to="/my-clients"
        color="primary"
        size="small"
        variant="text"
        startIcon={<IconChevronLeft />}
        data-test="link-my-clients"
      >
        Go back to My Clients page
      </RouteEditPortfolioLinkBackSD>
      <Grid container spacing={3} justify="space-between">
        <Grid item lg={7} sm={6}>
          <AddPortfolioForm
            onCancel={() => history.push('/my-clients')}
            type="edit"
            {...portfolio}
            bankAdvisorContact={_.get(portfolio, 'bankContacts', []).find(
              (b: any) => b.contactType === 'AD'
            )}
            bankITContact={_.get(portfolio, 'bankContacts', []).find(
              (b: any) => b.contactType === 'IT'
            )}
            handleSubmit={updatePortfolio}
          />
        </Grid>
        <Grid item lg={4} sm={6}>
          <ClientInformation />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        maxWidth={'sm'}
        fullWidth={true}
        scroll={'body'}
        onClose={closeSuccessDialog}
        data-test="component-success-dialog"
      >
        <DialogKitCloseButtonSD
          aria-label="close"
          onClick={closeSuccessDialog}
          data-test="icon-button-close"
        >
          <IconClose />
        </DialogKitCloseButtonSD>
        <div>
          <IconCheckSnackbar
            htmlColor={'#84CC59'}
            className={classes.successIcon}
          />
          <p className={classes.successText} data-test="text">
            The portfolio information is successfully updated.
          </p>
        </div>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={closeSuccessDialog}
            data-test="button-close"
          >
            Go to my clients
          </Button>
        </DialogActions>
      </Dialog>
    </RouteEditPortfolioSD>
  );
}

export default RouteEditPortfolio;
