import styled from "styled-components";
import {FormControl} from "@material-ui/core";
import breakpoints from "../../../helpers/breakpoints";


export const ClientFormAdvisorSelectSD = styled(FormControl)<
  typeof FormControl
>`
  display: flex;

  ${breakpoints.down('md')} {
    flex-direction: column;
  }

`;