import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import breakpoints from '../../helpers/breakpoints';

export const RouteResetPasswordSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RouteResetPasswordFormSD = styled.div`
  width: 376px;

  ${breakpoints.down('md')} {
    width: 308px;
  }

  ${breakpoints.down('sm')} {
    width: 348px;
  }
`;

export const RouteResetPasswordTitleSD = styled(Typography)`
  max-width: 425px;
  margin-bottom: 35px;
`;
