import { IHeadCell } from '../../../components/presentation/EnhancedTableHead/EnhancedTableHead';

export const ONBOARDING_PORTFOLIO_STATUSES = {
  submitted: 'SUBMITTED',
  requested: 'REQUESTED',
  activated: 'ACTIVATED',
};

export const STATUS_LABELS = {
  [ONBOARDING_PORTFOLIO_STATUSES.submitted]: 'Portfolio submitted',
  [ONBOARDING_PORTFOLIO_STATUSES.requested]: 'Data requested',
  [ONBOARDING_PORTFOLIO_STATUSES.activated]: 'Account activated',
};

export const ONBOARDING_STATUS_HEAD_CELLS: IHeadCell[] = [
  {
    id: 'name',
    label: 'Client Name',
    isSortable: true,
    width: '25%',
  },
  {
    id: 'custodian',
    label: 'Custodian & Portfolio Number',
    width: '25%',
  },
  {
    id: 'status',
    label: 'Latest Status',
    width: '25%',
  },
  {
    id: 'updated',
    label: 'Updated on',
    isSortable: true,
    width: '25%',
  },
];
