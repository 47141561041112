import React from 'react';
import StatisticItem, {
  IStatisticItemProps,
} from './StatisticItem/StatisticItem';
import { StatisticItemSD, StatisticSD } from './styled';

export interface IStatisticProps {
  first: IStatisticItemProps;
  second: IStatisticItemProps;
}

function Statistic({ first, second }: IStatisticProps) {
  return (
    <StatisticSD data-test="component-statistic">
      <StatisticItemSD>
        <StatisticItem data-test="first" {...first} />
      </StatisticItemSD>
      <StatisticItemSD>
        <StatisticItem data-test="second" {...second} />
      </StatisticItemSD>
    </StatisticSD>
  );
}

export default Statistic;
