import React from 'react';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import {
  CheckboxKitAdditionalLabelSD,
  CheckboxKitCheckedIcon,
  CheckboxKitControlLabelSD,
  CheckboxKitIconSD,
  CheckboxKitLabelSD,
  CheckboxKitSD,
  CheckboxKitWrapLabelSD,
} from './styled';

interface CheckboxKitProps {
  additionalLabel?: string;
  label?: string;
}

function CheckboxKit({
  label,
  additionalLabel,
  ...otherProps
}: CheckboxKitProps & CheckboxProps) {
  let content;

  if (!label) {
    content = (
      <CheckboxKitSD
        data-test={'checkbox'}
        disableRipple
        color={'default'}
        checkedIcon={<CheckboxKitCheckedIcon />}
        icon={<CheckboxKitIconSD />}
        {...otherProps}
      />
    );
  } else {
    content = (
      <CheckboxKitControlLabelSD
        data-test={'control-label'}
        control={
          <CheckboxKitSD
            data-test={'checkbox'}
            disableRipple
            color={'default'}
            checkedIcon={<CheckboxKitCheckedIcon />}
            icon={<CheckboxKitIconSD />}
            {...otherProps}
          />
        }
        label={
          <CheckboxKitWrapLabelSD>
            <CheckboxKitLabelSD>{label}</CheckboxKitLabelSD>
            {additionalLabel && (
              <CheckboxKitAdditionalLabelSD data-test={'additional-label'}>
                {additionalLabel}
              </CheckboxKitAdditionalLabelSD>
            )}
          </CheckboxKitWrapLabelSD>
        }
      />
    );
  }

  return <div data-test={'component-checkbox-kit'}>{content}</div>;
}

export default CheckboxKit;
