import styled, { css } from 'styled-components';
import {
  Table,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export const ClientsTableSD = styled(Table)``;

interface ClientsTableRowProps {
  expanded?: number;
}

export const ClientTableRowSD = styled(TableRow)<ClientsTableRowProps>`
  vertical-align: top;

  ${({ expanded }) =>
    !!expanded &&
    css`
      border-bottom-color: transparent;
    `}
`;

interface ClientTableCellProps {
  expanded?: number;
}

export const ClientTableCellSD = styled(TableCell)<ClientTableCellProps>`
  position: relative;
  ${({ expanded }) =>
    !!expanded &&
    css`
      padding-bottom: 0;
      border-bottom: 0;
    `}
`;

export const ClientTableButtonMoreSD = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  color: ${({ theme }) => theme.palette.primary[70]};
`;

export const ClientTableIconClosed = styled(KeyboardArrowDownIcon)`
  font-size: 32px;
`;

export const ClientTableIconOpened = styled(KeyboardArrowUpIcon)`
  font-size: 32px;
`;
