import styled from 'styled-components';
import { RECONCILIATION_STATUSES } from '../../../constants/common';
import iconSuccess from '../../../../../static/icons/reconcilliation-break-success.svg';
import iconProcessed from '../../../../../static/icons/reconcilliation-break-processed.svg';
import iconNotProcessed from '../../../../../static/icons/reconcilliation-break-not-processed.svg';

export const ReconciliationCardSD = styled.div``;

export const ReconciliationCardCustodianSD = styled.div``;

export const ReconciliationCardIdSD = styled.div``;

export const ReconciliationCardNumberSD = styled.div``;

interface ReconciliationCardStatusProps {
  status: number;
}

export const ReconciliationCardStatusSD = styled.div<
  ReconciliationCardStatusProps
>`
  display: flex;
  align-items: center;

  &:before {
    content: url(${getIconByStatus});
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

function getIconByStatus(props?: any) {
  switch (props.status) {
    case RECONCILIATION_STATUSES.success:
      return iconSuccess;
    case RECONCILIATION_STATUSES.processed:
      return iconProcessed;
    case RECONCILIATION_STATUSES.notProcessed:
      return iconNotProcessed;
    default:
      return '';
  }
}

export const ReconciliationCardDateSD = styled.time``;
