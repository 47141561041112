import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const PageBlankSD: any = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageBlankTitleSD = styled(Typography)`
  margin-bottom: 40px;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const PageBlankContentSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 45px 45px 65px;
  margin-bottom: 52px;
  border: 5px dashed ${({ theme }) => theme.palette.primary[90]};
`;

export const PageBlankSubtitleSD: any = styled(Typography)`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const PageBlankTextSD = styled.div`
  max-width: 575px;
  color: ${({ theme }) => theme.colors.textGray};
  font: 400 16px/22px OpenSans, Arial, sans-serif;
  text-align: center;
`;

export const PageBlankWrapImageSD = styled.figure`
  max-width: 865px;
  margin: 0 auto;
`;

export const PageBlankImageSD = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;
