import styled, { css } from 'styled-components';
import { Button, ExtendButtonBase, Typography } from '@material-ui/core';
import breakpoints from '../../helpers/breakpoints';

export const RouteForgotPasswordSD = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface RouteForgotPasswordTitleSDProps {
  sended?: 1 | 0;
}

export const RouteForgotPasswordTitleSD = styled(Typography)<
  RouteForgotPasswordTitleSDProps
>`
  margin-bottom: ${props => (!!props.sended ? '60px' : '29px')};
`;

export const RouteForgotPasswordFormSD = styled.div`
  width: 376px;

  ${breakpoints.down('md')} {
    width: 308px;
  }

  ${breakpoints.down('sm')} {
    width: 348px;
  }
`;

interface RouteForgotPasswordButtonSDProps {
  sended?: 1 | 0;
}

export const RouteForgotPasswordButtonSD: ExtendButtonBase<any> = styled(
  Button
)<RouteForgotPasswordButtonSDProps>`
  ${props =>
    props.sended
      ? css`
          margin-bottom: 60px;
        `
      : css`
          margin-top: 60px;
        `};
`;

export const RouteForgotPasswordWrapImageSD = styled.figure``;

export const RouteForgotPasswordImageSD = styled.img``;
