import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconCalendar(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M257.53-211H240.47a1.469,1.469,0,0,0-1.47,1.47v8.175a3.212,3.212,0,0,0,3.212,3.212h13.576A3.212,3.212,0,0,0,259-201.355v-8.175A1.469,1.469,0,0,0,257.53-211Zm-13.173,8.338a.948.948,0,0,1-.948.948h-.247a.948.948,0,0,1-.948-.948v-.247a.948.948,0,0,1,.948-.948h.247a.948.948,0,0,1,.948.948Zm0-4.286a.948.948,0,0,1-.948.948h-.247a.948.948,0,0,1-.948-.948v-.247a.948.948,0,0,1,.948-.948h.247a.948.948,0,0,1,.948.948Zm5.714,4.286a.948.948,0,0,1-.948.948h-.247a.948.948,0,0,1-.948-.948v-.247a.948.948,0,0,1,.948-.948h.247a.948.948,0,0,1,.948.948Zm0-4.286a.948.948,0,0,1-.948.948h-.247a.948.948,0,0,1-.948-.948v-.247a.948.948,0,0,1,.948-.948h.247a.948.948,0,0,1,.948.948Zm5.714,4.286a.948.948,0,0,1-.948.948h-.247a.948.948,0,0,1-.948-.948v-.247a.948.948,0,0,1,.948-.948h.247a.948.948,0,0,1,.948.948Zm0-4.286a.948.948,0,0,1-.948.948h-.247a.948.948,0,0,1-.948-.948v-.247a.948.948,0,0,1,.948-.948h.247a.948.948,0,0,1,.948.948Z"
        transform="translate(-239 218.143)"
      />
      <path
        d="M257.7-229.571h-1.557A1.429,1.429,0,0,0,254.714-231a1.429,1.429,0,0,0-1.429,1.429v.714a.714.714,0,0,1-.714.714.714.714,0,0,1-.714-.714V-229a.568.568,0,0,0-.568-.568h-6.575A1.429,1.429,0,0,0,243.286-231a1.429,1.429,0,0,0-1.429,1.429v.714a.714.714,0,0,1-.714.714.714.714,0,0,1-.714-.714v-.714H240.3a1.3,1.3,0,0,0-1.3,1.3v2.255a.731.731,0,0,0,.731.731h18.539a.731.731,0,0,0,.731-.731v-2.255A1.3,1.3,0,0,0,257.7-229.571Z"
        transform="translate(-239 231)"
      />
    </SvgIcon>
  );
}

export default IconCalendar;
