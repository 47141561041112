// @ts-nocheck

import {
  CUSTODIANS_FILTER_ACTIONS,
  CustodiansFilterState,
  CustodiansFilterTypes,
} from './types';
import { RECONCILIATION_STATUSES } from '../../../constants/common';

export const initialState = {
  sortBy: 'custodian',
  status: RECONCILIATION_STATUSES.notProcessed,
  search: '',
  selectedCustodians: [],
  custodians: [],
};

export default (
  state: CustodiansFilterState | undefined = initialState,
  action: CustodiansFilterTypes
): CustodiansFilterState => {
  const { type } = action;

  switch (type) {
    case CUSTODIANS_FILTER_ACTIONS.setSortBy:
      return {
        ...state,
        // @ts-ignore
        sortBy: action.payload.sortBy,
      };
    case CUSTODIANS_FILTER_ACTIONS.setStatus:
      return {
        ...state,
        // @ts-ignore
        status: action.payload.status,
      };
    case CUSTODIANS_FILTER_ACTIONS.setSelectedCustodians:
      return {
        ...state,
        // @ts-ignore
        selectedCustodians: action.payload.selectedCustodians,
      };
    case CUSTODIANS_FILTER_ACTIONS.toggleCustodian:
      return {
        ...state,
        // @ts-ignore
        selectedCustodians: state.selectedCustodians.includes(
          action.payload.custodian
        )
          ? state.selectedCustodians.filter(i => i !== action.payload.custodian)
          : // @ts-ignore
            [...state.selectedCustodians, action.payload.custodian],
      };
    case CUSTODIANS_FILTER_ACTIONS.setSearch:
      return {
        ...state,
        // @ts-ignore
        search: action.payload.search,
      };
    case CUSTODIANS_FILTER_ACTIONS.setCustodians:
      return {
        ...state,
        custodians: action.payload.custodians,
      };
    case CUSTODIANS_FILTER_ACTIONS.clearCustodians:
      return {
        ...state,
        selectedCustodians: [],
      };
    default:
      return state;
  }
};
