import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 15,
    boxShadow: '2px 2px 10px #00000033',
    width: 180,
    color: '#707070',
    marginRight: 15,
    cursor: 'pointer',
  },
  cardActive: {
    padding: 15,
    boxShadow: '2px 2px 10px #00000033',
    width: 180,
    color: '#ffffff',
    backgroundColor: '#6AA347',
    marginRight: 15,
    cursor: 'pointer',
  },
  heading: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '30px',
  },
}));

function StatisticCard({
  active,
  count,
  typeLabel,
  label,
  onClick,
  ...props
}: {
  active: boolean;
  count: string;
  typeLabel: string;
  label: string;
  onClick: (e: React.MouseEvent) => void;
}) {
  const styles = useStyles();

  return (
    <div
      className={active ? styles.cardActive : styles.card}
      {...props}
      onClick={onClick}
      data-test="component-statistic-card"
    >
      <div className={styles.heading} data-test="count">
        {count}
      </div>
      <div>{typeLabel}</div>
      <div>{label}</div>
    </div>
  );
}

export default StatisticCard;
