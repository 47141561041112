import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconAddPortfolio(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M241.881-15.286h9.951a2.881,2.881,0,0,0,2.881-2.881v-9.951A2.881,2.881,0,0,0,251.833-31h-9.951A2.881,2.881,0,0,0,239-28.119v9.951A2.881,2.881,0,0,0,241.881-15.286Zm-.027-8.362a.92.92,0,0,1,.92-.921h2.654v-2.654a.92.92,0,0,1,.92-.92h1.016a.92.92,0,0,1,.92.92v2.654h2.654a.921.921,0,0,1,.92.921v1.016a.92.92,0,0,1-.92.92h-2.654v2.654a.921.921,0,0,1-.92.921h-1.016a.921.921,0,0,1-.92-.921v-2.654h-2.654a.92.92,0,0,1-.92-.92Z"
        transform="translate(-239 35.286)"
      />
      <path
        d="M258.8-43H249.82a.82.82,0,0,0-.82.82v.893a.82.82,0,0,0,.82.82h8.418a2.8,2.8,0,0,1,2.79,2.79v8.418a.82.82,0,0,0,.82.82h.893a.82.82,0,0,0,.82-.82v-8.984A4.756,4.756,0,0,0,258.8-43Z"
        transform="translate(-243.56 43)"
      />
    </SvgIcon>
  );
}

export default IconAddPortfolio;
