import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const RouteOnboardingStatusSD = styled.div`
  padding-top: 45px;
  padding-bottom: 60px;
  background-color: #fbfbfd;
`;

export const RouteOnboardingStatusContainerSD = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  padding: 0 15px;
`;

export const RouteOnboardingStatusStatisticSD = styled.div`
  margin-bottom: 10px;
`;

export const RouteOnboardingStatusHeaderSD = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const RouteOnboardingStatusFilterSD = styled.div`
  margin-bottom: 10px;
`;

export const RouteOnboardingStatusTableSD = styled.div`
  margin-bottom: 40px;
`;

export const RouteOnboardingStatusTitleSD = styled(Typography)`
  margin-right: 16px;
  color: ${({ theme }) => theme.palette.primary.light};
`;
