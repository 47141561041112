import styled from 'styled-components';
import { Radio, FormControlLabel } from '@material-ui/core';

export const RadioKitIconSD = styled.span`
  width: 20px;
  height: 20px;
  border: 1px solid #b3c9da;
  border-radius: 50%;
  background-color: #fff;

  input:disabled ~ & {
    border-color: #c9c9c9;
    background-color: #eaedf3;
  }
`;

export const RadioKitCheckedIcon = styled(RadioKitIconSD)`
  position: relative;
  border-color: #2d9c3c;
  background-color: #35ad46;
  box-shadow: 0 1px 1px #2d9c3c;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const RadioKitSD = styled(Radio)`
  &&& {
    &:hover {
      background-color: transparent;

      ${RadioKitIconSD} {
        border-color: #6693b4;
      }
    }

    input:disabled ~ ${RadioKitIconSD} {
      border-color: #c9c9c9;
      background-color: #eaedf3;
    }
  }
`;

interface RadioKitLabelProps {
  small?: boolean;
  bold?: boolean;
}

export const RadioKitControlLabelSD = styled(FormControlLabel)`
  display: flex;
  align-items: flex-start;
`;

export const RadioKitWrapLabelSD = styled.div`
  padding-top: 9px;
`;

export const RadioKitLabelSD = styled.div`
  color: #707070;
  font: ${(p: RadioKitLabelProps) => (p.bold ? 700 : 400)}
      ${(p: RadioKitLabelProps) => (p.small ? '14px' : '16px')} /
      ${(p: RadioKitLabelProps) => (p.small ? '19px' : '22px')} OpenSans,
    Arial, sans-serif;
`;
