import styled, { css } from 'styled-components';
import { TableCell, TableRow } from '@material-ui/core';

export const CollapsedRowCellSD = styled(TableCell)`
  position: relative;
  padding-top: 20px;
  padding-bottom: 5px;
`;

export const CollapsedRowBankSD = styled.div`
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.bold} 14px/19px OpenSans, sans-serif;
`;

export const CollapsedRowPortfoliosSD = styled.div``;

export const CollapsedRowPortfolioSD = styled.div`
  & + & {
    position: relative;
    padding-top: 10px;
    padding-bottom: 3px;
    margin-top: 5px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 30px;
      height: 1px;
      background-color: #eaedf3;
    }
  }
`;

interface CollapsedRowProps {
  first?: number;
}

export const CollapsedRowSD = styled(TableRow)<CollapsedRowProps>`
  vertical-align: top;

  ${CollapsedRowCellSD} {
    ${({ first }) =>
      !!first &&
      css`
        padding-top: 0;
      `}
  }

  &:not(:last-child) {
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  & + & {
    ${CollapsedRowBankSD} {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 25px;
        right: 0;
        height: 1px;
        background-color: #eaedf3;
      }
    }

    ${CollapsedRowPortfoliosSD} {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 55px;
        height: 1px;
        background-color: #eaedf3;
      }
    }
  }
`;
