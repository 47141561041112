import styled from 'styled-components';
import { TextField } from '@material-ui/core';

interface PageInputFormFieldProps {
  pattern: string;
  min: string;
  max: number;
  inputmode: string;
}

export const PageInputFormFieldSD = styled(TextField)<PageInputFormFieldProps>`
  width: 35px;
  margin-right: 5px;

  .MuiFilledInput-input {
    padding: 2px 5px;
  }

  .MuiInputBase-input {
    text-align: center;
    font: ${({ theme }) => theme.fontWeight.normal} 14px / 19px Open Sans, Arial,
      sans-serif;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    & {
      -moz-appearance: textfield;
    }
  }
`;
