import { NOTIFIER_ACTIONS } from './types';

export const enqueueSnackbar = (notification: any) => {
  const key = new Date().getTime() + Math.random();

  return {
    type: NOTIFIER_ACTIONS.enqueueSnackbar,
    payload: {
      notification: {
        key,
        ...notification,
      },
    },
  };
};

export const removeSnackbar = (key: number) => {
  return {
    type: NOTIFIER_ACTIONS.removeSnackbar,
    payload: { key },
  };
};
