import styled from 'styled-components';
import { Button, Grid, Typography } from '@material-ui/core';
import { LinkProps } from 'react-router-dom';
import { EMAIL_DIALOG_HEIGHT } from '../../../constants/common';

export const RouteQEOnboardingDetailsSD = styled.div`
  padding-top: 30px;
  padding-bottom: ${50 + EMAIL_DIALOG_HEIGHT}px;
`;

export const RouteQEOnboardingDetailsContainerSD = styled.div`
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
`;

export const RouteQeOnboardingDetailsHeaderSD = styled(Grid)`
  margin-bottom: 30px;
`;

export const RouteQEOnboardingDetailsLinkSD = styled(Button)<LinkProps>`
  margin-bottom: 30px;
`;

export const RouteQEOnboardingDetailsTitleSD = styled(Typography)`
  margin-bottom: 20px;
`;

export const RouteQEOnboardingDetailsQEActionSD = styled.div`
  margin-top: -7px;
`;

export const RouteQEOnboardingDetailsPropertySD = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textGray};
  font: ${({ theme }) => theme.fontWeight.normal} 16px/22px Open Sans, Arial,
    sans-serif;
`;

export const RouteQEOnboardingDetailsPropertyKeySD = styled.div`
  margin-right: 10px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const RouteQEOnboardingDetailsPropertyValueSD = styled.div``;
