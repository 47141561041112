import { Action } from '../../../app/types';

export interface UserInfo {
  firstName: string;
  id: number;
  lastName: string;
  role: number;
}

export interface UserState {
  logged?: boolean;
  registered?: boolean;
  userInfo: UserInfo;
}

// Describing the different ACTION NAMES available
export const USER_ACTIONS = {
  login: {
    request: 'USER / LOGIN_REQUEST',
    success: 'USER / LOGIN_SUCCESS',
    error: 'USER / LOGIN_ERROR',
  },
  registration: {
    request: 'USER / REGISTRATION_REQUEST',
    success: 'USER / REGISTRATION_SUCCESS',
    error: 'USER / REGISTRATION_ERROR',
  },
  update: {
    userInfo: 'USER / UPDATE INFO',
    authorize: 'USER / UPDATE_AUTHORIZE',
  },
};

type loginRequestAction = Action<typeof USER_ACTIONS.login.request, any>;

type loginSuccessAction = Action<typeof USER_ACTIONS.login.success, any>;

type loginErrorAction = Action<typeof USER_ACTIONS.login.error, any>;

type updateUserInfoAction = Action<typeof USER_ACTIONS.update.userInfo, any>;

type updateAuthorizeAction = Action<typeof USER_ACTIONS.update.authorize, any>;

export type UserActionTypes =
  | loginRequestAction
  | loginSuccessAction
  | loginErrorAction
  | updateUserInfoAction
  | updateAuthorizeAction;
