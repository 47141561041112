import {Action} from "../../../app/types";

export const POLICIES_ACTIONS = {
  setPolicies: 'POLICIES_ACTIONS / SET_POLICIES',
  togglePolicy: 'TOGGLE_POLICIES_ACTIONS / TOGGLE_POLICIES',
  setPolicyValue: 'SET_POLICIES_VALUE_ACTIONS / SET_POLICIES_VALUE',
  setPoliciesSearch: 'SET_POLICIES_SEARCH_ACTIONS / SET_POLICIES_SEARCH_VALUE',
  clearPoliciesSearch: 'CLEAR_POLICIES_SEARCH_ACTIONS / CLEAR_POLICIES_SEARCH_VALUE',
};

type setPoliciesAction = Action<
  typeof POLICIES_ACTIONS.setPolicies,
  any
>;

type setPoliciesSearch = Action<
  typeof POLICIES_ACTIONS.setPoliciesSearch,
  any
>;

type togglePolicyAction = Action<
  typeof POLICIES_ACTIONS.togglePolicy,
  any
>;
type setPolicyValue = Action<
  typeof POLICIES_ACTIONS.setPolicyValue,
  any
>;
type clearPoliciesSearch = Action<
  typeof POLICIES_ACTIONS.clearPoliciesSearch,
  any
>;


export type PoliciesActionTypes =
  | setPoliciesAction
  | togglePolicyAction
  | setPolicyValue
    | setPoliciesSearch
    |clearPoliciesSearch;