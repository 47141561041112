import { Action } from '../../../app/types';
import {ICustodian} from "../AddPortfolioForm/hooks/useCustodian";

type PortfolioInfo = {
  fullName: string;
  customerNumber: string;
};

interface ICashAccount {
  iban: string;
  currency: number | '';
}

interface IBankContact {
  fullName: string;
  email: string;
  phone: string;
}

export interface IAddPortfolioForm {
  custodian: ICustodian;
  portfolioNumber: string;
  accountHolders: string;
  securityAccounts: Array<string>;
  cashAccounts: Array<ICashAccount>;
  bankAdvisorContact: IBankContact;
  bankITContact: IBankContact;
  addAnother: boolean;
}

export interface State {
  dialogSaveSuccessOpen: boolean;
  dialogSaveAndCreateSuccessOpen: boolean;
  portfolioInfo: PortfolioInfo;
  form: IAddPortfolioForm;
}

// Describing the different ACTION NAMES available
export const ROUTE_ADD_PORTFOLIO_ACTIONS = {
  dialogSaveSuccess: {
    open: 'DIALOG_SAVE_SUCCESS / OPEN',
    close: 'DIALOG_SAVE_SUCCESS / CLOSE',
  },
  dialogSaveAndCreateSuccess: {
    open: 'DIALOG_SAVE_AND_CREATE_SUCCESS / OPEN',
    close: 'DIALOG_SAVE_AND_CREATE_SUCCESS / CLOSE',
  },
  portfolioInfo: {
    update: 'PORTFOLIO_INFO / SET',
  },
  form: {
    updateFields: 'ROUTE_ADD_PORTFOLIO_ACTIONS / FORM_UPDATE_FIELDS',
    reset: 'ROUTE_ADD_PORTFOLIO_ACTIONS / FORM_RESET',
  },
};

type openDialogSaveSuccess = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.open,
  any
>;

type closeDialogSaveSuccess = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveSuccess.close,
  any
>;

type openDialogSaveAndCreateSuccess = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveAndCreateSuccess.open,
  any
>;

type closeDialogSaveAndCreateSuccess = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.dialogSaveAndCreateSuccess.close,
  any
>;

type updatePortfolioInfo = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.portfolioInfo.update,
  PortfolioInfo
>;

type updateAddPortfolioFormUpdateFields = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.form.updateFields,
  IAddPortfolioForm
>;

type updateAddPortfolioFormReset = Action<
  typeof ROUTE_ADD_PORTFOLIO_ACTIONS.form.reset,
  any
>;

export type RouteAddPortfolioTypes =
  | openDialogSaveSuccess
  | closeDialogSaveSuccess
  | openDialogSaveAndCreateSuccess
  | closeDialogSaveAndCreateSuccess
  | updatePortfolioInfo
  | updateAddPortfolioFormUpdateFields
  | updateAddPortfolioFormReset;
