// A custom hook that builds on useLocation, useHistory
// get and set query string
// https://codesandbox.io/s/use-query-6bjc2?file=/src/useQuery.js:295-377
import { useLocation, useHistory } from 'react-router-dom';
import {getClients} from "../store/commonStates/clients/actions";
import {AppDispatch} from "../store/configureStore";
import {useDispatch} from "react-redux";

function parseQuery(search: any) {
  const params = new URLSearchParams(search);
  const object: { [key: string]: any } = {};
  // @ts-ignore
  for (const [key, value] of params) {
    object[key] = value;
  }
  return object;
}

function stringifyQuery(query: any) {
  return new URLSearchParams(query).toString();
}

export const useQuery = (): [{ [key: string]: any }, Function] => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const setSearchParams = (params: any) => {
    const mergedParams = {
      ...parseQuery(search),
      ...params,
    };

    dispatch(getClients({ ...mergedParams }, true));

    history.push({
      search: stringifyQuery(mergedParams),
    });
  };

  return [parseQuery(search), setSearchParams];
};
