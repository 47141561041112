import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

function IconArrowDropUp(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 10.919">
      <path
        d="M67.389-212.39a2.172,2.172,0,0,0-3.409,0l-5.35,6.769a2.052,2.052,0,0,0,1.61,3.325H71.129a2.052,2.052,0,0,0,1.61-3.325Z"
        transform="translate(-58.185 213.215)"
      />
    </SvgIcon>
  );
}

export default IconArrowDropUp;
