import React, { Fragment, useEffect, useState } from 'react';
import {
  RouteQEOnboardingPackageBlankSD,
  RouteQEOnboardingPackageContainerSD,
  RouteQEOnboardingPackageHeaderSD,
  RouteQEOnboardingPackageSD,
  RouteQEOnboardingPackageTitleSD,
} from './styled';
import PageBlank from '../../../components/presentation/PageBlank/PageBlank';
import imageDecor from '../../../static/images/decore-reconciliation-breaks.png';
import Api from '../../../api/api';
import { Grid } from '@material-ui/core';
import OnboardingPackageTable from './OnboardingPackageTable';
import StatisticCard from './StatisticCard';
import { makeStyles } from '@material-ui/core/styles';
import PaginationKit from '../../../components/kit/PaginationKit/PaginationKit';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../../../hooks/useQuery';
import Filter from '../Filter/Filter';
import { resetFilter } from '../Filter/store/actions';
import { AppDispatch } from '../../../store/configureStore';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  statisticRow: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
});

function RouteQEOnboardingPackage() {
  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();
  const [total, setTotal] = useState(100);
  const location = useLocation();
  const [
    { order = 'asc', order_by, type = 'not-verified' },
    setQuery,
  ] = useQuery();
  const params = new URLSearchParams(location.search);
  const pageSize = 30;
  const page = parseInt(params.get('page') || '1');
  const [statistic, setStatistic] = useState({
    verified: 0,
    notVerified: 0,
    total: 0,
  });

  const [portfolios, setPortfolios] = useState<Array<object>>([]);

  useEffect(() => {
    Api.portfolios.statistic().then(data => {
      setStatistic({
        verified: data.data.verified,
        notVerified: data.data.notVerified,
        total: data.data.total,
      });
    });
  }, []);

  useEffect(() => {
    Api.portfolios
      .list(page, pageSize, {
        type: type,
        order: order,
        order_by: order_by,
      })
      .then(data => {
        setTotal(data.data.count);
        setPortfolios(data.data.results);
      });
  }, [page, type, order, order_by]);

  useEffect(() => {
    return () => {
      dispatch(resetFilter());
    };
  }, []);

  return (
    <RouteQEOnboardingPackageSD>
      {portfolios.length === 0 ? (
        <RouteQEOnboardingPackageBlankSD>
          <PageBlank
            title={'Onboarding Package'}
            subtitle={'Keep calm, onboarding packages are on its way.'}
            renderText={() => (
              <Fragment>
                In this page you would be able to{' '}
                <strong>
                  keep track of onboarding packages received by GOIO from
                  advisors.
                </strong>{' '}
                Your next steps would be to{' '}
                <strong>review these packages</strong> and{' '}
                <strong>send them back to the advisors</strong> in case of
                missing information or{' '}
                <strong>forward them to the respective custodians.</strong> For
                now, it seems like you no packages to review!
              </Fragment>
            )}
            image={imageDecor}
          />
        </RouteQEOnboardingPackageBlankSD>
      ) : (
        <RouteQEOnboardingPackageContainerSD>
          <RouteQEOnboardingPackageHeaderSD container>
            <Grid item xs={8}>
              <RouteQEOnboardingPackageTitleSD color="primary" variant={'h1'}>
                Onboarding Package
              </RouteQEOnboardingPackageTitleSD>
              <div className={classes.statisticRow}>
                <StatisticCard
                  active={type === 'not-verified'}
                  count={statistic.notVerified.toString()}
                  typeLabel={'Not verified'}
                  label={'Onboarding Packages'}
                  onClick={(e: React.MouseEvent) => {
                    setQuery({ type: 'not-verified', page: 1 });
                  }}
                />
                <StatisticCard
                  active={type === 'verified'}
                  count={statistic.verified.toString()}
                  typeLabel={'Verified'}
                  label={'Onboarding Packages'}
                  onClick={(e: React.MouseEvent) => {
                    setQuery({ type: 'verified', page: 1 });
                  }}
                />
                <StatisticCard
                  active={type === 'all'}
                  count={statistic.total.toString()}
                  typeLabel={'All'}
                  label={'Onboarding Packages'}
                  onClick={(e: React.MouseEvent) => {
                    setQuery({ type: 'all', page: 1 });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <Filter />
            </Grid>
          </RouteQEOnboardingPackageHeaderSD>
          <OnboardingPackageTable portfolios={portfolios} />
          <br />
          <br />
          <PaginationKit limit={pageSize} total={total} />
        </RouteQEOnboardingPackageContainerSD>
      )}
    </RouteQEOnboardingPackageSD>
  );
}

export default RouteQEOnboardingPackage;
