import React, { Fragment } from 'react';
import WelcomePage from '../../components/smart/WelcomePage/WelcomePage';
import DialogClientForm from '../../components/smart/DialogClientForm/DialogClientForm';
import { AxiosResponse } from 'axios';
import Api from '../../api/api';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { Dialog, DialogActions, Grid } from '@material-ui/core';
import { DialogKitCloseButtonSD } from '../../components/kit/DialogKit/styled';
import IconClose from '../../components/icons/IconClose';
import IconCheckSnackbar from '../../components/icons/IconCheckSnackbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  RouteOnboardingStatusHeaderSD,
  RouteOnboardingStatusSD,
  RouteOnboardingStatusTitleSD,
  RouteOnboardingStatusContainerSD,
  RouteOnboardingStatusStatisticSD,
  RouteOnboardingStatusFilterSD,
  RouteOnboardingStatusTableSD,
} from './style';
import PersonAdd from '@material-ui/icons/PersonAdd';
import PortfoliosTable from './PortfoliosTable/PortfoliosTable';
import {
  MOCK_PORTFOLIOS,
  MOCK_STATISTICS,
  MOCK_STATISTICS_DATE,
} from './mocks/common';
import StatisticDate from './StatisticDate/StatisticDate';
import Statistic from './Statistic/Statistic';
import Filter from './Filter/Filter';
import PaginationKit from '../../components/kit/PaginationKit/PaginationKit';
import StickyScroll from '../../components/presentation/StickyScroll/StickyScroll';
import SearchBar from '../../components/presentation/SearchBar/SearchBar';
import FilterButtonsGroup from './FilterButtonsGroup/FilterButtonsGroup';
import EnhancedTableHead from '../../components/presentation/EnhancedTableHead/EnhancedTableHead';
import { ONBOARDING_STATUS_HEAD_CELLS } from './constants/common';

const useStyles = makeStyles(() => ({
  successIcon: {
    float: 'left',
  },
  successText: {
    paddingLeft: 40,
    fontSize: 16,
  },
}));

const initialState = {
  client: null,
  isDialogClientFormOpen: false,
  isSuccessDialogOpen: false,
  successLabel: '',
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'set-success-label':
      return {
        ...state,
        successLabel: action.payload.successLabel,
      };
    case 'set-client':
      return {
        ...state,
        client: action.payload.client,
      };
    case 'open-dialog-client-form':
      return {
        ...state,
        isDialogClientFormOpen: true,
      };
    case 'close-dialog-client-form':
      return {
        ...state,
        isDialogClientFormOpen: false,
      };
    case 'open-dialog-save-success':
      return {
        ...state,
        isSuccessDialogOpen: true,
      };
    case 'close-dialog-save-success':
      return {
        ...state,
        isSuccessDialogOpen: false,
      };
    default:
      return state;
  }
};

function OnboardingStatus(props: { portfolios?: any[] }) {
  const { portfolios = MOCK_PORTFOLIOS } = props;
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const createNewClient = () => {
    Api.clients
      .getGoioId()
      .then((response: AxiosResponse) => {
        dispatch({
          type: 'set-client',
          payload: { client: { goioID: response.data['goio_id'] } },
        });
        dispatch({
          type: 'open-dialog-client-form',
        });
      })
      .catch(() => {
        enqueueSnackbar("Could'n retrieve GOIO ID", {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      });
  };

  const closeDialogClientForm = () => {
    dispatch({
      type: 'close-dialog-client-form',
    });
  };

  const handleSaveSuccess = (values: any) => {
    dispatch({
      type: 'set-success-label',
      payload: {
        successLabel: `${values.firstName} ${values.lastName} (Customer Number ${values.customerNumber})`,
      },
    });
    closeDialogClientForm();
    openSuccessDialog();
  };

  const closeSuccessDialog = () => {
    dispatch({
      type: 'close-dialog-save-success',
    });
    history.push('/my-clients');
  };

  const openSuccessDialog = () => {
    dispatch({
      type: 'open-dialog-save-success',
    });
  };

  return (
    <Fragment>
      {portfolios.length > 0 ? (
        <Fragment>
          <StickyScroll threshold={350}>
            {{
              filter: (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FilterButtonsGroup small />
                  </Grid>
                  <Grid item xs={6}>
                    <SearchBar />
                  </Grid>
                </Grid>
              ),
              tableHead: (
                <EnhancedTableHead
                  cells={ONBOARDING_STATUS_HEAD_CELLS}
                  gutterBottom={0}
                />
              ),
            }}
          </StickyScroll>
          <RouteOnboardingStatusSD>
            <RouteOnboardingStatusContainerSD>
              <RouteOnboardingStatusHeaderSD>
                <RouteOnboardingStatusTitleSD variant="h1">
                  Portfolio Onboarding Status
                </RouteOnboardingStatusTitleSD>

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<PersonAdd />}
                  onClick={createNewClient}
                >
                  Add New Client
                </Button>
              </RouteOnboardingStatusHeaderSD>
              <Grid container spacing={2} justify="space-between">
                <Grid item lg={6}>
                  <RouteOnboardingStatusFilterSD>
                    <Filter />
                  </RouteOnboardingStatusFilterSD>
                </Grid>
                <Grid item lg={5}>
                  <RouteOnboardingStatusStatisticSD>
                    <Statistic
                      first={MOCK_STATISTICS.first}
                      second={MOCK_STATISTICS.second}
                    />
                  </RouteOnboardingStatusStatisticSD>
                  <StatisticDate
                    first={MOCK_STATISTICS_DATE.first}
                    second={MOCK_STATISTICS_DATE.second}
                  />
                </Grid>
              </Grid>
              <RouteOnboardingStatusTableSD>
                <PortfoliosTable portfolios={MOCK_PORTFOLIOS} />
              </RouteOnboardingStatusTableSD>
              <PaginationKit total={78} />
            </RouteOnboardingStatusContainerSD>
          </RouteOnboardingStatusSD>
        </Fragment>
      ) : (
        <WelcomePage
          buttonAction={createNewClient}
          buttonLabel={'Add new client'}
          welcomeText={
            'In this page you would be able to <b>keep track of your clients’ portfolio onboarding statuses</b>. Let’s set up your dashboard by adding clients and their portfolios!'
          }
          heading={'Ready, set, goio!'}
          welcomeHeading={'Ready, set, goio!'}
        />
      )}
      <Dialog
        open={Boolean(state.isSuccessDialogOpen)}
        maxWidth={'sm'}
        fullWidth={true}
        scroll={'body'}
        onClose={closeSuccessDialog}
        data-test="dialog-success"
      >
        <DialogKitCloseButtonSD aria-label="close" onClick={closeSuccessDialog}>
          <IconClose />
        </DialogKitCloseButtonSD>
        <div>
          <IconCheckSnackbar
            htmlColor={'#84CC59'}
            className={classes.successIcon}
          />
          <p className={classes.successText}>
            New client account <b>{state.successLabel}</b> has been successfully
            added.
          </p>
        </div>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={closeSuccessDialog}
          >
            Go to my clients
          </Button>
        </DialogActions>
      </Dialog>
      <DialogClientForm
        client={_.get(state, 'client', {})}
        open={state.isDialogClientFormOpen}
        onClose={closeDialogClientForm}
        onSaveSuccess={handleSaveSuccess}
      />
    </Fragment>
  );
}

export default OnboardingStatus;
