import styled from 'styled-components';

export const RouteQEDataQualitySD = styled.div`
  padding-top: 45px;
`;

export const RouteQEDataQualityBlankSD = styled.div`
  max-width: 1190px;
  padding: 0 15px;
  margin: 0 auto;
`;
