import {
  PolicySearchFieldSD,
  PortfolioPolicyContainerSD,
  PortfolioPolicyContentSD,
  PortfolioPolicyDataSD,
  PortfolioPolicyHeaderSD,
  PortfolioPolicyMetadataSD,
  PortfolioPolicyMetadataTitleSD,
  PortfolioPolicySearchClearButtonSD,
  PortfolioPolicySearchFieldSD,
  PortfolioPolicyTitleSD
} from "./styled";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/rootReducer";
import {CLIENTS_ACTIONS, IClient, IPortfolio} from "../../../store/commonStates/clients/types";
import React, {useEffect, useState} from "react";
import {getClient, getEmptyPortfolio, getPortfolio} from "../../../store/commonStates/clients/actions";
import {AppDispatch} from "../../../store/configureStore";
import Api from "../../../api/api";
import {Button, Dialog, DialogActions, Grid, InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import PortfolioPolicyGroup from "./PortfolioPolicyGroup";
import {POLICIES_ACTIONS} from "../../../store/commonStates/policies/types";
import useUnsavedChangesWarning from "../hooks/useUnsavedChangesWarning";
import IconAddCircle from "../../../components/icons/IconAddCircle";
import {DialogKitCloseButtonSD} from "../../../components/kit/DialogKit/styled";
import IconClose from "../../../components/icons/IconClose";
import IconCheckSnackbar from "../../../components/icons/IconCheckSnackbar";
import {makeStyles} from "@material-ui/core/styles";


interface IUseSelectorClientProps {
  client: Partial<IClient>;
}
interface IUseSelectorPortfolioProps {
  portfolio: Partial<IPortfolio>;
}

interface PoliciesSelectorProps {
  policies: any;
  search: string;
  selectedPolicies: any;
}

const useStyles = makeStyles(() => ({
  dialogContent: {
    overflowY: 'visible',
  },

  successIcon: {
    float: 'left',
  },
  successText: {
    paddingLeft: 40,
    marginBottom: 35,
    fontSize: 16,
  },
}));

export const getEmptyPolicies = (): any => (
  dispatch: AppDispatch
) => {
  dispatch({
    type: POLICIES_ACTIONS.setPolicies,
    payload: {
      policies: [],
    }
  });
};

function AddPortfolioPoliciesForm() {
  const { clientId = '', portfolioId = '' } = useParams();
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    dispatch(getPortfolio(portfolioId));
  }, [portfolioId]);
  useEffect(() => {
    dispatch(getClient(clientId));
  }, [clientId]);
  const { client } = useSelector<RootState, IUseSelectorClientProps>(
    (state: RootState) => ({ client: state.clients.currentClient || {} })
  );
  const { portfolio } = useSelector<RootState, IUseSelectorPortfolioProps>(
    (state: RootState) => ({portfolio: state.clients.currentPortfolio || {} })
  );
  const dispatch: AppDispatch = useDispatch();

  const { policies, search, selectedPolicies } = useSelector<
    RootState,
    PoliciesSelectorProps
  >((state: RootState) => {
    return {
      policies: state.policies.policies,
      search: state.policies.search,
      selectedPolicies: state.policies.selectedPolicies,
    };
  })

  // set empty portfolio when leave the form
  useEffect(() => {
    return () => {
       dispatch(getEmptyPolicies())
    };
  }, []);

  useEffect(() => {
    Api.portfolios
      .getPolicies(portfolioId, {
        search: search,
      })
      .then(response => {
        if (search) {
          dispatch({
            type: POLICIES_ACTIONS.setPolicies,
            payload: {
              selectedPolicies: response.data,
            }
          });
        } else {
          dispatch({
            type: POLICIES_ACTIONS.setPolicies,
            payload: {
              policies: response.data,
            }
          });
        }
      });
  }, [portfolioId, search]);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: POLICIES_ACTIONS.setPoliciesSearch,
      payload: { search: (event.target as HTMLInputElement).value },
    });
  };

  return (
      <PortfolioPolicyDataSD>
        <PortfolioPolicyHeaderSD>
          <PortfolioPolicyContainerSD>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} container justify="space-between">
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="flex-end"
                  justify="space-between"
                >
                  <Grid item>
                    <PortfolioPolicyTitleSD data-test="title" variant="h2">
                      Portfolio Policies
                    </PortfolioPolicyTitleSD>
                  </Grid>
                </Grid>
                <Grid>
                  <PortfolioPolicySearchClearButtonSD
                    variant="text"
                    size="small"
                    startIcon={<IconAddCircle />}
                    disabled={!search}
                    onClick={() => {
                      dispatch({
                        type: POLICIES_ACTIONS.clearPoliciesSearch,
                      });
                    }}
                  >
                    Clear filter
                  </PortfolioPolicySearchClearButtonSD>
                </Grid>
                <Grid item xs={12}>
                  <PortfolioPolicySearchFieldSD>
                    <PolicySearchFieldSD
                      data-test="search-field"
                      placeholder={'Search policies'}
                      fullWidth
                      value={search}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>{
                        handleChangeSearch(e);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </PortfolioPolicySearchFieldSD>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <PortfolioPolicyMetadataTitleSD>
                  Selected Portfolio:
                </PortfolioPolicyMetadataTitleSD>
                <PortfolioPolicyMetadataSD>
                    {client.firstName || client.lastName ? client.firstName + ' ' + client.lastName: client.nickname}
                </PortfolioPolicyMetadataSD>
                <PortfolioPolicyMetadataSD>
                    Client ID : {client.customerNumber}
                </PortfolioPolicyMetadataSD>
                <PortfolioPolicyMetadataSD>
                    {portfolio.custodian ? portfolio.custodian.name : ''}
                </PortfolioPolicyMetadataSD>
                <PortfolioPolicyMetadataSD>
                    Portfolio No: {portfolio.portfolioNumber}
                </PortfolioPolicyMetadataSD>

              </Grid>
            </Grid>
          </PortfolioPolicyContainerSD>
        </PortfolioPolicyHeaderSD>
        <PortfolioPolicyContentSD>
          <PortfolioPolicyContainerSD>
            <PortfolioPolicyGroup
              policies={ search ? selectedPolicies : policies}
              portfolioId={portfolioId}
            />

          </PortfolioPolicyContainerSD>

        </PortfolioPolicyContentSD>
      </PortfolioPolicyDataSD>
  )
}


export default AddPortfolioPoliciesForm;
