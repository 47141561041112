import React from 'react';
import {
  CollapsedRowCellSD,
  CollapsedRowSD,
  CollapsedRowBankSD,
  CollapsedRowPortfoliosSD,
  CollapsedRowPortfolioSD,
} from './styled';
import { IPortfolio } from '../../../../store/commonStates/clients/types';
import Portfolio from './Portfolio/Portfolio';

interface CollapsedRowProps {
  portfoliosByBanks: {
    [key: string]: Array<IPortfolio>;
  };
  clientId: number;
}

function CollapsedRow({ portfoliosByBanks, clientId }: CollapsedRowProps) {
  return (
    <React.Fragment>
      {Object.keys(portfoliosByBanks).map((key: any, index: number) => {
        return (
          <CollapsedRowSD
            key={key}
            first={index === 0 ? 1 : 0}
            data-test="collapsed-bank-row"
          >
            <CollapsedRowCellSD style={{ width: '25%' }} />
            <CollapsedRowCellSD style={{ width: '25%' }}>
              <CollapsedRowBankSD data-test="bank">{key}</CollapsedRowBankSD>
            </CollapsedRowCellSD>
            <CollapsedRowCellSD>
              <CollapsedRowPortfoliosSD data-test="portfolios-row">
                {portfoliosByBanks[key].map((portfolio: any) => (
                  <CollapsedRowPortfolioSD key={portfolio.id}>
                    <Portfolio portfolio={portfolio} clientId={clientId} />
                  </CollapsedRowPortfolioSD>
                ))}
              </CollapsedRowPortfoliosSD>
            </CollapsedRowCellSD>
          </CollapsedRowSD>
        );
      })}
    </React.Fragment>
  );
}

export default CollapsedRow;
