/**
 * example https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example?file=/Notifier.js:984-1019
 */

import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { removeSnackbar } from './store/actions';

interface NotifierSelectorProps {
  notifications: any[];
}

function Notifier() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { notifications } = useSelector<RootState, NotifierSelectorProps>(
    (state: RootState) => {
      return {
        notifications: state.notifier.notifications,
      };
    }
  );

  useEffect(() => {
    notifications.forEach(notification => {
      // display snackbar using notistack
      enqueueSnackbar(notification.message, {
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        key: notification.key,
        ...notification.options,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(+myKey));
        },
      });
    });
  }, [notifications]);

  return null;
}

export default Notifier;
