import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { propValueOr } from '../helpers/common';
import ClientsApi from './clients/clientsApi';
import PortfoliosApi from './portfolios/portfoliosApi';
import ReconciliationsApi from './reconciliations/reconciliationsApi';

/**
 * Singleton Api class
 */
export class API {
  client: AxiosInstance;
  token: string | null | undefined;
  refreshToken: string | null | undefined;
  refreshRequest: Promise<AxiosResponse<any>> | undefined;
  refreshTokenPath: string;
  clients: ClientsApi;
  portfolios: PortfoliosApi;
  reconsiliations: ReconciliationsApi;
  private static instance: API;
  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    });
    this.refreshTokenPath = 'auth/token/refresh/';

    this.token = window.localStorage.getItem('jwt');
    this.refreshToken = window.localStorage.getItem('jwt_refresh');

    this.clients = new ClientsApi(this.client);
    this.portfolios = new PortfoliosApi(this.client);
    this.reconsiliations = new ReconciliationsApi(this.client);

    // before request check that header include token
    this.client.interceptors.request.use(
      config => {
        if (!this.token) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config,
        };

        newConfig.headers.Authorization = `Bearer ${this.token}`;

        return newConfig;
      },
      err => Promise.reject(err)
    );

    // update tokens if need
    this.client.interceptors.response.use(
      res => res,
      err => {
        if (
          !this.refreshToken ||
          !err.response ||
          err.response.status !== 401 ||
          err.config.retry ||
          err.config.url === this.refreshTokenPath
        ) {
          throw err;
        }

        return this.updateTokens(err.config);
      }
    );

    this.refreshRequest = undefined;

    this.clients = new ClientsApi(this.client);

    if (API.instance) {
      return API.instance;
    }

    API.instance = this;
  }

  updateTokens(lastRequestConfig: AxiosRequestConfig) {
    // if some requests fire together
    if (!this.refreshRequest) {
      this.refreshRequest = this.client.post(this.refreshTokenPath, {
        refresh: this.refreshToken,
      });
    }

    // @ts-ignore
    return this.refreshRequest
      .then(resp => {
        window.localStorage.setItem('jwt', propValueOr(resp, 'data.access'));
        this.token = propValueOr(resp, 'data.access');

        this.refreshRequest = undefined;

        if (!lastRequestConfig) {
          return;
        }

        const repeatedRequest = {
          ...lastRequestConfig,
          retry: true,
        };

        return this.client(repeatedRequest);
      })
      .catch(() => {
        this.refreshRequest = undefined;
        this.logout();
      });
  }

  login({ email, password }: { password: string; email: string }) {
    return this.client.post('/auth/token/', {
      email,
      password,
    });
  }

  registration({ email, password, company_id, first_name, last_name }: { password: string; email: string, company_id: string, first_name: string, last_name: string }) {
    return this.client.post('/auth/registration/', {
      email,
      password,
      company_id,
      first_name,
      last_name,
    });
  }

  userInfo() {
    return this.client.get('/auth/user/info/');
  }

  accountActivation({
    confirmationId,
    password = '',
  }: {
    confirmationId: string | undefined;
    password?: string;
  }) {
    return this.client
      .post('/auth/signup/confirm/', {
        confirmation_id: confirmationId,
        password,
      })
      .then(resp => {
        return resp;
      });
  }

  forgotPassword(email: string) {
    return this.client.post('/auth/password/reset/', { email });
  }

  resetPassword(values: {
    password: string;
    token: string | null;
    uid?: string | null;
  }) {
    return this.client
      .post('/auth/password/reset/confirm/', {
        new_password1: values.password,
        new_password2: values.password,
        uid: values.uid,
        token: values.token,
      })
      .then(resp => {
        return resp;
      });
  }

  getCurrencies() {
    return this.client.get('/portfolios/currency/');
  }

  getAccountTypes() {
    return this.client.get('/portfolios/account_types/');
  }

  getCustodians() {
    return this.client.get('/portfolios/custodian/');
  }

  getAdvisors() {
    return this.client.get('/auth/wmo_advisors/');
  }

  logout() {
    this.token = null;
    this.refreshToken = null;
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('jwt_refresh');
    window.location.replace('/login');
  }

  setTokens = (token: string, refreshToken: string) => {
    this.token = token;
    this.refreshToken = refreshToken;
    window.localStorage.setItem('jwt', token);
    window.localStorage.setItem('jwt_refresh', refreshToken);
  };
}

export default new API();
