import React, { useEffect } from 'react';
import {
  RouteResetPasswordSD,
  RouteResetPasswordTitleSD,
  RouteResetPasswordFormSD,
} from './styled';
import SetUpPasswordForm from '../../components/smart/SetUpPasswordForm/SetUpPasswordForm';
import { Box } from '@material-ui/core';
import { useQuery } from '../../hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Api from '../../api/api';
import { propValueOr } from '../../helpers/common';

function RouteResetPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [query] = useQuery();
  const history = useHistory();

  useEffect(() => {
    const token = query['token'];
    const uid = query['uid'];

    if (!(token || uid)) {
      history.push('/login');
    }
  }, []);

  const resetPassword = (
    password: string,
    setErrors: Function,
    setSubmitting: Function
  ) => {
    const token = query['token'];
    const uid = query['uid'];

    Api.resetPassword({
      password,
      token,
      uid,
    })
      .then(() => {
        history.push({
          pathname: '/login',
          state: {
            snackbar: {
              show: true,
              message: 'Your password has been changed. You can sign in now.',
            },
          },
        });
      })
      .catch(err => {
        const errMessage = propValueOr(err, 'response.data.message', 'Error');

        setSubmitting(false);
        setErrors({ password: '', confirmPassword: '' });

        enqueueSnackbar(String(errMessage), {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      });
  };

  return (
    <RouteResetPasswordSD>
      <RouteResetPasswordTitleSD variant="subtitle1">
        <Box fontSize={20} textAlign="center">
          Welcome to goiodata. Let’s change your password to get you started!
        </Box>
      </RouteResetPasswordTitleSD>
      <RouteResetPasswordFormSD>
        <SetUpPasswordForm onSubmit={resetPassword} />
      </RouteResetPasswordFormSD>
    </RouteResetPasswordSD>
  );
}

export default RouteResetPassword;
