import React from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import PdfViewerToolBar from './PdfViewerToolbar/PdfViewerToolbar';
import reducer, { initialState } from './reducer/reducer';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { PDF_VIEWER_ACTIONS } from './reducer/types';
import {
  PdfViewerAspectRatioBoxInsideSD,
  PdfViewerAspectRatioBoxSD,
} from './styled';
import { Paper } from '@material-ui/core';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface IPdfViewerProps {
  file: Blob;
}

function PdfViewer(props: IPdfViewerProps) {
  const { file } = props;
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
    handleChangePage(1);
    dispatch({
      type: PDF_VIEWER_ACTIONS.setNumPages,
      payload: { pages: numPages },
    });
  };

  const handleChangePage = (page: number) => {
    dispatch({
      type: PDF_VIEWER_ACTIONS.setPageNumber,
      payload: { page },
    });
  };

  const handleChangeScale = (scale: number) => {
    dispatch({
      type: PDF_VIEWER_ACTIONS.setScale,
      payload: { scale },
    });
  };

  return (
    <div data-test="component-pdf-viewer">
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Paper>
          <PdfViewerToolBar
            pageNumber={state.pageNumber}
            numPages={state.numPages}
            scale={state.scale}
            onChangeScale={handleChangeScale}
            onChangePage={handleChangePage}
          />
          <PdfViewerAspectRatioBoxSD>
            <PdfViewerAspectRatioBoxInsideSD>
              <Page pageNumber={state.pageNumber} scale={state.scale} />
            </PdfViewerAspectRatioBoxInsideSD>
          </PdfViewerAspectRatioBoxSD>
        </Paper>
      </Document>
    </div>
  );
}

export default PdfViewer;
