import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const PdfViewerSD = styled(Paper)`
  position: relative;
`;

export const PdfViewerAspectRatioBoxSD = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: calc(1.414 * 100%);
  position: relative;
`;

export const PdfViewerAspectRatioBoxInsideSD = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
