import { Action } from '../../../app/types';
import { ICurrency } from '../../../routes/add-portfolio/AddPortfolioForm/hooks/useCurrency';
import { IAccountType } from '../../../routes/add-portfolio/AddPortfolioForm/hooks/useAccountType';
import {ICustodian} from "../../../routes/add-portfolio/AddPortfolioForm/hooks/useCustodian";

export const CLIENTS_ACTIONS = {
  client: {
    request: 'CLIENTS / CLIENT_REQUEST',
    success: 'CLIENTS / CLIENT_SUCCESS',
    error: 'CLIENTS / CLIENT_ERROR',
    resetState: 'CLIENTS / CLIENT_RESET_STATE',
  },
  clients: {
    request: 'CLIENTS_ACTIONS / CLIENTS_REQUESTS',
    success: 'CLIENTS_ACTIONS / CLIENTS_SUCCESS',
    error: 'CLIENTS_ACTIONS / CLIENTS_ERROR',
    resetState: 'CLIENTS_ACTIONS / CLIENTS_RESET_STATE',
  },
  portfolio: {
    request: 'CLIENTS_ACTIONS / PORTFOLIO_REQUEST',
    success: 'CLIENTS_ACTIONS / PORTFOLIO_SUCCESS',
    error: 'CLIENTS_ACTIONS / PORTFOLIO_ERROR',
    resetState: 'CLIENTS_ACTIONS / PORTFOLIO_RESET_STATE',
  },
};

export interface ISecurityAccount {
  id?: number;
  securityNumber: string;
}

export interface ICashAccount {
  id?: number;
  iban: string;
  accountNo: string;
  currency: ICurrency | string;
  accountType: IAccountType | string;
  accountTypeName?: string;
}

interface IBankContact {
  id: number;
  contactType: string;
  fullName: string;
  email: string;
  phoneNumber: string;
}

export interface IPortfolio {
  id: number;
  custodian: ICustodian;
  portfolioNumber: string;
  accountHolder?: number;
  accountHolders: string;
  mandate: boolean;
  securityAccounts: Array<ISecurityAccount>;
  cashAccounts: Array<ICashAccount>;
  bankContacts: Array<IBankContact>;
  status: string,
  dateOfCancellation: number,
  statusChangedOn: string,
  noticeSubmitted: string,
  policiesNum: number,
}

export interface IClient {
  id: number;
  avatar?: string;
  goioID: string;
  wealthManager: number;
  wealthManagerName: string;
  customerNumber: string;
  firstName: string;
  lastName: string;
  nickname: string;
  created: string;
  portfolios: Array<IPortfolio>;
}

export interface IClientsState {
  list: Array<IClient>;
  isClientsFetching: boolean;
  currentClient: IClient | null;
  isCurrentClientFetching: boolean;
  currentPortfolio: IPortfolio | null;
  isCurrentPortfolioFetching: boolean;
  total: number;
  isSearch: boolean;
}

type portfolioRequestAction = Action<
  typeof CLIENTS_ACTIONS.portfolio.request,
  any
>;

type portfolioSuccessAction = Action<
  typeof CLIENTS_ACTIONS.portfolio.success,
  IClient
>;

type portfolioErrorAction = Action<typeof CLIENTS_ACTIONS.portfolio.error, any>;

type portfolioResetStateAction = Action<
  typeof CLIENTS_ACTIONS.portfolio.resetState,
  any
>;

type clientRequestAction = Action<typeof CLIENTS_ACTIONS.client.request, any>;

type clientSuccessAction = Action<
  typeof CLIENTS_ACTIONS.client.success,
  IClient
>;

type clientErrorAction = Action<typeof CLIENTS_ACTIONS.client.error, any>;

type clientResetStateAction = Action<
  typeof CLIENTS_ACTIONS.client.resetState,
  any
>;

type clientsRequestAction = Action<typeof CLIENTS_ACTIONS.clients.request, any>;

type clientsSuccessAction = Action<
  typeof CLIENTS_ACTIONS.clients.success,
  Array<IClient>
>;

type clientsErrorAction = Action<typeof CLIENTS_ACTIONS.clients.error, any>;

type clientsResetStateAction = Action<
  typeof CLIENTS_ACTIONS.clients.resetState,
  any
>;

export type ClientsActionTypes =
  | portfolioRequestAction
  | portfolioSuccessAction
  | portfolioErrorAction
  | portfolioResetStateAction
  | clientRequestAction
  | clientSuccessAction
  | clientErrorAction
  | clientResetStateAction
  | clientsRequestAction
  | clientsSuccessAction
  | clientsErrorAction
  | clientsResetStateAction;
