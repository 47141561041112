import { IHeadCell } from '../../../components/presentation/EnhancedTableHead/EnhancedTableHead';

export const MY_CLIENTS_HEAD_CELLS: IHeadCell[] = [
  {
    id: 'name',
    label: 'Client Name',
    isSortable: true,
    width: '25%',
  },
  {
    id: 'custodians',
    label: 'Number of Custodians',
    width: '25%',
  },
  {
    id: 'portfolios',
    label: 'Number of Portfolios',
  },
];
