import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const RouteAddPortfolioSD = styled.div`
  max-width: 1300px;
  padding: 30px 15px 100px;
  margin: 0 auto;
`;

export const RouteAddPortfolioLinkBackSD: any = styled(Button)`
  margin-bottom: 30px;
`;

export const RouteAddPortfolioPolicySD = styled.div`
  max-width: 1190px;
  padding: 30px 15px 130px;
  margin: 0 auto;
`;

export const RouteAddPortfolioPolicyLinkBackSD: any = styled(Button)`
  margin-bottom: 30px;
`;
