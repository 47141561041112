import React, { useEffect } from 'react';
import LoginForm, { FormValues } from './LoginForm/LoginForm';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import {
  RouteLoginButtonSD,
  RouteLoginFormSD,
  RouteLoginSD,
  RouteLoginTitleSD,
} from './styled';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../store/commonStates/user/actions';
import { AppDispatch } from '../../store/configureStore';
import { Link, useLocation } from 'react-router-dom';
import { propValueOr } from '../../helpers/common';

function RouteLogin() {
  const dispatch: AppDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    const shackbarInfo = propValueOr(location, 'state.snackbar', {});
    if (shackbarInfo.show) {
      enqueueSnackbar(shackbarInfo.message, {
        variant: 'success',
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
  }, []);

  const sendLoginRequest = (
    values: FormValues,
    setErrors: Function,
    setSubmitting: Function
  ) => {
    dispatch(loginAction(values)).catch(() => {
      setSubmitting(false);
      setErrors({ password: 'Incorrect Password', email: 'Incorrect E-Mail' });
    });
  };

  return (
    <RouteLoginSD data-test="component-route-login">
      <RouteLoginTitleSD variant="subtitle1">
        <Box fontSize={20} textAlign="center">
          Sign in
        </Box>
      </RouteLoginTitleSD>
      <RouteLoginFormSD>
        <LoginForm onSubmit={sendLoginRequest} />
      </RouteLoginFormSD>
      <RouteLoginButtonSD
        component={Link}
        to="/forgot-password"
        color="primary"
        size="small"
        variant="text"
        data-test="forgot-link"
      >
        Forgot Password?
      </RouteLoginButtonSD>
      <RouteLoginButtonSD
        component={Link}
        to="/registration"
        color="primary"
        size="small"
        variant="text"
        data-test="registration"
      >
        Want to create new account?
      </RouteLoginButtonSD>
    </RouteLoginSD>
  );
}

export default RouteLogin;
