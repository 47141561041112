import styled from 'styled-components';
import { Table } from '@material-ui/core';

export const StickyScrollSD = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;

  padding-top: 20px;
  padding-bottom: 6px;
  background-color: ${({ theme }) => theme.palette.primary[90]};
`;

export const StickyScrollFilterSD = styled.div`
  padding-bottom: 15px;
  margin-bottom: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary[70]};
`;

export const StickyScrollTableHeadSD = styled(Table)`
  .MuiTableHead-root {
    box-shadow: none;
  }

  .MuiTableRow-root {
    box-shadow: none;
    border: 0;
  }
`;
