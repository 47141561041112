import styled, { DefaultTheme } from 'styled-components';
import { Typography } from '@material-ui/core';

export const PasswordRequirementsSD = styled.section``;

export const PasswordRequirementsTitleSD = styled(Typography)`
  color: ${({ theme }) => theme.colors.textGray};
`;

export const PasswordRequirementsListSD = styled.ul`
  margin: 0;
  padding: 0;
`;

interface PasswordRequirementsItemSDProps {
  isValid?: boolean;
  theme: DefaultTheme;
}

export const PasswordRequirementsItemSD = styled.li`
  display: flex;
  color: ${({ isValid, theme }: PasswordRequirementsItemSDProps) =>
    isValid ? theme.palette.secondary.dark : theme.colors.textGray};
  font: 400 12px/16px OpenSans, sans-serif;
`;

export const PasswordRequirementsIconSD = styled.div`
  margin-right: 5px;

  > svg {
    font-size: 0.9rem;
  }
`;
